import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import langID from './lang/id'
import langEN from './lang/en'
import langZH from './lang/zh'
import langRU from './lang/ru'

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            id: {
                translation: langID,
            },
            en: {
                translation: langEN,
            },
            zh: {
                translation: langZH,
            },
            ru: {
                translation: langRU,
            }
        }
    })

export default i18n;