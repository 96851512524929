import { FC } from "react";

/* eslint-disable react/prop-types */
interface TiktokProps {
  iconClass: any;
}

const Tiktok: FC<TiktokProps> = ({ iconClass }) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000" fill="currentColor" className={`${iconClass}`}>
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
      <path d="M2315 5109 c-800 -83 -1501 -518 -1927 -1196 -604 -960 -491 -2229
      272 -3065 423 -463 951 -740 1585 -830 118 -17 511 -16 635 0 579 80 1097 337
      1491 739 130 132 157 163 243 277 256 341 423 757 488 1211 17 118 17 512 0
      630 -43 303 -122 561 -247 814 -134 268 -270 459 -483 674 -395 400 -889 649
      -1457 733 -123 18 -478 26 -600 13z m790 -1136 c22 -171 76 -302 170 -416 122
      -148 304 -243 502 -263 l63 -7 0 -236 0 -236 -94 6 c-52 4 -120 13 -151 19
      -136 28 -310 102 -427 181 -31 22 -59 39 -62 39 -2 0 -4 -264 -3 -587 2 -582
      2 -589 -21 -678 -83 -326 -333 -580 -657 -666 -120 -31 -294 -36 -415 -11
      -361 74 -641 350 -715 703 -20 92 -19 270 1 367 17 85 64 203 116 289 44 73
      166 204 242 258 174 123 411 190 606 170 l70 -7 0 -243 0 -243 -37 10 c-21 6
      -78 8 -128 6 -114 -5 -200 -43 -278 -123 -249 -253 -108 -673 245 -728 230
      -36 454 133 488 368 6 42 10 465 10 1073 l0 1002 234 0 235 0 6 -47z"/>
    </g>
   </svg>
  )
}

export default Tiktok;