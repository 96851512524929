import Marquee from "react-fast-marquee";
import { client } from "../json/client";
import { useTranslation } from 'react-i18next';

const Client = () => {
    const { t } = useTranslation();
    return (
        <section className="relative w-full h-full py-20 bg-white dark:bg-black overflow-hidden">
            <div className="container">
                <div className="w-full flex flex-col xl:flex-row items-center justify-center" data-aos="fade-right">
                    <div className="w-full flex flex-col items-start justify-between text-left">
                        <p className="relative text-lg text-blue font-poppins font-semibold mb-4 before:absolute before:content-[''] before:bg-blue before:w-[24px] before:h-0.5 pl-[30px] before:translate-x-0 before:top-[50%] before:left-0  before:rounded-l-lg before:rounded-r-lg">{t('home.client.heading')}</p>
                        <h2 className="text-xl text-[#12174D] dark:text-white font-poppins font-bold">{t('home.client.subheading')}</h2>
                    </div>
                </div>
                <Marquee
                    speed={30}
                    play={true}
                >
                    <ul className="p-5 flex gap-10">
                    {
                    client.map((item, index) => (
                        <li key={index} className="py-4 px-20 flex items-center justify-center bg-white dark:bg-black shadow-[0px_0px_10px_rgba(255,255,255,0.5),5px_5px_10px_rgba(108,146,181,0.10)] transition-all">
                            <img
                                src={item.logo}
                                alt="Logo Client"
                                width={100}
                                height={100}
                                loading="lazy"
                                title="Logo Client"
                                className="w-[100px] h-[100px]"
                            />
                        </li>
                    ))
                    }
                    </ul>
                </Marquee>
            </div>
        </section>
    )
}

export default Client;