const content = {
    menu: {
        home: "Главная",
        product: "Продукт",
        blog: "Блог",
        career: "Карьера",
        about: "О нас",
        contact: "Связаться с нами"
    },
    metadata: {
        title: "Yatu Indonesia - Лучшее решение для высококачественного порошкового покрытия",
        description: "Yatu Indonesia, мировой лидер в инновационных решениях для порошкового покрытия, предоставляет высококачественную продукцию с идеальными результатами для различных нужд. Посетите нас для получения дополнительной информации о наших услугах и продуктах.",
        keyword: "Yatu Indonesia, порошковое покрытие, решение для покрытия, качественное покрытие, технология порошкового покрытия, долговечное покрытие",
        thumbnail: "/thumbnail_ru.jpg"
    },
    footer: {
        tagline: "Лучшее решение для порошкового покрытия для вас.",
        p1: "Заинтересованы в работе с нами",
        company: "Компания",
        contact: "Связаться с нами",
        follow: "Следите за нами",
        p2: "Все права защищены."
    },
    breadcrumb: {
        about: "О нас",
        product: "Продукция",
        career: "Присоединяйтесь к нашей команде",
        contact: "Свяжитесь с нами",
        automotive: "Детали автомобильной продукции",
        architecture: "Детали архитектурной продукции",
        industry: "Детали промышленной продукции",
        furniture: "Детали мебели",
        equipment: "Детали оборудования",
        hardware: "Детали аппаратного обеспечения",
        ace: "Детали ACE продукции",
        retail: "Детали розничной продукции",
        rebar: "Детали арматуры",
        beyond_metal: "Детали продукции за пределами металлов",
        lighting: "Детали освещения",
        aplikator: "Детали аппликатора"
    },
    home: {
        hero: {
            heading: "ВАШЕ ЛИЧНОЕ ЛУЧШЕЕ РЕШЕНИЕ ДЛЯ ПОРОШКОВОГО ПОКРЫТИЯ.",
            subheading: "Yatu Indonesia, компания по производству порошковых покрытий, расположенная в Тангеренге, Индонезия. Расширила свою деятельность из Фошаня, Чжэцзян, в Тангеренг. С более чем 20-летним опытом, компания гарантирует высококачественные, стабильные и долговечные продукты, с планами глобального расширения, открывая филиалы в стратегически важных местах.",
            button1: "Наши услуги",
            button2: "Связаться с нами"
        },
        about: {
            heading: "О НАС",
            subheading: "Ведущий глобальный бренд решений для порошкового покрытия",
            description: "В YATU Indonesia мы видим сложные задачи как возможности показать свои навыки. Наш опыт в напылении металлов со сложными контурами означает, что мы можем справиться с проектами, которые другие считают невозможными, предоставляя результаты, превосходящие ожидания.",
            total_year: "лет глобальной гарантии",
            award: {
                silver: "Серебряная глобальная гарантия",
                gold: "Золотая глобальная гарантия"
            },
            button1: "Узнать больше"
        },
        chooce: {
            heading: "ЦЕННОСТИ БИЗНЕСА",
            subheading: "Почему выбирают нас?",
            chooce1: {
                title: "Точный цвет",
                description: "Yatu Indonesia понимает важность достижения идеального цвета для вашего проекта. Наши передовые технологии обеспечивают точность совпадения цветов на уровне ±99%, гарантируя, что ваше видение станет безупречной реальностью. Скажите 'прощай' несоответствиям и несовершенствам."
            },
            chooce2: {
                title: "Эксперты по цвету, которым вы доверяете",
                description: "В YATU Indonesia наша команда ученых и техников по цвету обладает знаниями и опытом, чтобы воплотить в жизнь даже самые сложные цветовые запросы. Мы не просто совпадаем цвета; мы понимаем цвет."
            },
            chooce3: {
                title: "Гарантированное лучшее соотношение цены и качества",
                description: "В YATU Indonesia мы верим, что спокойствие должно идти в комплекте с каждой покупкой. Поэтому мы предлагаем гарантию лучшей цены на разработку вашего индивидуального цвета, обеспечивая лучшее соотношение цены и качества без скрытых сюрпризов."
            },
            chooce4: {
                title: "Создание устойчивого будущего",
                description: "Yatu стремится к внедрению новых методов для положительного воздействия на окружающую среду. Усилия Yatu включают значительное снижение отходов путем внедрения технологий производства без отходов и инвестиций в энергоэффективные машины для порошкового покрытия. Yatu гарантирует, что предоставленный клиентам порошок может быть переработан или повторно использован, предлагая программы переработки и обучая клиентов ответственному управлению отходами. Внедряя эти стратегии, Yatu активно снижает углеродный след и способствует более устойчивому будущему."
            },
            chooce5: {
                title: "Инвестиции в качество",
                description: "С поддержкой наших основных поставщиков, включая KINTE, SJ и Huahui, Yatu Indonesia постоянно соблюдает самые высокие стандарты качества во всех наших продуктах. Наша приверженность к совершенству подтверждается сертификатом ISO 9001, строгими управленческими протоколами и комплексным испытательным оборудованием. Мы гордимся тем, что предлагаем 15-летнюю Золотую глобальную гарантию и 10-летнюю Серебряную глобальную гарантию, обеспечивая долгосрочную надежность и удовлетворение клиентов. В Yatu Indonesia мы верим, что инвестиции в качество - ключ к успеху."
            },
            button1: "Узнать больше",
            button2: "Закрыть"
        },
        feature: {
            heading: "Ключевые особенности",
            subheading: "Наши ключевые особенности",
            a1: {
                title: "Наши премиальные порошки",
                description: "Наш уникальный продукт обеспечивает идеальное покрытие металлов с сложными формами. Будь то глубокие углубления, острые углы или труднодоступные участки, высококачественные порошки YATU Indonesia обеспечивают равномерное покрытие каждой поверхности, улучшая как внешний вид, так и долговечность."
            },
            a2: {
                title: "Скажите 'прощай' проблемам с повторным покрытием",
                description: "Повторное покрытие может быть затратным по времени и деньгам. С порошками YATU Indonesia вы можете быть уверены, что любые необходимые ремонты могут быть выполнены без усилий, и наши порошки также полностью способны к повторному покрытию при необходимости."
            },
            a3: {
                title: "Наши передовые машины для связывания",
                description: "Оборудование Yatu Indonesia для металлического связывания революционизирует порошковое покрытие, создавая постоянную связь между металлическими пигментами и самими частицами порошка."
            }
        },
        product: {
            heading: "ПРОДУКТ",
            subheading: "Наш продукт",
            p: "Порошковые покрытия Yatu предлагают широкий ассортимент высокоэффективных решений для различных применений. Независимо от того, работаете ли вы в сфере оборудования, промышленности, архитектуры или освещения, Yatu позволяет вам добиваться превосходных результатов."
        },
        client: {
            heading: "КЛИЕНТ",
            subheading: "Наш клиент"
        }
    },
    about: {
        history: {
            heading: "История",
            subheading: "История Yatu Indonesia",
            p: "Завод Zhejiang YATU расширил свой бизнес в городе Тангеранг, Индонезия, под управлением компании PT.",
            a1: {
                year: "2024",
                title: "Yatu Indonesia",
                description: "Yatu Indonesia, расположенная в Тангеранге, Индонезия, является недавно созданной компанией по производству порошковых покрытий. Первоначально компания базировалась в Фошане, Китай, но быстро расширилась, чтобы конкурировать с крупными игроками в отрасли. Сейчас компания имеет три завода в Фошане, Чжэцзяне и новый завод в Тангеранге, Индонезия."
            },
            a2: {
                year: "2023",
                title: "Yatu Indonesia",
                description: "Yatu Indonesia, расположенная в Тангеранге, Индонезия, является недавно созданной компанией по производству порошковых покрытий. Первоначально компания базировалась в Фошане, Китай, но быстро расширилась, чтобы конкурировать с крупными игроками в отрасли. Сейчас компания имеет три завода в Фошане, Чжэцзяне и новый завод в Тангеранге, Индонезия."
            },
            a3: {
                year: "2022",
                title: "Yatu Indonesia",
                description: "Yatu Indonesia, расположенная в Тангеранге, Индонезия, является недавно созданной компанией по производству порошковых покрытий. Первоначально компания базировалась в Фошане, Китай, но быстро расширилась, чтобы конкурировать с крупными игроками в отрасли. Сейчас компания имеет три завода в Фошане, Чжэцзяне и новый завод в Тангеранге, Индонезия."
            },
            a4: {
                year: "2021",
                title: "Yatu Indonesia",
                description: "Yatu Indonesia, расположенная в Тангеранге, Индонезия, является недавно созданной компанией по производству порошковых покрытий. Первоначально компания базировалась в Фошане, Китай, но быстро расширилась, чтобы конкурировать с крупными игроками в отрасли. Сейчас компания имеет три завода в Фошане, Чжэцзяне и новый завод в Тангеранге, Индонезия."
            }
        },
        point: {
            heading: "Уникальное торговое предложение",
            t1: "Национальное высокотехнологичное предприятие",
            t2: "Быстрое обслуживание",
            t3: "Долговечность",
            t4: "ISO 9001",
            t5: "Предоставление высококачественного порошка",
            t6: "Лидер на рынке",
            t7: "Гарантия качества",
            t8: "Клиентоориентированный подход"
        }
    },
    career: {
        banner: {
            heading: "Стань частью нашей инновационной семьи!",
            subheading: "Исследуйте увлекательные карьерные возможности и раскройте свой потенциал вместе с нами. Присоединяйтесь к нашей команде и помогайте формировать будущее.",
            button: "Присоединиться к нашей команде"
        },
        review: {
            a1: {
                employee: "Мэрайя Кэри",
                review: "Работа в Yatu Indonesia ощущается как часть большой семьи. Мы все поддерживаем друг друга и стремимся делать все возможное каждый день. Преданность компании качеству и инновациям в порошковом покрытии действительно вдохновляет. У меня была возможность профессионально расти и принимать на себя новые вызовы, которые я никогда не считал возможными. Это было удивительное путешествие.",
                position: "Графический дизайнер",
                images: "/images/review/1.jpg"
            },
            a2: {
                employee: "Лаудия Синтия Белла",
                review: "Работа в Yatu Indonesia ощущается как часть большой семьи. Мы все поддерживаем друг друга и стремимся делать все возможное каждый день. Преданность компании качеству и инновациям в порошковом покрытии действительно вдохновляет. У меня была возможность профессионально расти и принимать на себя новые вызовы, которые я никогда не считал возможными. Это было удивительное путешествие.",
                position: "Цифровой маркетинг",
                images: "/images/review/2.jpg"
            },
            a3: {
                employee: "Феби Путри",
                review: "Работа в Yatu Indonesia ощущается как часть большой семьи. Мы все поддерживаем друг друга и стремимся делать все возможное каждый день. Преданность компании качеству и инновациям в порошковом покрытии действительно вдохновляет. У меня была возможность профессионально расти и принимать на себя новые вызовы, которые я никогда не считал возможными. Это было удивительное путешествие.",
                position: "UI/UX дизайнер",
                images: "/images/review/3.jpg"
            },
            heading: "Давайте расти вместе",
            subheading: "В Yatu Indonesia мы являемся пионерами в мире порошковых покрытий и стремимся предоставлять клиентам качественные и устойчивые решения. Мы верим, что наш успех обусловлен преданностью и страстью нашей команды. Если вы ищете возможность присоединиться к компании, где ваши навыки и идеи будут оценены, мы будем рады услышать вас!"
        },
        gallery: {
            heading: "Наша рабочая среда",
            subheading: "В Yatu Indonesia мы верим, что позитивная рабочая среда необходима для стимулирования творчества и инноваций. Наши современные офисы спроектированы так, чтобы вдохновлять на сотрудничество и командную работу, с открытыми пространствами и зонами отдыха, где сотрудники могут расслабиться и перезарядиться. Мы также предлагаем гибкий график работы и возможности удаленной работы, чтобы помочь нашей команде достичь здорового баланса между работой и личной жизнью.",
            a1: "Все",
            a2: "Офис",
            a3: "Продукт"
        }
    },    
    contact: {
        heading: "Свяжитесь с нами",
        office: "Офис",
        email: "Электронная почта",
        phone: "Номер телефона",
        form: {
            name: "Имя",
            phone: "Номер телефона",
            email: "Электронная почта",
            subject: "Тема",
            message: "Сообщение"
        },
        button: "Отправить сообщение",
        sending: "Отправка...",
        success: "Спасибо за ваше сообщение! Мы свяжемся с вами в ближайшее время.",
    },
    product: {
        titleA1: "Яту для автомобилей",
        subtitleA1: "Автомобильная окраска",
        descriptionA1: "Выберите высококачественное порошковое покрытие Yatu, которое соответствует вашим потребностям, если вам необходимы следующие важные характеристики: гибкость, превосходный цвет и отделка, стойкость к царапинам и потертостям, устойчивость к погодным условиям и долговечность.",
        taglineA1: "Yatu считает, что порошковое покрытие, используемое для полировки, столь же важно.",
    
        titleA2: "Яту для архитектуры",
        subtitleA2: "Архитектурная окраска",
        descriptionA2: "Мы предлагаем двойные преимущества - превосходную долговечность и легкость нанесения, обеспечивая экономичную и долговечную защиту от коррозии и износа, что гарантирует более длительный срок службы и сохранение внешнего вида в сложных условиях.",
        taglineA2: "Износостойкое и коррозионностойкое",
    
        titleA3: "Яту для промышленности",
        subtitleA3: "Промышленная окраска",
        descriptionA3: "Для широкого круга промышленных применений линейка порошковых покрытий Yatu Indonesia обеспечивает превосходную защиту и производительность. Наш широкий ассортимент химических составов и цветов продукции гарантирует, что мы можем удовлетворить уникальные потребности различных типов металлов, используемых в промышленных условиях. Кроме того, мы предлагаем правильное решение для каждого промышленного применения, обеспечивая оптимальные и долгосрочные результаты.",
        taglineA3: "Порошковое покрытие Yatu разработано так, чтобы быть износостойким и труднопроходимым.",
    
        titleA4: "Яту для оборудования",
        subtitleA4: "Окраска оборудования",
        descriptionA4: "Для домашних, коммерческих и промышленных нужд Yatu Indonesia предлагает широкий ассортимент специально разработанных порошковых составов для различных применений.",
        taglineA4: "Yatu Indonesia предоставляет ценность людям по всему миру каждый день.",
    
        titleA5: "Яту для фурнитуры",
        subtitleA5: "Окраска фурнитуры",
        descriptionA5: "Выберите высококачественное порошковое покрытие Yatu, которое соответствует вашим потребностям, если вам необходимы следующие важные характеристики: гибкость, превосходный цвет и отделка, стойкость к царапинам и потертостям, устойчивость к погодным условиям и долговечность.",
        taglineA5: "Для бытового, коммерческого или промышленного использования Yatu Indonesia предлагает широкий выбор порошковых составов, специально разработанных для различных применений.",
    
        titleA6: "Яту для ACE",
        subtitleA6: "Окраска ACE",
        descriptionA6: "Лидер на мировом рынке в области передовых и инновационных решений для порошковых покрытий. Наши покрытия доступны в различных цветах и тщательно тестируются для соответствия самым высоким стандартам производительности.",
        taglineA6: "Используя наш опыт в архитектурном секторе, мы применяем наши знания для создания передовых индивидуальных аппаратных решений.",
    
        titleA7: "Яту для розницы",
        subtitleA7: "Розничная окраска",
        descriptionA7: "Лидер на мировом рынке в области передовых и инновационных решений для порошковых покрытий. Эти покрытия подходят для различных поверхностей, включая полки, аксессуары, мебель, а также наружные порошковые покрытия для окон, дверей, занавесок и витрин, обеспечивая защиту и улучшая внешний вид.",
        taglineA7: "Yatu Indonesia предлагает прочные, долговечные и износостойкие порошковые покрытия для различных типов сельскохозяйственного, строительного и оборудования (ACE), обеспечивая длительную защиту и эстетическую привлекательность.",
    
        titleA8: "Яту для арматуры",
        subtitleA8: "Окраска арматуры",
        descriptionA8: "Они внушают уверенность в долговечности тротуаров, морских конструкций и мостов. Арматура с фьюжн-связанным эпоксидным покрытием используется в различных строительных приложениях.",
        taglineA8: "Порошковые покрытия Yatu обеспечивают прочный, стойкий и долговечный защитный слой благодаря своим термореактивным свойствам.",
    
        titleA9: "Яту для сверх металлов",
        subtitleA9: "Окраска сверх металлов",
        descriptionA9: "Лидер на мировом рынке в области передовых и инновационных решений для порошковых покрытий. Наши покрытия доступны в различных цветах и тщательно тестируются для соответствия самым высоким стандартам производительности.",
        taglineA9: "Эпоксидированная арматура обеспечивает значительные преимущества для бетона, подвергающегося коррозионным условиям, таким как соли для размораживания или морская среда.",
    
        titleA10: "Яту для освещения",
        subtitleA10: "Окраска освещения",
        descriptionA10: "Доступны в различных цветах и стильных эффектах от Yatu для осветительных приборов, улучшая их внешний вид и функциональность в различных стилях дизайна, от классического до современного. Выбор модных цветов и эффектов порошковых покрытий Yatu для светильников не только обеспечивает оптимальную функциональность, но и повышает их визуальную привлекательность, улучшая эстетику и атмосферу в различных стилях, таких как традиционный, современный, промышленный, современный и деревенский, обеспечивая универсальность и изысканность.",
        taglineA10: "Yatu Indonesia предлагает прочные, долговечные и износостойкие порошковые покрытия для различных типов сельскохозяйственного, строительного и оборудования (ACE), обеспечивая длительную защиту и эстетическую привлекательность.",
    
        titleA11: "Яту для аппликаторов",
        subtitleA11: "Окраска аппликаторов",
        descriptionA11: "Наш широкий выбор, дополненный специальной услугой подбора цвета для аппликаторов. Yatu Powder Coatings предлагает широкий ассортимент, включая специальную услугу подбора цвета для аппликаторов.",
        taglineA11: "Yatu предлагает разнообразные цвета порошка и модные эффекты для осветительных приборов, обеспечивая их эффективную работу и отличное внешнее видение, улучшая внешний вид и ощущения в различных стилях, таких как традиционные, современные, промышленные, современные и деревенские настенные светильники.",
    
        titleA12: "Яту для мебели",
        subtitleA12: "Окраска мебели",
        descriptionA12: "Yatu Indonesia приносит пользу людям по всему миру каждый день. Создавая яркие тона и атмосферы для всех типов мебели. Наши инновационные продукты и материалы предназначены для защиты и украшения вашего мира сегодня, завтра и в будущем.",
        taglineA12: "Порошковые покрытия Yatu предлагают широкий выбор порошковых покрытий, включая тысячи цветов, эффектов и индивидуального цветового соответствия, особенно для аппликаторов.",
    
        keuntungan: "Преимущество",
        button: "Узнать больше",
        variant: "Цветное покрытие вариантов"
    }
}

export default content;