const content = {
    menu: {
        home: "首页",
        product: "产品",
        blog: "博客",
        career: "职业",
        about: "关于我们",
        contact: "联系我们"
    },
    metadata: {
        title: "Yatu Indonesia - 高品质粉末涂层的最佳解决方案",
        description: "Yatu Indonesia, 创新粉末涂层解决方案的全球领导者, 提供高质量产品, 完美满足各种需求. 访问我们了解更多关于我们优质服务和产品的信息.",
        keyword: "Yatu Indonesia, 粉末涂层, 涂层解决方案, 高品质涂层, 粉末涂层技术, 耐久涂层",
        thumbnail: "/thumbnail_cn.jpg"
    },
    footer: {
        tagline: "为您提供最佳粉末涂层解决方案。",
        p1: "有兴趣与我们合作",
        company: "公司",
        contact: "联系我们",
        follow: "关注我们",
        p2: "版权所有。"
    },
    breadcrumb: {
        about: "关于我们",
        product: "产品",
        career: "加入我们的团队",
        contact: "联系我们",
        automotive: "汽车产品详情",
        architecture: "建筑产品详情",
        industry: "工业产品详情",
        furniture: "家具产品详情",
        equipment: "设备产品详情",
        hardware: "硬件产品详情",
        ace: "ACE产品详情",
        retail: "零售产品详情",
        rebar: "钢筋产品详情",
        beyond_metal: "超越金属产品详情",
        lighting: "照明产品详情",
        aplikator: "涂布器产品详情"
    },      
    home: {
        hero: {
            heading: "您的最佳粉末涂层解决方案。",
            subheading: "Yatu Indonesia，一家位于印度尼西亚丹格朗的粉末涂层公司。业务从佛山、浙江扩展到丹格朗。凭借超过20年的专业经验，公司保证高质量、稳定和耐用的产品，并计划通过在战略位置开设分公司实现全球扩展。",
            button1: "我们的服务",
            button2: "联系我们"
        },
        about: {
            heading: "关于我们",
            subheading: "全球顶级粉末涂层解决方案品牌",
            description: "在YATU Indonesia，我们将困难的任务视为展示我们技能的机会。我们在喷涂复杂轮廓金属方面的专业知识意味着我们可以处理其他人认为不可能完成的项目，交付超出预期的结果。",
            total_year: "年全球保修",
            award: {
                silver: "银级全球保修",
                gold: "金级全球保修"
            },
            button1: "了解更多"
        },
        chooce: {
            heading: "商业价值",
            subheading: "为什么选择我们？",
            chooce1: {
                title: "精确的颜色",
                description: "Yatu Indonesia理解为您的项目实现完美颜色的重要性。我们的尖端技术提供±99%的颜色匹配准确性，确保您的愿景成为完美的现实。告别不一致和不完美。"
            },
            chooce2: {
                title: "您信任的颜色专家",
                description: "在YATU Indonesia，我们的颜色科学家和技术人员团队拥有实现最复杂颜色要求的知识和经验。我们不仅匹配颜色；我们理解颜色。"
            },
            chooce3: {
                title: "最佳价值保证",
                description: "在YATU Indonesia，我们相信每次购买都应该带来安心。这就是为什么我们为您的定制颜色开发提供竞争性价格保证，确保您获得最佳价值而没有任何隐藏的惊喜。"
            },
            chooce4: {
                title: "创造可持续的未来",
                description: "Yatu致力于开创新的方法，对环境产生积极影响。Yatu的努力包括通过实施零废物生产技术和投资于先进的节能粉末涂层设备显著减少废物。Yatu确保提供给客户的粉末可以通过提供回收计划和教育客户有关负责任的废物管理实践来回收或再利用。通过实施这些策略，Yatu积极减少其碳足迹并促进更加可持续的未来。"
            },
            chooce5: {
                title: "投资质量",
                description: "在我们核心供应商，包括KINTE、SJ和Huahui的支持下，Yatu Indonesia始终在所有产品中保持最高质量标准。我们的卓越承诺通过ISO 9001认证、严格的管理协议和全面的测试设备得到了加强。我们自豪地提供15年的金级全球保修和10年的银级全球保修，确保长期可靠性和客户满意度。在Yatu Indonesia，我们相信投资质量是成功的关键。"
            },
            button1: "了解更多",
            button2: "关闭"
        },
        feature: {
            heading: "关键特性",
            subheading: "我们的关键特性",
            a1: {
                title: "我们的优质粉末",
                description: "我们的独特产品确保在具有复杂形状的金属上完美覆盖。无论是深缝、锐角还是难以到达的区域，YATU Indonesia的高质量粉末确保每个表面均匀涂层，增强外观和耐用性。"
            },
            a2: {
                title: "告别重涂麻烦",
                description: "重涂可能耗时且昂贵，使用YATU Indonesia的粉末，您可以放心任何必要的维修都可以轻松处理，我们的粉末在需要时也完全能够重涂。"
            },
            a3: {
                title: "我们的先进粘合设备",
                description: "Yatu Indonesia的金属粘合设备通过在金属颜料和粉末颗粒之间创建永久粘合来革新粉末涂层。"
            }
        },
        product: {
            heading: "产品",
            subheading: "我们的产品",
            p: "Yatu涂层粉末为各种应用提供全面的高性能解决方案。无论您是在五金、工业、建筑还是照明行业，Yatu都能帮助您实现卓越的效果。"
        },
        client: {
            heading: "客户",
            subheading: "我们的客户"
        }
    },
    about: {
        history: {
            heading: "历史",
            subheading: "Yatu Indonesia 历史",
            p: "浙江YATU工厂已在印尼Tangerang扩展其业务，隶属于PT。",
        a1: {
            year: "2024",
            title: "Yatu Indonesia",
            description: "Yatu Indonesia 位于印尼Tangerang，是一家新成立的粉末涂料公司。公司最初总部设在中国佛山，现已迅速扩展，与行业巨头展开竞争。目前公司在佛山、浙江和最新的印尼Tangerang拥有三家工厂。"
        },
        a2: {
            year: "2023",
            title: "Yatu Indonesia",
            description: "Yatu Indonesia 位于印尼Tangerang，是一家新成立的粉末涂料公司。公司最初总部设在中国佛山，现已迅速扩展，与行业巨头展开竞争。目前公司在佛山、浙江和最新的印尼Tangerang拥有三家工厂。"
        },
        a3: {
            year: "2022",
            title: "Yatu Indonesia",
            description: "Yatu Indonesia 位于印尼Tangerang，是一家新成立的粉末涂料公司。公司最初总部设在中国佛山，现已迅速扩展，与行业巨头展开竞争。目前公司在佛山、浙江和最新的印尼Tangerang拥有三家工厂。"
        },
        a4: {
            year: "2021",
            title: "Yatu Indonesia",
            description: "Yatu Indonesia 位于印尼Tangerang，是一家新成立的粉末涂料公司。公司最初总部设在中国佛山，现已迅速扩展，与行业巨头展开竞争。目前公司在佛山、浙江和最新的印尼Tangerang拥有三家工厂。"
        }
      }
    },
    career: {
        banner: {
            heading: "成为我们创新家庭的一员！",
            subheading: "探索激动人心的职业机会，与我们一起释放您的潜力。加入我们的团队，共同塑造未来。",
            button: "加入我们的团队"
        },
        review: {
            a1: {
                employee: "玛丽亚·凯丽",
                review: "在 Yatu Indonesia 工作感觉像是一个大家庭的一部分。我们每天都互相支持，努力做到最好。公司在粉末涂料方面对质量和创新的承诺令人鼓舞。我有机会在专业上成长，并迎接我从未想象过的挑战。这段旅程至今非常精彩。",
                position: "平面设计师",
                images: "/images/review/1.jpg"
            },
            a2: {
                employee: "劳迪亚·辛蒂亚·贝拉",
                review: "在 Yatu Indonesia 工作感觉像是一个大家庭的一部分。我们每天都互相支持，努力做到最好。公司在粉末涂料方面对质量和创新的承诺令人鼓舞。我有机会在专业上成长，并迎接我从未想象过的挑战。这段旅程至今非常精彩。",
                position: "数字营销",
                images: "/images/review/2.jpg"
            },
            a3: {
                employee: "菲比·普特里",
                review: "在 Yatu Indonesia 工作感觉像是一个大家庭的一部分。我们每天都互相支持，努力做到最好。公司在粉末涂料方面对质量和创新的承诺令人鼓舞。我有机会在专业上成长，并迎接我从未想象过的挑战。这段旅程至今非常精彩。",
                position: "UI/UX 设计师",
                images: "/images/review/3.jpg"
            },
            heading: "让我们一起成长",
            subheading: "在 Yatu Indonesia，我们是粉末涂料领域的先驱，致力于为客户提供高质量、可持续的解决方案。我们相信，我们的成功源于团队的奉献和热情。如果您正在寻找一家重视您的技能和想法的前瞻性公司，我们很想听到您的声音！"
        },
        gallery: {
            heading: "我们的工作环境",
            subheading: "在 Yatu Indonesia，我们相信积极的工作环境对于激发创造力和创新至关重要。我们的现代化办公室旨在激发协作与团队合作，设有开放式空间和休闲区，供员工放松和充电。我们还提供灵活的工作时间和远程工作选项，以帮助我们的团队实现健康的工作与生活平衡。",
            a1: "全部",
            a2: "办公室",
            a3: "产品"
        }
    },    
    contact: {
        heading: "联系我们",
        office: "办公室",
        email: "电子邮箱地址",
        phone: "电话号码",
        form: {
            name: "姓名",
            phone: "电话号码",
            email: "电子邮箱",
            subject: "主题",
            message: "留言"
        },
        button: "发送消息",
        sending: "发送中...",
        success: "您的消息已成功发送！",
    },
    product: {
        titleA1: "雅图用于汽车",
        subtitleA1: "汽车喷涂",
        descriptionA1: "如果您希望涂层具有以下重要特性，请选择优质的雅图粉末涂料：柔韧性、卓越的颜色和表面效果、抗刮擦和磨损、耐候性和持久性。",
        taglineA1: "Yatu认为用于抛光的粉末涂层同样重要。",
    
        titleA2: "雅图用于建筑",
        subtitleA2: "建筑喷涂",
        descriptionA2: "我们提供双重优势，即卓越的耐久性和易于应用，提供经济高效且持久的防腐蚀和磨损保护，确保在苛刻条件下延长使用寿命并保持外观。",
        taglineA2: "耐磨且防腐蚀",
    
        titleA3: "雅图用于工业",
        subtitleA3: "工业喷涂",
        descriptionA3: "雅图印度尼西亚的粉末涂料系列为广泛的工业应用提供卓越的保护和性能。我们丰富的产品化学成分和颜色选择确保我们能够满足工业环境中各种金属的独特需求。此外，我们还为每种工业应用提供了正确的解决方案，确保最佳且持久的效果。",
        taglineA3: "Yatu的粉末涂层设计为耐磨且耐用。",
    
        titleA4: "雅图用于设备",
        subtitleA4: "设备喷涂",
        descriptionA4: "无论是家用、商用还是工业用途，雅图印度尼西亚提供多种专门设计用于各种应用的粉末配方。",
        taglineA4: "Yatu Indonesia每天为世界各地的人们提供价值。",
    
        titleA5: "雅图用于硬件",
        subtitleA5: "硬件喷涂",
        descriptionA5: "如果您希望涂层具有以下重要特性，请选择优质的雅图粉末涂料：柔韧性、卓越的颜色和表面效果、抗刮擦和磨损、耐候性和持久性。",
        taglineA5: "无论是家用、商业还是工业用途，Yatu Indonesia提供各种专为不同应用设计的粉末配方。",
    
        titleA6: "雅图用于ACE",
        subtitleA6: "ACE喷涂",
        descriptionA6: "在全球范围内引领卓越和创新的粉末涂层解决方案。我们的涂层有多种颜色可供选择，并经过严格测试，以满足最高性能标准。",
        taglineA6: "利用我们在建筑行业的经验，我们将知识应用于创造行业领先的定制硬件产品。",
    
        titleA7: "雅图用于零售",
        subtitleA7: "零售喷涂",
        descriptionA7: "在全球范围内引领卓越和创新的粉末涂层解决方案。这些涂层适用于各种表面，包括货架、配件、家具以及外部粉末涂层，用于窗户、门、幕墙和橱窗，确保保护并提升外观。",
        taglineA7: "Yatu Indonesia为各种农业、建筑和设备（ACE）应用提供强大、耐用且耐磨的粉末涂层，确保持久的保护和美观。",
    
        titleA8: "雅图用于钢筋",
        subtitleA8: "钢筋喷涂",
        descriptionA8: "它们增强了对人行道、海洋结构和桥梁的耐久性信心。环氧涂层钢筋用于各种建筑应用。",
        taglineA8: "Yatu的粉末涂层由于其热固性特性，提供强大、坚韧且持久的保护层。",
    
        titleA9: "雅图用于超越金属",
        subtitleA9: "超越金属喷涂",
        descriptionA9: "在全球范围内引领卓越和创新的粉末涂层解决方案。我们的涂层有多种颜色可供选择，并经过严格测试，以满足最高性能标准。",
        taglineA9: "环氧涂层的钢筋在暴露于腐蚀性条件下，如除冰盐或海洋环境中，为混凝土提供显著的优势。",
    
        titleA10: "雅图用于照明",
        subtitleA10: "照明喷涂",
        descriptionA10: "雅图为灯具提供多种颜色和时尚效果，从经典到现代，提升其外观和功能。雅图粉末涂层的流行颜色和效果选择不仅确保了最佳功能性，还提升了其视觉吸引力，增强了传统、现代、工业、当代和乡村风格的美学和氛围，提供了多功能性和精致感。",
        taglineA10: "Yatu Indonesia为各种农业、建筑和设备（ACE）应用提供强大、耐用且耐磨的粉末涂层，确保持久的保护和美观。",
    
        titleA11: "雅图用于应用器",
        subtitleA11: "应用器喷涂",
        descriptionA11: "我们提供多种选择，并为应用器提供专门的颜色匹配服务。雅图粉末涂料提供广泛的选择，包括为应用器量身定制的颜色匹配服务。",
        taglineA11: "Yatu提供各种粉末颜色和时尚效果的照明设备，确保它们有效地工作并看起来很棒，提升各种风格的外观和感觉，如传统、现代、工业、当代和乡村风格的壁灯。",
    
        titleA12: "雅图用于家具",
        subtitleA12: "家具喷涂",
        descriptionA12: "雅图印度尼西亚每天为全球的人们创造价值。通过为各种家具创造丰富的色调和氛围。我们的创新产品和材料旨在保护和美化您的世界，今天、明天和未来。",
        taglineA12: "Yatu粉末涂层提供广泛的粉末涂层选择，包括数千种颜色、效果和定制颜色匹配，特别适用于涂料应用。",
    
        keuntungan: "优势",
        button: "了解更多",
        variant: "变体颜色涂层"
    }    
}

export default content;