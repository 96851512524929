import { FC } from "react";

/* eslint-disable react/prop-types */
interface TwitterProps {
  iconClass: any;
}

const Twitter: FC<TwitterProps> = ({ iconClass }) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000" fill="currentColor" className={`${iconClass}`}>
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
            <path d="M2315 5109 c-800 -83 -1501 -518 -1927 -1196 -604 -961 -490 -2237
            274 -3068 425 -462 951 -737 1583 -827 119 -17 512 -16 635 1 622 86 1148 360
            1572 820 349 378 572 862 650 1406 17 118 17 512 0 630 -59 416 -191 769 -410
            1099 -92 140 -185 254 -315 385 -399 404 -893 653 -1462 737 -123 18 -478 26
            -600 13z m27 -1592 c207 -302 379 -549 382 -550 3 -1 218 246 479 548 l473
            550 128 3 c75 1 126 -1 124 -7 -1 -5 -123 -148 -269 -317 -146 -170 -390 -454
            -542 -631 l-277 -321 531 -774 c292 -425 560 -815 596 -865 l64 -93 -439 0
            -439 0 -398 580 c-219 319 -401 580 -404 579 -3 0 -229 -260 -501 -577 l-496
            -577 -132 -3 -132 -3 574 668 574 667 -32 46 c-36 49 -1071 1557 -1101 1603
            l-17 27 438 -2 439 -3 377 -548z"/>
            <path d="M2359 2558 l918 -1313 196 -3 c108 -1 197 1 197 5 0 5 -411 595 -913
            1313 l-913 1305 -202 3 -201 2 918 -1312z"/>
        </g>
    </svg>
  )
}

export default Twitter;