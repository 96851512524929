import { useTranslation } from 'react-i18next'
import { FaArrowRight } from "react-icons/fa";
import { product } from '../json/product'

const ListProduct = () => {
    const { i18n, t } = useTranslation();
    const currentLang = i18n.language || 'id';
    return (
        <section className="relative w-full h-auto py-20 bg-white dark:bg-black overflow-hidden">
            <div className="container">
                {product.map((item, index) => {
                    const number = index + 1;
                    return(
                        <div key={index} className='relative w-full pb-10 mb-10 last:pb-0 last:mb-0 flex flex-col gap-y-12 border-[#C3C2C2] border-b-[0.4px] last:border-b-0' data-aos={`${index % 2 ? 'fade-right': 'fade-left'}`}>
                            <div className="relative w-full h-[250px] md:h-[450px] shadow-[-10px_-10px_15px_rgba(0,0,0,0.20),0px_0px_35px_rgba(0,0,0,0.20)] rounded-3xl">
                                <img src={item.images[0].src} className="w-full h-[250px] md:h-[450px] object-cover object-center rounded-3xl" />
                                <div className="absolute p-4 md:p-10 top-0 w-full h-full flex flex-col items-start justify-end gap-y-1 bg-gradient-to-b from-[#0E102D]/0 from-25% to-[#0D1730] to-100# rounded-3xl">
                                    <h3 className="text-white text-base md:text-4xl font-poppins font-bold">{t(`product.titleA${number}`)}</h3>
                                    <span className="text-white text-sm md:text-base font-sarala font-normal">{t(`product.subtitleA${number}`)}</span>
                                </div>
                            </div>
                            <div className='flex flex-col gap-y-5'>
                                <p className="text-[#12174D] dark:text-white text-3xl font-sarala font-bold">{t(`product.keuntungan`)}</p>
                                <p className="text-lg text-gray dark:text-white font-normal font-sarala leading-10">{t(`product.descriptionA${number}`)}</p>
                            </div>
                            <div className="flex items-center">
                                <a href={`/${currentLang}/product/${item.slug}`}  className="text-base text-white font-poppins font-normal flex items-center gap-x-4 py-4 px-10 bg-[#39B54A] hover:bg-[#39B54A]/80 rounded-[10px] transition-all">
                                    <span>{t(`product.button`)}</span>
                                    <FaArrowRight />
                                </a>
                            </div>
                        </div>
                    )
                })}
            </div>
        </section>
    );
}

export default ListProduct;