const content = {
    menu: {
        home: "Home",
        product: "Product",
        blog: "Blog",
        career: "Career",
        about: "About us",
        contact: "Contact Us"
    },
    metadata: {
        title: "Yatu Indonesia - The Best Solution for High-Quality Powder Coating",
        description: "Yatu Indonesia, a global leader in innovative powder coating solutions, provides high-quality products with perfect results for various needs. Visit us for more information about our superior services and products.",
        keyword: "Yatu Indonesia, powder coating, coating solution, quality coating, powder coating technology, durable coating",
        thumbnail: "/thumbnail_us.jpg"
    },
    footer: {
        tagline: "The Best Coating Powder Solution for You.",
        p1: "Interested To Work With Us",
        company: "Company",
        contact: "Contact Us",
        follow: "Follow Us",
        p2: "All Rights Reserved."
    },
    breadcrumb: {
        about: "About Us",
        product: "Products",
        career: "Join Our Team",
        contact: "Contact Us",
        automotive: "Automotive Product Details",
        architecture: "Architecture Product Details",
        industry: "Industry Product Details",
        furniture: "Furniture Product Details",
        equipment: "Equipment Product Details",
        hardware: "Hardware Product Details",
        ace: "ACE Product Details",
        retail: "Retail Product Details",
        rebar: "Rebar Product Details",
        beyond_metal: "Beyond Metal Product Details",
        lighting: "Lighting Product Details",
        aplikator: "Applicator Product Details"
    },
    home: {
        hero: {
            heading: "YOUR PERSONAL BEST COATING POWDER SOLUTION.",
            subheading: "Yatu Indonesia, a coating powder company based in Tangerang, Indonesia. Has expanded its operations from Foshan, Zhejiang, to Tangerang.  With over 20 years of expertise, the company ensures high-quality, stable, and durable products, with plans to expand globally by opening branches in strategic locations.",
            button1: "Our Service",
            button2: "Contact Us"
        },
        about: {
            heading: "About US",
            subheading: "A Top Global Brand Of Coating Powder Solution",
            description: "At YATU Indonesia, we see difficult tasks as opportunities to show our skills. Our expertise in spraying metals with challenging contours means we can handle projects others consider impossible, delivering results that exceed expectations.",
            total_year: "Years Global Warranty",
            award: {
                silver: "Silver Global Warranty",
                gold: "Gold Global Warranty"
            },
            button1: "Discover More"
        },
        chooce: {
            heading: "BUSINESS VALUE",
            subheading: "Why Chooce Us ?",
            chooce1: {
                title: "Precision Color",
                description: "Yatu Indonesia understands the importance of achieving the perfect color for your project. Our cutting edge technology delivers ±99% color matching accuracy, ensuring your vision becomes a flawless reality. Say goodbye to inconsistencies and imperfections."
            },
            chooce2: {
                title: "The Color Experts You Trust",
                description: "At YATU Indonesia, our team of color scientists and technicians possess the knowledge and experience to bring even the most complex color requests to life. We don`t just match colors; we understand color."
            },
            chooce3: {
                title: "The Best Value Guaranteed",
                description: "At YATU Indonesia, we believe that peace of mind should come standard with every purchase. That`s why we offer a competitive price guarantee for your custom color development, ensuring you get the best value without any hidden surprises."
            },
            chooce4: {
                title: "Creating a Sustainable Future",
                description: "Achieve the Yatu is committed to pioneering new methods to make a positive impact on the environment. Yatu`s efforts include significantly reducing waste by implementing zero-waste production techniques and investing in sophisticated energy-efficient powder coating machines. Yatu ensures that the powder provided to customers can be recycled or reused by offering recycling programs and educating customers about responsible waste management practices. By implementing these strategies, Yatu is actively reducing its carbon footprint and contributing to a more sustainable future.perfect metallic look, from subtle shimmer to bold chrome, on any project."
            },
            chooce5: {
                title: "Invest In Quality",
                description: "With the support of our core suppliers, including KINTE, SJ, and Huahui, Yatu Indonesia consistently upholds the highest quality standards in all our products. Our commitment to excellence is reinforced by our ISO 9001 certification, strict management protocols, and comprehensive testing equipment. We proudly offer a 15-year Gold Global Warranty and a 10-year Silver Global Warranty, ensuring long-lasting reliability and customer satisfaction. At Yatu Indonesia, we believe that investing in quality is the key to success."
            },
            button1: "Learn More",
            button2: "Close"
        },
        feature: {
            heading: "Key Features",
            subheading: "Our Key Features",
            a1: {
                title: "Our Premium Powders",
                description: "Our unique product ensures perfect coverage on metals with complex shapes. Whether it's deep crevices, sharp angles, or hard-to-reach areas, YATU Indonesia's high-quality powders ensure that every surface is uniformly coated, enhancing both appearance and durability."
            },
            a2: {
                title: "Say Goodbye to Recoating Hassles",
                description: "Recoating can be time-consuming and costly, with YATU Indonesia’s powders, you can rest assured that any necessary repairs can be handled effortlessly & our powders are also fully capable of recoating when required."
            },
            a3: {
                title: "Our Advanced Bonding Machines",
                description: "Yatu Indonesia's Metallic Bonding Equipment revolutionizes powder coating by creating a permanent bond between metallic pigments and the powder particles themselves."
            }
        },
        product: {
            heading: "PRODUCT",
            subheading: "Our Product",
            p: "Yatu Coating Powders offer a comprehensive range of high-performance solutions for a diverse array of applications.  Whether you're in the hardware, industrial, architectural, or lighting industries, Yatu empowers you to achieve superior results."
        },
        client: {
            heading: "CLIENT",
            subheading: "Our Client"
        }
    },
    about: {
        history: {
            heading: "History",
            subheading: "Yatu Indonesia History",
            p: "The Zhejiang YATU factory has expanded its business to Tangerang, Indonesia under PT.",
            a1: {
                year: "2024",
                title: "Yatu Indonesia",
                description: "Located in Tangerang, Indonesia, Yatu Indonesia is a newly established powder coating company. Originally headquartered in Foshan, China, Yatu Indonesia has rapidly expanded to compete with major industry players. The company now operates three factories in Foshan, Zhejiang, and the latest in Tangerang, Indonesia."
            },
            a2: {
                year: "2023",
                title: "Yatu Indonesia",
                description: "Located in Tangerang, Indonesia, Yatu Indonesia is a newly established powder coating company. Originally headquartered in Foshan, China, Yatu Indonesia has rapidly expanded to compete with major industry players. The company now operates three factories in Foshan, Zhejiang, and the latest in Tangerang, Indonesia."
            },
            a3: {
                year: "2022",
                title: "Yatu Indonesia",
                description: "Located in Tangerang, Indonesia, Yatu Indonesia is a newly established powder coating company. Originally headquartered in Foshan, China, Yatu Indonesia has rapidly expanded to compete with major industry players. The company now operates three factories in Foshan, Zhejiang, and the latest in Tangerang, Indonesia."
            },
            a4: {
                year: "2021",
                title: "Yatu Indonesia",
                description: "Located in Tangerang, Indonesia, Yatu Indonesia is a newly established powder coating company. Originally headquartered in Foshan, China, Yatu Indonesia has rapidly expanded to compete with major industry players. The company now operates three factories in Foshan, Zhejiang, and the latest in Tangerang, Indonesia."
            }
        },
        point: {
            heading: "独特卖点",
            t1: "国家高新技术企业",
            t2: "快速服务",
            t3: "耐用性",
            t4: "ISO 9001",
            t5: "提供高质量粉末",
            t6: "顶级领先产品",
            t7: "质量保证",
            t8: "以客户为中心的方式"
        }        
    },
    career: {
        banner: {
            heading: "Be a Part of Our Innovative Family!",
            subheading: "Explore exciting career opportunities and unleash your potential with us. Join our team and help shape the future.",
            button: "Join Our Team"
        },
        review: {
            a1:{
                employee: "Mariah Carry",
                review: "Working at Yatu Indonesia feels like being part of a big family. We all support each other and strive to do our best every day. The company's dedication to quality and innovation in powder coating is truly inspiring. I’ve had the chance to grow professionally and take on new challenges that I never thought possible. It’s been an amazing journey so far.",
                position: "Graphic Designer",
                images: "/images/review/1.jpg"
            },
            a2:{
                employee: "Laudia Cyntia Bella",
                review: "Working at Yatu Indonesia feels like being part of a big family. We all support each other and strive to do our best every day. The company's dedication to quality and innovation in powder coating is truly inspiring. I’ve had the chance to grow professionally and take on new challenges that I never thought possible. It’s been an amazing journey so far.",
                position: "Digital Marketing",
                images: "/images/review/2.jpg"
            },
            a3:{
                employee: "Feby Putry",
                review: "Working at Yatu Indonesia feels like being part of a big family. We all support each other and strive to do our best every day. The company's dedication to quality and innovation in powder coating is truly inspiring. I’ve had the chance to grow professionally and take on new challenges that I never thought possible. It’s been an amazing journey so far.",
                position: "UI/UX Designer",
                images: "/images/review/3.jpg"
            },
            heading: "Let`s Grow Together",
            subheading: "At Yatu Indonesia, we are pioneers in the world of coating powder, committed to delivering high-quality, sustainable solutions to our clients. We believe that our success is driven by the dedication and passion of our team. If you`re looking to join a forward-thinking company where your skills and ideas will be valued, we`d love to hear from you!"
        },
        gallery: {
            heading: "Our Work Environment",
            subheading: "At Yatu Indonesia, we believe that a positive work environment is essential for fostering creativity and innovation. Our modern offices are designed to inspire collaboration and teamwork, with open-plan spaces and breakout areas where employees can relax and recharge. We also offer flexible working hours and remote working options to help our team achieve a healthy work-life balance.",
            a1: "All",
            a2: "Office",
            a3: "Product"
        }
    },    
    contact: {
        heading: "Contact Us",
        office: "Office",
        email: "Email Address",
        phone: "Phone Number",
        form: {
            name: "Name",
            phone: "Phone Number",
            email: "Email",
            subject: "Subject",
            message: "Message"
        },
        button: "Send Message",
        sending: "Sending...",
        success: "Message sent successfully!",
    },
    product: {
        titleA1: "Yatu For Automotive",
        subtitleA1: "Automotive Painting",
        descriptionA1: "Choose high-quality Yatu powder coatings that suit your needs if you want the coating to have the following essential features: flexibility, exceptional color and finish, resistance to scratches and abrasions, weather resistance, and durability.",
        taglineA1: "Yatu considers the powder coating used for polishing to be equally important.",
    
        titleA2: "Yatu For Architecture",
        subtitleA2: "Architecture Painting",
        descriptionA2: "We offer the dual advantages of exceptional durability and easy application, providing cost-effective and long-lasting protection against corrosion and wear, ensuring longer life and maintained appearance in demanding conditions.",
        taglineA2: "Wear-resistant and corrosion-resistant",
    
        titleA3: "Yatu For Industry",
        subtitleA3: "Industrial Painting",
        descriptionA3: "For a wide range of industrial applications, Yatu Indonesia's range of powder coatings provides outstanding protection and performance. Our wide selection of product chemistries and colors ensures that we can meet the unique needs of various types of metals used in industrial environments. Besides, we also provide the right solution for every industrial application, ensuring optimal and long-lasting results.",
        taglineA3: "Yatu's powder coating is designed to be wear-resistant and hard-working.",
    
        titleA4: "Yatu For Equipment",
        subtitleA4: "Equipment Painting",
        descriptionA4: "Whether for household, commercial, or industrial purposes, Yatu Indonesia offers a variety of powder formulations specifically designed for diverse applications.",
        taglineA4: "Yatu Indonesia delivers value to people around the world every day.",
    
        titleA5: "Yatu For Hardware",
        subtitleA5: "Hardware Painting",
        descriptionA5: "Choose high-quality Yatu powder coatings that suit your needs if you want the coating to have the following essential features: flexibility, exceptional color and finish, resistance to scratches and abrasions, weather resistance, and durability.",
        taglineA5: "Whether for household, commercial, or industrial use, Yatu Indonesia offers a range of powder formulations specifically designed for various applications.",
    
        titleA6: "Yatu For ACE",
        subtitleA6: "ACE Painting",
        descriptionA6: "Leading globally in superior and innovative powder coating solutions. Our coatings come in various colors and are rigorously tested to meet the highest performance standards.",
        taglineA6: "Leveraging our experience in the architectural sector, we apply our knowledge to create cutting-edge custom hardware products.",
    
        titleA7: "Yatu For Retail",
        subtitleA7: "Retail Painting",
        descriptionA7: "Leading globally in superior and innovative powder coating solutions. These coatings are suitable for various surfaces, including shelves, accessories, furniture, and external powder for windows, doors, curtain walls, and storefronts, ensuring protection and enhancing appearance.",
        taglineA7: "Yatu Indonesia offers strong, durable, and wear-resistant powder coatings for various types of Agricultural, Construction, and Equipment (ACE) applications, ensuring enduring protection and aesthetic appeal.",
    
        titleA8: "Yatu For Rebar",
        subtitleA8: "Rebar Painting",
        descriptionA8: "They instill confidence in the durability of pavements, marine structures, and bridges. Fusion-bonded epoxy-coated rebar is used in various construction applications.",
        taglineA8: "Yatu’s powder coatings offer a strong, tough, and enduring protective layer due to their thermoset properties.",
    
        titleA9: "Yatu For Beyond Metal",
        subtitleA9: "Beyond Metal Painting",
        descriptionA9: "Leading globally in superior and innovative powder coating solutions. Our coatings come in various colors and are rigorously tested to meet the highest performance standards.",
        taglineA9: "Epoxy-coated rebar provides significant advantages for concrete exposed to corrosive conditions such as de-icing salts or marine environments.",
    
        titleA10: "Yatu For Lighting",
        subtitleA10: "Lighting Painting",
        descriptionA10: "Available in a variety of colors and stylish effects from Yatu for lighting fixtures, enhancing their appearance and functionality across design styles, from classic to modern. Yatu's trendy powder coating color and effect choices for lighting fixtures ensure optimal functionality while boosting visual appeal, enhancing aesthetics and ambiance in diverse styles such as traditional, modern, industrial, contemporary, and rustic, providing versatility and sophistication.",
        taglineA10: "Yatu Indonesia offers strong, durable, and wear-resistant powder coatings for various types of Agricultural, Construction, and Equipment (ACE) applications, ensuring enduring protection and aesthetic appeal.",
    
        titleA11: "Yatu For Applicators",
        subtitleA11: "Applicator Painting",
        descriptionA11: "Our wide selection, combined with custom color matching services for applicators. Yatu Powder Coatings offers a diverse range of options, including custom color matching for applicators.",
        taglineA11: "Yatu offers a range of powder colors and stylish effects for lighting fixtures, ensuring they perform effectively and look fantastic, enhancing the appearance and feel in various styles such as traditional, modern, industrial, contemporary, and rustic wall lights.",
    
        titleA12: "Yatu For Furniture",
        subtitleA12: "Furniture Painting",
        descriptionA12: "Yatu Indonesia provides value to people worldwide daily. By creating vibrant tones and atmospheres for all types of furniture. Our innovative products and materials are designed to protect and beautify your world today, tomorrow, and beyond.",
        taglineA12: "Yatu Powder Coatings offers a wide selection of powder coatings, including thousands of colors, effects, and custom color matching, especially for applicators.",
    
        keuntungan: "Advantage",
        button: "Learn More",
        variant: "Variant Color Coating"
    }
}

export default content;