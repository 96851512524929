import { useTranslation } from 'react-i18next'

const Feature = () => {
    const { t } = useTranslation();
    return (
        <section className="relative py-20 my-20 w-full h-full flex items-center bg-white dark:bg-black overflow-hidden">
            <div className="absolute top-0 left-0 w-full xl:w-8/12 h-full bg-[url('/images/feature.jpg')] bg-center bg-cover bg-no-repeat rounded-r-[35px]" data-aos="fade-right"></div>
            <div className="container">
                <div className="relative w-full flex flex-1 flex-col md:flex-col lg:flex-col xl:flex-row gap-4 items-center" data-aos="fade-left">
                    <div className="xl:ml-auto xl:w-8/12">
                        <div className="py-14 px-10 flex flex-col bg-white dark:bg-[#212121] rounded-[25px] shadow-[0px_-15px_50px_rgba(255,255,255,0.5),0px_15px_50px_rgba(108,146,181,0.10)] dark:shadow-[10px_10px_19px_#1c1e22,-10px_-10px_19px_#262a2e]">
                            <div className="w-full flex flex-col xl:flex-row items-center justify-center mb-8">
                                <div className="w-full flex flex-col items-start justify-between text-left">
                                    <p className="relative text-lg text-blue font-poppins font-semibold mb-4 before:absolute before:content-[''] before:bg-blue before:w-[24px] before:h-0.5 pl-[30px] before:translate-x-0 before:top-[50%] before:left-0  before:rounded-l-lg before:rounded-r-lg">{t('home.feature.heading')}</p>
                                    <h2 className="text-xl text-[#12174D] dark:text-white font-poppins font-bold">{t('home.feature.subheading')}</h2>
                                </div>
                            </div>
                            <div className="flex flex-col mb-7 pb-7 border-b-[.5px] border-gray/50">
                                <p className="mb-3 text-[#12174D] dark:text-white text-xl font-poppins font-semibold">{t('home.feature.a1.title')}</p>
                                <span className="text-gray dark:text-white text-base font-sarala font-normal">{t('home.feature.a1.description')}</span>
                            </div>
                            <div className="flex flex-col mb-7 pb-7 border-b-[.5px] border-gray/50">
                                <p className="mb-3 text-[#12174D] dark:text-white text-xl font-poppins font-semibold">{t('home.feature.a2.title')}</p>
                                <span className="text-gray dark:text-white text-base font-sarala font-normal">{t('home.feature.a2.description')}</span>
                            </div>
                            <div className="flex flex-col">
                                <p className="mb-3 text-[#12174D] dark:text-white text-xl font-poppins font-semibold">{t('home.feature.a3.title')}</p>
                                <span className="text-gray dark:text-white text-base font-sarala font-normal">{t('home.feature.a3.description')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Feature;