interface MenuProps {
    name: string;
    slug: string;
    link: string;
}

export const menu: MenuProps[] = [
    {
        name: 'Home',
        slug: 'home',
        link: '/'
    },
    {
        name: 'Product',
        slug: 'product',
        link: '/product'
    },
    // {
    //     name: 'Blog',
    //     slug: 'blog',
    //     link: '/blog'
    // },
    {
        name: 'Career',
        slug: 'career',
        link: '/career'
    },
    {
        name: 'About Us',
        slug: 'about',
        link: '/about-us'
    },
    {
        name: 'Contact Us',
        slug: 'contact',
        link: '/contact-us'
    },
];