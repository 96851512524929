import { FaArrowRight } from "react-icons/fa";
import { useTranslation } from 'react-i18next'

const Hero = () => {
    const { t } = useTranslation();
    return (
        <section className="relative w-full h-screen flex items-center justify-center px-0 m-0 overflow-hidden bg-white dark:bg-black">
            <div className="relative container z-50">
                <div className="w-full md:w-full lg:w-9/12 px-10 md:px-10 lg:px-20 flex flex-col items-start justify-start gap-y-5">
                    <h2 className="text-base md:text-xl lg:text-2xl xl:text-5xl text-white font-poppins font-bold">{t('home.hero.heading')}</h2>
                    <p className="text-xs md:text-sm lg:text-base xl:text-lg text-white font-sarala font-normal">{t('home.hero.subheading')}</p>
                    <div className="w-full hidden md:flex items-center justify-start gap-x-5">
                        <a href="/" className="px-7 py-4 bg-[#39B54A] hover:bg-[#39B54A]/80 transition-all text-white font-poppins font-normal rounded-[50px]">{t('home.hero.button1')}</a>
                        <a href="/contact-us" className="text-white font-poppins font-normal flex items-center gap-x-4">
                            <span>{t('home.hero.button2')}</span>
                            <FaArrowRight />
                        </a>
                    </div>
                </div>
            </div>
            <div className="absolute w-full h-full z-20 flex items-center md:items-start justify-center text-left flex-col top-0 left-0 right-0 bg-gradient-to-t from-[#079fdb] to-[#079fdb10] from-[10%] to-[100%]"></div>
            <video
                autoPlay
                loop
                muted
                className="absolute z-10 w-auto min-w-full min-h-full max-w-none"
            >
                <source src="/video.mp4" type="video/mp4" />
            </video>
        </section>
    );
}

export default Hero;