import { FC } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';

interface ToggleMenuProps {
    isOpen: boolean;
    handleToggleMenu: () => void;
}

const ToggleMenu: FC<ToggleMenuProps> = ({ isOpen, handleToggleMenu }) => {
    return (
        <button className='block md:block lg:hidden' onClick={handleToggleMenu}>
            {isOpen ? <FaTimes className="text-3xl text-primary dark:text-secondary" /> : <FaBars className="text-3xl text-primary dark:text-secondary h-1/2" />}
        </button>
    )
};

export default ToggleMenu;