import { Fragment, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import 'aos/dist/aos.css';
import AOS from 'aos';

import Header from './components/Header';
import Footer from './components/Footer';

// Pages
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Product from './pages/Product/index';
import DetailProduct from './pages/Product/Detail';
import Career from './pages/Career';
import Profile from './pages/Profile'

import { useTranslation } from 'react-i18next';

// Language routes setup
const locales = ['en', 'id', 'zh', 'ru'] as const;
type Locale = typeof locales[number];

// Utility function to get the locale from pathname
const getLocaleFromPathname = (pathname: string): Locale => {
  const path = pathname.split('/')[1];
  return locales.includes(path as Locale) ? (path as Locale) : 'id'; // Default to 'id' if not found
};

function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 600 });

    // Automatically detect and set the language based on the URL
    const locale = getLocaleFromPathname(window.location.pathname);
    i18n.changeLanguage(locale);
  }, [i18n]);

  const currentLang = i18n.language || 'id';

  const isProfilePage = location.pathname.includes('/profile');

  return (
    <Fragment>
      <Helmet>
        <html lang={currentLang} />
        <title>{t('metadata.title')}</title>
        <meta name="title" content={`${t('metadata.title')}`} />
        <meta name="description" content={t('metadata.description')} />
        <meta name="keywords" content={t('metadata.keyword')} />

        <meta itemProp="title" content={`${t('metadata.title')}`} />
        <meta itemProp="description" content={t('metadata.description')} />
        <meta itemProp="image" content={t('metadata.thumbnail')} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${t('metadata.title')}`} />
        <meta property="og:description" content={t('metadata.description')} />
        <meta property="og:url" content="https://yatu.co.id/" />
        <meta property="og:image" content={t('metadata.thumbnail')} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={`${t('metadata.title')}`} />
        <meta property="twitter:description" content={t('metadata.description')} />
        <meta property="twitter:url" content="https://yatu.co.id/" />
        <meta property="twitter:image" content={t('metadata.thumbnail')} />

        <meta name="robots" content="index, follow" />

        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href="https://yatu.co.id/" />
      </Helmet>
      {!isProfilePage && <Header />} {/* Conditionally render Header */}
      <main>
        <h1 className="firstHeading">Solusi Powder Coating Inovatif dan Berkualitas Tinggi dari Yatu Indonesia</h1>
        <Routes>
          {locales.map((locale) => (
            <Fragment key={locale}>
              <Route path={`/${locale}`} element={<Home />} />
              <Route path={`/${locale}/about-us`} element={<AboutUs />} />
              <Route path={`/${locale}/contact-us`} element={<ContactUs />} />
              <Route path={`/${locale}/product`} element={<Product />} />
              <Route path={`/${locale}/product/:slug`} element={<DetailProduct />} />
              <Route path={`/${locale}/career`} element={<Career />} />
              <Route path={`/${locale}/profile/:name`} element={<Profile />} />
            </Fragment>
          ))}
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/product" element={<Product />} />
          <Route path="/product/:slug" element={<DetailProduct />} />
          <Route path="/career" element={<Career />} />
          <Route path="/profile/:name" element={<Profile />} />
        </Routes>
      </main>
      <div className='p-3 h-auto fixed text-center w-auto z-[100] rounded-full bg-white right-5 bottom-5 shadow-[-10px_-10px_15px_rgba(70,70,70,0.12),10px_10px_15px_rgba(70,70,70,0.12)]'>
        <a href="https://wa.me/6285179995559" target='_blank' className='cta-btn-whatsapp group bg-white'>
          <img src="/images/whatsapp.png" width={40} height={40} alt="wa" />
        </a>
      </div>
      {!isProfilePage && <Footer />} {/* Conditionally render Footer */}
    </Fragment>
  );
}

export default App;
