// src/components/Breadcrumb.tsx
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Breadcrumb = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language || 'id';
  const pathSegments = location.pathname.split('/').filter((segment) => segment);

  const [brands, setBrands] = useState('');
  const [banner, setBanner] = useState('');
  const [productDetail, setProductDetail] = useState('');


  const buildBreadcrumb = () => {
    return pathSegments.map((segment, index) => {
      const path = `/${pathSegments.slice(0, index + 1).join('/')}`;
      return { path, label: decodeURIComponent(segment) };
    });
  };

  const breadcrumb = buildBreadcrumb();

  useEffect(() => {
    if (breadcrumb.length > 0) {
      switch (breadcrumb[1]?.path) {
        case `/${currentLang}/about-us`:
          setBrands(t('breadcrumb.about'));
          setBanner('/images/banner/bg-Breadcrumb-01.jpg');
          break;
        case `/${currentLang}/product`:
          setBrands(t('breadcrumb.product'));
          setBanner('/images/banner/bg-Breadcrumb-02.jpg');
          if (breadcrumb.length > 2) {
            switch (breadcrumb[2].label) {
              case 'yatu-for-automotive':
                setProductDetail(t(`breadcrumb.automotive`));
                setBanner('/images/banner/bg-Breadcrumb-03.jpg');
                break;
              case 'yatu-for-architecture':
                setProductDetail(t(`breadcrumb.architecture`));
                setBanner('/images/banner/bg-Breadcrumb-04.jpg');
                break;
              case 'yatu-for-industry':
                setProductDetail(t(`breadcrumb.industry`));
                setBanner('/images/banner/bg-Breadcrumb-05.jpg');
                break;
              case 'yatu-for-equipment':
                setProductDetail(t(`breadcrumb.equipment`));
                setBanner('/images/banner/bg-Breadcrumb-06.jpg');
                break;
              case 'yatu-for-hardware':
                setProductDetail(t(`breadcrumb.hardware`));
                setBanner('/images/banner/bg-Breadcrumb-07.jpg');
                break;
              case 'yatu-for-ace':
                setProductDetail(t(`breadcrumb.ace`));
                setBanner('/images/banner/bg-Breadcrumb-08.jpg');
                break;
              case 'yatu-for-retail':
                setProductDetail(t(`breadcrumb.retail`));
                setBanner('/images/banner/bg-Breadcrumb-09.jpg');
                break;
              case 'yatu-for-rebar':
                setProductDetail(t(`breadcrumb.rebar`));
                setBanner('/images/banner/bg-Breadcrumb-10.jpg');
                break;
              case 'yatu-for-beyond_metal':
                setProductDetail(t(`breadcrumb.beyond_metal`));
                setBanner('/images/banner/bg-Breadcrumb-11.jpg');
                break;
              case 'yatu-for-lighting':
                setProductDetail(t(`breadcrumb.lighting`));
                setBanner('/images/banner/bg-Breadcrumb-12.jpg');
                break;
              case 'yatu-for-aplikator':
                setProductDetail(t(`breadcrumb.aplikator`));
                setBanner('/images/banner/bg-Breadcrumb-13.jpg');
                break;
              case 'yatu-for-furniture':
                setProductDetail(t(`breadcrumb.furniture`));
                setBanner('/images/banner/bg-Breadcrumb-14.jpg');
                break;
              default:
                setBrands("");
                setBanner("");
                setProductDetail("");
            }
          }
          break;
        case `/${currentLang}/blog`:
          setBrands(t('breadcrumb.blog'));
          setBanner('/images/banner/bg-Breadcrumb-01.jpg');
          break;
        case `/${currentLang}/career`:
          setBrands(t('breadcrumb.career'));
          setBanner('/images/banner/bg-Breadcrumb-01.jpg');
          break;
        case `/${currentLang}/contact-us`:
          setBrands(t('breadcrumb.contact'));
          setBanner('/images/banner/bg-Breadcrumb-01.jpg');
          break;
        default:
          setBrands("");
          setBanner("");
          setProductDetail("");
          
      }
    }
  }, [breadcrumb, currentLang, t]);

  return (
    <div className="relative w-full h-fit flex items-center bg-center bg-cover bg-no-repeat" style={{ backgroundImage: `url(${banner})` }}>
      <div className="absolute w-full h-full bg-gradient-to-r from-[#079EDB] via-[#079EDB]/70 to-[#079EDB] overlay z-10"></div>
      <div className="relative container z-20">
        <div className="w-full flex items-center justify-start">
          <nav className="w-full flex flex-col items-center space-x-2 text-white font-semibold">
            {breadcrumb.length > 0 && (
              <span className="w-full flex flex-col items-start justify-between m-0">
                <div className='w-6/12 mb-20 mt-32'>
                <h2 className='text-6xl font-poppins font-bold capitalize'>{productDetail.replace('-', ' ').replace('-', ' ') || brands}</h2>
                </div>
                <hr className='border-t-[0.1px] w-full' />
                <div className='mt-8 mb-24 flex items-center'>
                  <img src='/images/icon-breadcrumb.svg' />
                  <Link to="/" className="hover:underline ml-2">{t('menu.home')}</Link>
                  <span className="mx-2">-</span>
                  <span>{brands}</span>
                  {
                    productDetail && (
                      <>
                        <span className="mx-2">-</span>
                        <span>{productDetail.replace('-', ' ').replace('-', ' ')}</span>
                      </>
                    )
                  }
                </div>
              </span>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
