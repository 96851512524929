import { BsFilePersonFill } from 'react-icons/bs';
import { FaWhatsapp } from "react-icons/fa";
import { useParams } from "react-router-dom"

interface Website {
  logo: string,
  name: string,
  role: string,
  email: string,
  url: string,
  address: string,
  map: string
}

interface ProfileData {
  username: string;
  photo: string;
  name: string;
  position: string;
  email: string;
  phone: string;
  wa: string;
  website: Website[];
}

const data: ProfileData[] = [
    {
        username: 'wawanfintara',
        photo: '/images/profile/wawanfintara.jpeg',
        name: 'Wawan Fintara',
        position: 'Chief Executive Officer',
        email: ' wawanfintara@yatu.co.id',
        phone: '+62 812 2121 9993',
        wa: '6281221219993',
        website: [
            {
                logo: '/images/yatu.png',
                name: 'PT. Xing Yatu Indonesia',
                role: 'Chief Executive Officer',
                email: 'wawanfintara@yatu.co.id',
                url: 'https://yatu.co.id/',
                address: 'Jl. Industri 3 Blok G No. 08 RT. 002 / RW. 004 Pasir Jaya Kec. Jatiuwung, Kota Tangerang-Banten',
                map: 'https://maps.app.goo.gl/XhK93jzfP3E2oX6p6'
            }
        ],
    },
    {
        username: 'andriyanto',
        photo: '/images/profile/andriyanto.jpeg',
        name: 'Andriyanto',
        position: 'Commissioner',
        email: ' andriyanto@yatu.co.id',
        phone: '+62 811 351 668',
        wa: '64225675566',
        website: [
            {
                logo: '/images/yatu.png',
                name: 'PT. Xing Yatu Indonesia',
                role: 'Commissioner',
                email: 'andriyanto@yatu.co.id',
                url: 'https://yatu.co.id/',
                address: 'Jl. Industri 3 Blok G No. 08 RT. 002 / RW. 004 Pasir Jaya Kec. Jatiuwung, Kota Tangerang-Banten',
                map: 'https://maps.app.goo.gl/XhK93jzfP3E2oX6p6'
            }
        ],
    }
];

const Profile: React.FC = () => {
    const { name } = useParams<{ name: string }>();
    const profile = data.filter(item => item.username === name);

    if (!profile.length) return null;

  return (
    <section className="relative m-0 p-0 w-full max-w-lg mx-auto bg-[#FFFFFF] z-[999] overflow-hidden">
        {profile.map((item, index) => (
            <div key={index} className="relative w-full px-4 mx-auto z-[99]">
                <div className="relative py-10 w-full flex items-center justify-between gap-x-4">
                    <a href={`tel:${item.phone}`} className="py-4 px-5 bg-[#069EDB] flex items-center justify-center gap-x-1 rounded-[50px]">
                        <BsFilePersonFill className='text-white' />
                        <span className="text-base text-white font-Roboto font-normal">Save To Contact</span>
                    </a>
                    <a href={`https://wa.me/${item.wa}`} target="_blank" className="py-4 px-5 bg-[#25D366] flex items-center justify-center gap-x-1 rounded-[50px]">
                        <FaWhatsapp className='w-6 h-6 text-white' />
                        <span className="text-base text-white font-Roboto font-normal">WhatsApp</span>
                    </a>
                </div>
                <div className='relative w-full flex flex-col items-center justify-center mb-10'>
                    <img src={item.photo} alt="" className="w-[200px] h-[200px] object-cover object-top rounded-full outline outline-offset-8 outline-1 outline-[#069EDB]" width={200} height={200} />
                    <p className='text-4xl text-black font-Roboto font-semibold mt-5 mb-2'>{item.name}</p>
                    <span className='text-base text-black font-Roboto font-normal'>{item.position}</span>
                </div>
                <div className="relative w-full p-6 bg-[#069EDB] rounded-3xl mb-10 border">
                    <div className="flex items-center justify-start gap-x-2 mb-6">
                        <BsFilePersonFill className="w-6 h-7 text-white" />
                        <span className="text-xl text-white font-sans font-semibold uppercase">Contact</span>
                    </div>
                    <div className="relative flex flex-col items-start justify-start gap-y-2 mb-7 pb-7 border-b">
                        <span className="text-sm text-white/80 font-Roboto font-normal">Name</span>
                        <div className="text-base text-white font-semibold font-Roboto">{item.name}</div>
                    </div>
                    <div className="relative flex flex-col items-start justify-start gap-y-2">
                        <span className="text-sm text-white/80 font-Roboto font-normal">Phone</span>
                        <div className="text-base text-white font-semibold font-Roboto">{item.phone}</div>
                    </div>
                </div>
                {item.website.map((item, index) => (
                <div key={index} className="relative w-full p-6 bg-[#069EDB] rounded-3xl mb-10 border">
                    <div className="relative flex flex-row items-center justify-start gap-x-2 mb-7">
                      <img src={item.logo} width={100} height={90} className='w-auto h-auto' alt='logo yatu' />
                    </div>
                    <div className="relative flex flex-col items-start justify-start gap-y-2 mb-7 pb-7 border-b">
                        <span className="text-sm text-white/80 font-Roboto font-normal">Role</span>
                        <div className="text-base text-white font-semibold font-Roboto">{item.role}</div>
                    </div>
                    <div className="relative flex flex-col items-start justify-start gap-y-2 mb-7 pb-7 border-b">
                        <span className="text-sm text-white/80 font-Roboto font-normal">Email</span>
                        <a href={`mailto:${item.email}`} className="text-base text-white font-semibold font-Roboto" target='_blank'>{item.email}</a>
                    </div>
                    <div className="relative flex flex-col items-start justify-start gap-y-2 mb-7 pb-7 border-b">
                        <span className="text-sm text-white/80 font-Roboto font-normal">Website</span>
                        <a href={item.url} className="text-base text-white font-semibold font-Roboto" target='_blank'>{item.url}</a>
                    </div>
                    <div className="relative flex flex-row items-center justify-between gap-y-2">
                      <div className='w-7/12 flex flex-col'>
                          <span className="text-sm text-white/80 font-Roboto font-normal">Address</span>
                          <div className="text-base text-white font-semibold font-Roboto">{item.address}</div>
                      </div>
                      <a href={item.map} target='_blank' className="bg-white py-3 px-6 text-[#069EDB] text-sm font-Roboto font-semibold rounded-[50px]">Show On Map</a>  
                    </div>
                </div>
                ))}
            </div>
        ))}
        <div className='relative w-full bg-[#069EDB] flex items-center justify-center gap-x-2 py-4 mt-4 rounded-t-[35px] border z-[99]'>
            <img src="/images/yatu.png" width={100} height={90} className='w-auto h-auto' alt='logo yatu' />
        </div>
    </section>
  );
};

export default Profile;