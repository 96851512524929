import { Fragment } from 'react'

import Breadcrumb from '../components/Breadcrumb'
import Information from '../components/Information'

export default function ContactUs() {
  return (
    <Fragment>
        <Breadcrumb />
        <Information />
    </Fragment>
  )
}
