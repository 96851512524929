import { FaArrowRight } from "react-icons/fa";
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom';

const AboutUsSection = () => {
    const { i18n, t } = useTranslation();
    const location = useLocation();
    const currentLang = i18n.language || 'id';
    const isHidden = location.pathname === `/${currentLang}/about-us`
    return (
        <section className="relative w-full h-auto py-20 bg-white dark:bg-black overflow-hidden">
            <div className="container">
                <div className="relative grid grid-cols-1 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-1 gap-10">
                    <div className="md:col-span-1 lg:col-span-7 xl:col-span-6" data-aos="fade-right">
                        <div className="relative w-full flex items-center justify-center">
                            <img
                                src="/images/about/banner.png" 
                                width={490} 
                                height={560} 
                                className="relative w-auto h-auto rounded-[15px]" alt="about"
                            />
                        </div>
                    </div>
                    <div className="lg:col-span-5 xl:col-span-6 mt-14 lg:mt-4" data-aos="fade-left">
                        <p className="relative text-lg text-blue font-poppins font-semibold mb-4 before:absolute before:content-[''] before:bg-blue before:w-[24px] before:h-0.5 pl-[30px] before:translate-x-0 before:top-[50%] before:left-0  before:rounded-l-lg before:rounded-r-lg">{t('home.about.heading')}</p>
                        <h2 className="text-xl text-[#12174D] dark:text-white font-poppins font-bold mb-8">{t('home.about.subheading')}</h2>
                        <p className="text-lg text-gray dark:text-white font-sarala font-normal leading-10 lg:leading-normal xl:leading-10">{t('home.about.description')}</p>
                        <div className={`w-full flex flex-col lg:flex-row xl:flex-row items-center justify-center mt-10 ${isHidden ? '': 'mb-10 pb-10 lg:mb-10 lg:pb-10 xl:mb-20 xl:pb-20 border-b-[.5px] border-b-gray'}`}>
                            <div className="w-full flex flex-row items-center">
                                <img src="/images/silver_warrant.png" width={50} height={50} alt={t('home.about.award.silver')} className="w-[98px] h-[98px] lg:w-[60px] lg:h-[60px] xl:w-[98px] xl:h-[98px] drop-shadow-2xl" />
                                <span className="ml-10 lg:ml-5 text-lg lg:text-sm text-[#12174D] dark:text-white font-poppins font-semibold">{t('home.about.award.silver')}</span>
                            </div>
                            <div className="w-full flex flex-row items-center">
                                <img src="/images/gold_warrant.png" width={50} height={50} alt={t('home.about.award.gold')} className="w-[98px] h-[98px] lg:w-[60px] lg:h-[60px] xl:w-[98px] xl:h-[98px] drop-shadow-2xl" />
                                <span className="ml-10 lg:ml-5 text-lg lg:text-sm text-[#12174D] dark:text-white font-poppins font-semibold">{t('home.about.award.gold')}</span>
                            </div>
                        </div>
                        <div className={`${isHidden ? 'hidden': 'flex'} items-center`}>
                            <a href="/about-us" className="text-base text-white font-poppins font-normal flex items-center gap-x-4 p-5 bg-blue hover:bg-blue/80 rounded-2xl transition-all">
                                <span>{t('home.about.button1')}</span>
                                <FaArrowRight />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUsSection;