import { FC } from "react";

/* eslint-disable react/prop-types */
interface LinkedInProps {
  iconClass: any;
}

const LinkedIn: FC<LinkedInProps> = ({ iconClass }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000" fill="currentColor" className={`${iconClass}`}>
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
        <path d="M2315 5109 c-800 -83 -1501 -518 -1927 -1196 -604 -960 -491 -2229
        272 -3065 423 -463 951 -740 1585 -830 118 -17 511 -16 635 0 579 80 1097 337
        1491 739 130 132 157 163 243 277 256 341 423 757 488 1211 17 118 17 512 0
        630 -43 303 -122 561 -247 814 -134 268 -270 459 -483 674 -395 400 -889 649
        -1457 733 -123 18 -478 26 -600 13z m-660 -1105 c71 -25 156 -111 180 -184 58
        -170 -20 -349 -179 -409 -187 -70 -377 -12 -462 141 -27 49 -29 61 -29 153 0
        84 4 107 22 141 47 88 116 144 212 171 59 17 190 10 256 -13z m1900 -859 c101
        -28 213 -92 285 -165 77 -78 123 -153 162 -262 55 -157 58 -195 58 -859 l0
        -609 -310 0 -310 0 0 548 c0 432 -3 561 -14 613 -26 122 -75 192 -164 236 -79
        39 -191 40 -272 2 -72 -34 -132 -96 -172 -177 l-33 -67 -3 -577 -3 -578 -310
        0 -309 0 0 940 0 940 310 0 310 0 0 -132 1 -133 25 30 c14 17 43 51 64 77 73
        86 217 165 345 188 71 13 269 4 340 -15z m-1737 -957 l2 -938 -315 0 -315 0 0
        933 c0 514 3 937 7 940 4 4 144 6 312 5 l306 -3 3 -937z"/>
      </g>
    </svg>
  )
}

export default LinkedIn