import { useEffect, useState } from "react";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import SocialMedia from "./SocialMedia";
import Fitur from "../Fitur";
import { useTranslation } from 'react-i18next'
import { menu } from "../json/menu";
import { useLocation } from 'react-router-dom';
import ToggleMenu from "./ToggleMenu";

const Header = () => {
    const { i18n, t } = useTranslation();
    const location = useLocation();
    const [ isSticky, setIsSticky ] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleScroll = () => {
		const scrollTop = window.scrollY;
        setIsSticky(scrollTop >= 1)
	};

    const handleToggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
    });

    const currentLang = i18n.language || 'id';
    return (
        <header className={`${isSticky ? 'fixed top-0' : 'relative top-0'} w-full h-auto bg-white transition-all z-[9999]`}>
            <div className="relative w-full p-4 bg-blue">
                <div className="container ">
                    <div className="flex items-center justify-between">
                        <div className="w-full hidden md:flex items-center">
                            <ul className="flex items-center gap-x-6">
                                <li>
                                    <a href="mailto:cs@yatu.co.id" className="flex items-center gap-x-2">
                                        <FaEnvelope className="text-base text-white" />
                                        <span className="text-white text-sm font-poppins font-normal underline">cs@yatu.co.id</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="tel:6285179995559" className="flex items-center gap-x-2">
                                        <FaPhoneAlt className="text-base text-white" />
                                        <span className="text-white text-sm font-poppins font-normal">(+62) 851 7999 5559</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="w-full flex items-center justify-between md:justify-end gap-x-10">
                            <SocialMedia colorText="text-white" sizeIcon="w-6 h-6" />
                            <Fitur />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${isSticky ? 'fixed top-0 pt-0 pb-0' : 'relative pt-5 pb-3'} left-0 right-0 w-full h-auto bg-white transition-all z-[999]`}>
                <div className="container px-10">
                    <div className={`w-full ${isSticky ? 'h-20' : 'h-20'} flex items-center justify-between bg-white transition-all`}>
                        <a href={`/${currentLang}`} className="relative flex flex-1 items-center gap-x-2 text-3xl font-bold text-primary dark:text-secondary">
                            <img src="/images/yatulogo.svg" alt="logo yatu" className={`w-auto ${isSticky ? 'h-14' : 'h-16'} transition-all`} />
                        </a>
                        <ToggleMenu isOpen={isOpen} handleToggleMenu={handleToggleMenu} />
                        <nav className="items-center justify-between hidden w-full md:hidden lg:flex md:w-auto">
                            <ul className="flex flex-row gap-x-10">
                                {menu.map((item, index) => {
                                    const isActive = location.pathname === `/${currentLang}${item.link}` || location.pathname.startsWith(`/${currentLang}${item.link}/`);
                                    return (
                                        <li key={index} className={`group hover:bg-secondary hover:text-blue cursor-pointer ${isActive ? 'text-blue font-bold' : 'text-gray font-medium'}`}>
                                            <a href={`/${currentLang}${item.link}`} className="block group-hover:text-blue text-base font-poppins capitalize">{t(`menu.${item.slug}`)}</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <div className={`fixed w-full h-screen xl:hidden lg:hidden py-4 right-0 top-0 md:top-[116px] z-[999] bg-white dark:bg-black ease-in-out transition-all overflow-x-hidden ${isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
                <div className="relative container">
                    <div className="relative w-full flex items-center justify-between overflow-hidden">
                        <a href={`/${currentLang}`} className="relative flex flex-1 items-center gap-x-2 text-3xl font-bold text-primary dark:text-secondary">
                            <img src="/images/yatulogo.svg" alt="logo yatu" className={`w-auto ${isSticky ? 'h-14' : 'h-16'} transition-all`} />
                        </a>
                        <ToggleMenu isOpen={isOpen} handleToggleMenu={handleToggleMenu} />
                    </div>
                    <nav className="py-16">
                        <ul className="flex flex-1 flex-col items-start justify-center space-y-10">
                            {menu.map((item, index) => {
                                const isActive = location.pathname === `/${currentLang}${item.link}` || location.pathname.startsWith(`/${currentLang}${item.link}/`);
                                return (
                                    <li key={index} className={`group hover:bg-secondary hover:text-blue cursor-pointer ${isActive ? 'text-blue font-bold' : 'text-gray font-medium'}`}>
                                        <a href={`/${currentLang}${item.link}`} className="block group-hover:text-blue text-base font-poppins capitalize">{t(`menu.${item.slug}`)}</a>
                                    </li>
                                )
                            })}
                        </ul>
                    </nav>
                    <div className="flex item-center justify-center">
                        <SocialMedia colorText="text-blue" sizeIcon="w-8 h-8" />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;