import Mode from './Mode';
import Language from './Languange';

const Fitur = () => {
    return (
        <div className='flex items-center gap-4'>
            <Language />
            <Mode />
        </div>
    );
}

export default Fitur;