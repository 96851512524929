import { useTheme } from '../context/ThemeContext';
import { FaMoon, FaSun } from "react-icons/fa6";

const Mode = () => {
    const { theme, toggleTheme  } = useTheme();

    return (
        <button
            className='p-1 bg-white rounded-full'
            onClick={() => 
                toggleTheme()
            }
        >
            {
                theme === 'dark' ? (
                    <FaSun className='text-yellow-500' />
                ) : (
                    <FaMoon className='text-gray-900' />
                )
            }
        </button>
    );
}

export default Mode;