import { Fragment } from "react";
import Breadcrumb from "../components/Breadcrumb";
import ReviewEmployee from "../components/ReviewEmployee";
import Gallery from "../components/Gallery";
import BannerCareer from "../components/BannerCareer";

const Career = () => {
    return (
        <Fragment>
            <Breadcrumb />
            <BannerCareer />
            <ReviewEmployee />
            <Gallery />
        </Fragment>
    );
}

export default Career;