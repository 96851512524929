import React, { ReactNode, useState } from 'react';
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
// import axios from 'axios';

interface FormData {
    full_name: string;
    email: string;
    subject: string;
    phone: string;
    message: string;
}

const ContactInfo: React.FC<{ icon: JSX.Element; label: string; text: ReactNode }> = ({ icon, label, text }) => (
    <div className='flex items-start gap-x-4'>
        <span className='p-5 flex items-center justify-center bg-blue/10 dark:bg-white/90 rounded-full'>
            {icon}
        </span>
        <div className="flex flex-col gap-y-2">
            <p className="text-lg text-black/80 dark:text-white ml-2 font-poppins font-semibold">{label}</p>
            <p className="text-sm text-black/70 dark:text-white ml-2 font-sarala">{text}</p>
        </div>
    </div>
);

const Information: React.FC = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<FormData>({
        full_name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });
    const [loading, setLoading] = useState(false);  // Loading state
    const [error, setError] = useState<string | null>(null);  // Error state
    const [success, setSuccess] = useState<boolean>(false);  // Success state

    const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit: React.FormEventHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(false);
        // try {
        //     const response = await axios.post('http://103.181.143.240:3002/api/v1/send-email', formData);
        //     if (response.status === 200) {
        //         setSuccess(true);
        //         console.log('Email sent successfully!');
        //         setFormData({ full_name: '', email: '', subject: '', phone: '', message: '' });
        //     }
        // } catch (error) {
        //     console.error('Error sending email:', error);
        //     setError('Failed to send the email. Please try again.');
        // } finally {
        //     setLoading(false);
        // }
    };

    return (
        <section className='relative w-full h-auto py-20 bg-white dark:bg-black'>
            <div className="container">
                <div className='w-full rounded-2xl p-0 md:p-16 shadow-[0px_15px_50px_rgba(108,146,181,10%)]'>
                    <div className='flex flex-col md:flex-row items-start gap-y-10 md:gap-y-0'>
                        <div className='w-full md:w-6/12'>
                            <div className='w-full pb-12'>
                                <h2 className='text-4xl font-poppins font-bold text-blue'>{t('contact.heading')}</h2>
                            </div>
                            <div className='flex flex-col gap-8'>
                                <ContactInfo
                                    icon={<FaMapMarkerAlt className='text-2xl text-blue' />}
                                    label={t('contact.office')}
                                    text={
                                        <>
                                            Jl. Industri Raya 3 Blok G No. 08<br />RT. 002 / RW. 004 Pasir Jaya<br />Kec. Jatiuwung, Kota Tangerang, <br /> Banten, 15135
                                        </>
                                    }
                                />
                                <ContactInfo
                                    icon={<FaEnvelope className='text-2xl text-blue' />}
                                    label={t('contact.email')}
                                    text="cs@yatu.co.id"
                                />
                                <ContactInfo
                                    icon={<FaPhoneAlt className='text-2xl text-blue' />}
                                    label={t('contact.phone')}
                                    text="(+62) 851 7999 5559"
                                />
                            </div>
                        </div>
                        <div className='w-full md:w-6/12'>
                            <form onSubmit={handleSubmit}>
                                <div className="grid gap-6 gap-y-4 text-sm grid-cols-1 md:grid-cols-6">
                                    <div className="md:col-span-3">
                                        <label className="font-medium font-sarala text-black dark:text-white" htmlFor="full_name">{t('contact.form.name')}</label>
                                        <input 
                                            type="text" 
                                            name="full_name" 
                                            id="full_name" 
                                            className="mt-2 rounded-xl p-4 w-full outline-none text-black bg-white border-2 border-[#D9D9D9] hover:border-gray focus:border-gray dark:border-white dark:hover:border-white dark:focus:border-white transition-all placeholder:font-sarala" 
                                            value={formData.full_name} 
                                            onChange={handleChange} 
                                            placeholder={t('contact.form.name')}
                                        />
                                    </div>

                                    <div className="md:col-span-3">
                                        <label className="font-medium font-sarala text-black dark:text-white" htmlFor="phone">{t('contact.form.phone')}</label>
                                        <input 
                                            type="tel" 
                                            name="phone" 
                                            id="phone" 
                                            className="mt-2 rounded-xl p-4 w-full outline-none text-black bg-white border-2 border-[#D9D9D9] hover:border-gray focus:border-gray dark:border-white dark:hover:border-white dark:focus:border-white transition-all placeholder:font-sarala" 
                                            value={formData.phone} 
                                            onChange={handleChange} 
                                            placeholder={t('contact.form.phone')}
                                        />
                                    </div>

                                    <div className="md:col-span-3">
                                        <label className="font-medium font-sarala text-black dark:text-white" htmlFor="email">{t('contact.form.email')}</label>
                                        <input
                                            type="email" 
                                            name="email" 
                                            id="email" 
                                            className="mt-2 rounded-xl p-4 w-full outline-none text-black bg-white border-2 border-[#D9D9D9] hover:border-gray focus:border-gray dark:border-white dark:hover:border-white dark:focus:border-white transition-all placeholder:font-sarala"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder={t('contact.form.email')}
                                        />
                                    </div>

                                    <div className="md:col-span-3">
                                        <label className="font-medium font-sarala text-black dark:text-white" htmlFor="subject">{t('contact.form.subject')}</label>
                                        <input 
                                            type="text" 
                                            name="subject" 
                                            id="subject" 
                                            className="mt-2 rounded-xl p-4 w-full outline-none text-black bg-white border-2 border-[#D9D9D9] hover:border-gray focus:border-gray dark:border-white dark:hover:border-white dark:focus:border-white transition-all placeholder:font-sarala" 
                                            value={formData.subject} 
                                            onChange={handleChange} 
                                            placeholder={t('contact.form.subject')}
                                        />
                                    </div>

                                    <div className="md:col-span-6">
                                        <label className="font-medium font-sarala text-black dark:text-white" htmlFor="message">{t('contact.form.message')}</label>
                                        <textarea 
                                            name="message" 
                                            id="message" 
                                            className="mt-2 rounded-xl p-4 w-full outline-none text-black bg-white border-2 border-[#D9D9D9] hover:border-gray focus:border-gray dark:border-white dark:hover:border-white dark:focus:border-white transition-all placeholder:font-sarala" 
                                            rows={5} 
                                            value={formData.message} 
                                            onChange={handleChange} 
                                            placeholder={t('contact.form.message')}
                                        ></textarea>
                                    </div>
                                </div>
                                <button
                                    id="cta_submit"
                                    className="cta-btn-form bg-blue font-sarala text-white font-semibold px-8 py-4 mt-4 !mx-auto rounded-[10px]"
                                    disabled={loading}
                                >
                                    {loading ? t('contact.sending') : t('contact.button')}
                                </button>
                            </form>
                            {error && <p className="text-red-500 mt-4">{error}</p>}
                            {success && <p className="text-green-500 mt-4">{t('contact.success')}</p>}
                        </div>
                    </div>
                    <div className="pt-8">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.490290693765!2d106.55960417562397!3d-6.1988614937888755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ff09a2468d1d%3A0xb3851b7dfc023c71!2sPT.%20Xing%20Yatu%20Indonesia!5e0!3m2!1sid!2sid!4v1723357150594!5m2!1sid!2sid"
                            width="100%"
                            height="500"
                            frameBorder="0"
                            style={{ border: 0 }}
                            aria-hidden="false"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Information;
