import { Fragment } from 'react'

import Breadcrumb from '../components/Breadcrumb'
import AboutUsSection from '../components/AboutUsSection'
import History from '../components/History'
import Point from '../components/Point'

const AboutUs = () => {
    return (
        <Fragment>
            <Breadcrumb />
            <AboutUsSection />
            <History />
            <Point />
        </Fragment>
    )
}

export default AboutUs;