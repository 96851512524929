import { FC, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';

interface ChoiceUsProps {
  id: number;
  icon: string;
  title: string;
  description: string;
}

interface CustomButtonGroupProps {
  next: () => void;
  previous: () => void;
  carouselState: {
    currentSlide: number;
    totalItems: number;
  };
  responsive: {
    superLargeDesktop: {
      items: number;
    };
  };
}

const ChoiceUs: FC = () => {
  const { t } = useTranslation();
  const choiceUs: ChoiceUsProps[] = [
    {
      id: 1,
      icon: '/images/choice/icon1.svg',
      title: t('home.chooce.chooce1.title'),
      description: t('home.chooce.chooce1.description')
    },
    {
      id: 2,
      icon: '/images/choice/icon2.svg',
      title: t('home.chooce.chooce2.title'),
      description: t('home.chooce.chooce2.description')
    },
    {
      id: 3,
      icon: '/images/choice/warranty.svg',
      title: t('home.chooce.chooce3.title'),
      description: t('home.chooce.chooce3.description')
    },
    {
      id: 4,
      icon: '/images/choice/delivery.svg',
      title: t('home.chooce.chooce4.title'),
      description: t('home.chooce.chooce4.description')
    },
    {
      id: 5,
      icon: '/images/choice/icon5.svg',
      title: t('home.chooce.chooce5.title'),
      description: t('home.chooce.chooce5.description')
    }
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [modalData, setModalData] = useState<ChoiceUsProps | null>(null);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1024, min: 820 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 820, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
  };

  const CustomButtonGroupAsArrows: FC<CustomButtonGroupProps> = ({ next, previous, carouselState, responsive }) => {
    const { currentSlide, totalItems } = carouselState;
    const maxSlides = totalItems - responsive.superLargeDesktop.items;

    return (
      <div className="absolute -top-6 right-0 flex items-center justify-center gap-x-4">
        <button
          onClick={previous}
          disabled={currentSlide === 0}
          className={`${
            currentSlide === 0 ? 'border-gray bg-white opacity-50 cursor-not-allowed' : 'border-blue bg-blue'
          } border-[1px] rounded-full p-2`}
        >
          <FaArrowLeft className={`text-xs md:text-2xl ${currentSlide === 0 ? 'text-gray' : 'text-white'}`} />
        </button>
        <button
          onClick={next}
          disabled={currentSlide >= maxSlides}
          className={`${
            currentSlide >= maxSlides ? 'border-gray bg-white opacity-50 cursor-not-allowed' : 'border-blue bg-blue'
          } border-[1px] rounded-full p-2`}
        >
          <FaArrowRight className={`text-xs md:text-2xl ${currentSlide >= maxSlides ? 'text-gray' : 'text-white'}`} />
        </button>
      </div>
    );
  };

  const Modal: FC<{ data: ChoiceUsProps, onClose: () => void }> = ({ data, onClose }) => {
    return (
      <div className="fixed top-10 inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="relative max-w-lg p-10 bg-white rounded-[35px] text-center">
          <div className="relative w-full">
            <img className="rounded-t-[25px] w-full h-64 object-cover object-center" src="/images/whyus.webp" alt="" />
            <div className="absolute -bottom-10 left-0 right-0 w-20 h-20 mx-auto bg-blue rounded-full flex items-center justify-center">
              <img src={data.icon} width={42} height={42} alt={data.title} />
            </div>
          </div>
          <div className="pt-14 pb-5">
            <h5 className="mb-2 text-[#12174D] text-2xl text-center font-poppins font-bold">{data.title}</h5>
            <p className="max-h-[200px] overflow-y-auto mb-3 text-base text-gray text-center font-sarala font-normal">{data.description}</p>
          </div>
          <button onClick={onClose} className="bg-white mx-auto text-black px-14 py-3 border-[.5px] border-gray/50 rounded">{t('home.chooce.button2')}</button>
        </div>
      </div>
    );
  };

  const Card: FC<{ data: ChoiceUsProps, onLearnMore: (id: number) => void }> = ({ data, onLearnMore }) => {
    return (
      <div className="relative inline-block bg-white dark:bg-[#313131] group hover:bg-[url('/images/whyus.webp')] bg-center bg-cover bg-no-repeat w-full h-auto md:w-auto md:h-auto md:mx-2 xl:w-[411px] xl:h-auto my-10 p-11 rounded-3xl shadow-[-10px_-10px_15px_rgba(255,255,255,0.5),10px_10px_15px_rgba(70,70,70,0.12)] dark:shadow-[10px_10px_19px_#1c1e22,-10px_-10px_19px_#262a2e] transition-all">
        <div className="absolute w-full h-full top-0 left-0 right-0 bg-gradient-to-b from-[#0E102D]/25 to-[#0D1730] rounded-3xl z-10 opacity-0 group-hover:opacity-100"></div>
        <div className="relative z-20">
          <div className="w-20 h-20 mx-auto bg-blue rounded-full flex items-center justify-center">
            <img src={data.icon} width={42} height={42} alt={data.title} />
          </div>
          <div className="pt-4 pb-10 flex flex-1 flex-col items-center justify-center gap-y-4 text-center">
            <h3 className="text-blue group-hover:text-white text-lg md:text-base font-poppins font-bold line-clamp-2">{data.title}</h3>
            <p className="text-gray dark:text-white group-hover:text-white text-base font-poppins font-normal line-clamp-3 leading-10">{data.description}</p>
          </div>
          <div className="flex items-center justify-center">
            <button onClick={() => onLearnMore(data.id)} className="flex items-center bg-white group-hover:bg-[#39B54A] border-gray/50 group-hover:border-[#39B54A] border-[1px] rounded-[50px] py-4 px-10 text-black group-hover:text-white text-base font-sarala font-normal">
              <span>{t('home.chooce.button1')}</span>
              <FaArrowRight className="ml-3" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleBeforeChange = (nextSlide: number) => {
    setActiveIndex(nextSlide);
  };

  const handleLearnMore = (id: number) => {
    const data = choiceUs.find(item => item.id === id);
    setModalData(data || null);
  };

  const closeModal = () => {
    setModalData(null);
  };

  return (
    <section className="relative w-full h-auto py-20 bg-[#FAFAFF] dark:bg-[#212121] overflow-hidden">
      <div className="container">
        <div className="text-left flex flex-col" data-aos="fade-right">
          <p className="relative text-lg text-blue font-poppins font-semibold mb-5 before:absolute before:content-[''] before:bg-blue before:w-[24px] before:h-0.5 pl-[30px] before:translate-x-0 before:top-[50%] before:left-0 before:rounded-l-lg before:rounded-r-lg">{t('home.chooce.heading')}</p>
          <h2 className="text-lg md:text-xl text-[#12174D] dark:text-white font-poppins font-bold">{t('home.chooce.subheading')}</h2>
        </div>
        <div className="relative w-full pt-10" data-aos="flip-up">
          <Carousel
            responsive={responsive}
            ssr={true}
            arrows={false}
            infinite={false}
            autoPlay={false}
            containerClass="carousel-container"
            autoPlaySpeed={6000}
            focusOnSelect={true}
            customButtonGroup={<CustomButtonGroupAsArrows
                next={() => {}}
                previous={() => {}}
                carouselState={{ currentSlide: activeIndex, totalItems: choiceUs.length }}
                responsive={responsive}
            />}
            renderButtonGroupOutside={true}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            beforeChange={handleBeforeChange}
          >
            {choiceUs.map((item, index) => (
              <Card key={index} data={item} onLearnMore={handleLearnMore} />
            ))}
          </Carousel>
        </div>
      </div>
      {modalData && <Modal data={modalData} onClose={closeModal} />}
    </section>
  );
}

export default ChoiceUs;
