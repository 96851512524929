import { FC } from 'react';
import { Facebook, Instagram, LinkedIn, Tiktok, Youtube, Twitter } from './IconSocialMedia'

interface SocialMediaProps {
    colorText: string;
    sizeIcon: string;
}

const SocialMedia:FC<SocialMediaProps> = ({ colorText, sizeIcon }) => {
    return (
        <div className={`flex items-center gap-x-3 ${colorText}`}>
            <a href="https://www.facebook.com/yatu.co.id" target="_blank" rel="noreferrer" className="text-lg">
                <Facebook iconClass={`${sizeIcon}`} />
            </a>
            <a href="https://www.instagram.com/yatu.co.id/" target="_blank" rel="noreferrer" className="text-lg">
                <Instagram iconClass={`${sizeIcon}`} />
            </a>
            <a href="https://www.linkedin.com/company/yatu-indonesia/" target="_blank" rel="noreferrer" className="text-lg">
                <LinkedIn iconClass={`${sizeIcon}`} />
            </a>
            <a href="https://www.tiktok.com/@yatu.co.id" target="_blank" rel="noreferrer" className="text-lg">
                <Tiktok iconClass={`${sizeIcon}`} />
            </a>
            <a href="https://www.youtube.com/@yatu.indonesia" target="_blank" rel="noreferrer" className="text-lg">
                <Youtube iconClass={`${sizeIcon}`} />
            </a>
            <a href="https://x.com/Yatu_Indonesia" target="_blank" rel="noreferrer" className="text-lg">
                <Twitter iconClass={`${sizeIcon}`} />
            </a>
        </div>
    )
};

export default SocialMedia;