import { useTranslation } from 'react-i18next'

interface PointProps {
    icon: string;
    title: string;
}


const Point = () => {
    const { t } = useTranslation();
    const pointData: PointProps[] = [
        {
            icon: "/images/icon/point1.svg",
            title: t('about.point.t1')
        },
        {
            icon: "/images/icon/point2.svg",
            title: t('about.point.t2')
        },
        {
            icon: "/images/icon/point3.svg",
            title: t('about.point.t3')
        },
        {
            icon: "/images/icon/point4.svg",
            title: t('about.point.t4')
        },
        {
            icon: "/images/icon/point5.svg",
            title: t('about.point.t5')
        },
        {
            icon: "/images/icon/point6.svg",
            title: t('about.point.t6')
        },
        {
            icon: "/images/icon/point7.png",
            title: t('about.point.t7')
        },
        {
            icon: "/images/icon/point8.svg",
            title: t('about.point.t8')
        }
    ]
    return (
        <section className="relative w-full h-auto py-20 bg-white dark:bg-black">
            <div className="container">
                <div className="text-left flex flex-col" data-aos="fade-down">
                    {/* <p className="relative text-lg text-blue font-poppins font-semibold mb-5 before:absolute before:content-[''] before:bg-blue before:w-[24px] before:h-0.5 pl-[30px] before:translate-x-0 before:top-[50%] before:left-0 before:rounded-l-lg before:rounded-r-lg">{t('home.chooce.heading')}</p> */}
                    <h2 className="text-xl text-[#12174D] dark:text-white font-poppins font-bold">{t('about.point.heading')}</h2>
                </div>
                <div className="relative w-full grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-8 mt-10" data-aos="fade-up">
                    {
                        pointData.map((item, index) => (
                            <div key={index} className="w-full relative px-8 py-14 flex flex-col items-center justify-center bg-white dark:bg-black rounded-3xl transition-all shadow-[-8px_-8px_24px_0_rgba(108,146,181,0.10),8px_8px_24px_0_rgba(108,146,181,0.10)] hover:shadow-[-8px_-8px_24px_0_rgba(33,33,33,0.10),8px_8px_24px_0_rgba(33,33,33,0.10)] dark:shadow-[-8px_-8px_24px_0_rgba(143,143,143,.05),8px_8px_24px_0_rgba(143,143,143,.05)]">
                                <div className="w-20 h-20 bg-[#069EDB] rounded-2xl flex items-center justify-center">
                                    <img src={item.icon} alt={item.title} width={80} height={80} className="w-auto h-auto" />
                                </div>
                                <h3 className="text-blue text-xl font-poppins font-semibold mt-8 text-center">{item.title}</h3>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default Point;