const content = {
    menu: {
        home: "Beranda",
        product: "Produk",
        blog: "Blog",
        career: "Karir",
        about: "Tentang Kami",
        contact: "Hubungi Kami"
    },
    metadata: {
        title: "Yatu Indonesia - Solusi Terbaik untuk Powder Coating Berkualitas Tinggi",
        description: "Yatu Indonesia, pemimpin global dalam solusi powder coating inovatif, menyediakan produk berkualitas tinggi dengan hasil sempurna untuk berbagai kebutuhan. Kunjungi kami untuk informasi lebih lanjut tentang layanan dan produk unggulan kami.",
        keyword: "Yatu Indonesia, powder coating, solusi coating, coating berkualitas, teknologi powder coating, coating tahan lama",
        thumbnail: "/thumbnail_id.jpg"
    },
    footer: {
        tagline: "Solusi Powder Coating Terbaik untuk Anda.",
        p1: "Tertarik Bekerja Dengan Kami",
        company: "Perusahaan",
        contact: "Hubungi Kami",
        follow: "Ikuti Kami",
        p2: "Semua Hak Dilindungi."
    },
    breadcrumb: {
        about: "Tentang Kami",
        product: "Produk",
        career: "Bergabung dengan Tim Kami",
        contact: "Hubungi Kami",
        automotive: "Detail Produk Otomotif",
        architecture: "Detail Produk Arsitektur",
        industry: "Detail Produk Industri",
        furniture: "Detail Produk Furniture",
        equipment: "Detail Produk Peralatan",
        hardware: "Detail Produk Perangkat Keras",
        ace: "Detail Produk ACE",
        retail: "Detail Produk Ritel",
        rebar: "Detail Produk Tulangan",
        beyond_metal: "Detail Produk Di Luar Logam",
        lighting: "Detail Produk Penerangan",
        aplikator: "Detail Produk Aplikator"
    },
    home: {
        hero: {
            heading: "SOLUSI TERBAIK UNTUK POWDER COATING PRIBADI ANDA",
            subheading: "Yatu Indonesia adalah perusahaan powder coating terkemuka yang berbasis di Tangerang, Indonesia, dengan sejarah panjang yang dimulai dari Foshan, Zhejiang. Dengan pengalaman lebih dari 20 tahun, kami berkomitmen untuk menyediakan produk powder coating berkualitas tinggi, stabil, dan tahan lama. Saat ini, kami sedang memperluas jaringan kami secara global dengan membuka cabang di berbagai lokasi strategis.",
            button1: "Layanan Kami",
            button2: "Hubungi Kami"
        },
        about: {
            heading: "Tentang Kami",
            subheading: "Merek Global Terdepan untuk Solusi Powder Coating",
            description: "Di YATU Indonesia, kami melihat setiap tantangan sebagai kesempatan untuk menunjukkan kemampuan kami. Keahlian kami dalam melapisi logam dengan kontur yang kompleks menjadikan kami mitra ideal untuk proyek-proyek yang dianggap sulit oleh banyak pihak. Kami senantiasa memberikan hasil yang melebihi harapan pelanggan kami.",
            total_year: "Garansi Global Bertahun-tahun",
            award: {
                silver: "Garansi Global Perak",
                gold: "Garansi Global Emas"
            },
            button1: "Temukan Lebih Lanjut"
        },
        chooce: {
            heading: "NILAI BISNIS",
            subheading: "Mengapa Memilih Kami?",
            chooce1: {
                title: "Warna Presisi",
                description: "Yatu Indonesia memahami pentingnya akurasi warna dalam setiap proyek. Dengan teknologi pencocokan warna mutakhir yang kami miliki, kami dapat mencapai tingkat akurasi hingga ±99%. Ini memastikan bahwa setiap visi Anda dapat diwujudkan secara sempurna, tanpa ketidakkonsistenan dan kekurangan warna."
            },
            chooce2: {
                title: "Para Ahli Warna yang Dapat Anda Percayai",
                description: "Di YATU Indonesia, tim ahli warna kami terdiri dari ilmuwan dan teknisi yang berpengalaman dalam mewujudkan permintaan warna yang paling rumit. Kami tidak hanya mencocokkan warna, tetapi juga memahami karakteristik dan esensi warna, sehingga memberikan hasil terbaik untuk proyek Anda."
            },
            chooce3: {
                title: "Nilai Terbaik yang Dijamin",
                description: "Di YATU Indonesia, kami memahami bahwa setiap pembelian harus disertai dengan ketenangan pikiran. Oleh karena itu, kami menawarkan jaminan harga yang kompetitif untuk pengembangan warna khusus, memastikan Anda mendapatkan nilai terbaik tanpa biaya tersembunyi. Dengan demikian, kami memberikan transparansi dan kepercayaan dalam setiap transaksi."
            },
            chooce4: {
                title: "Menciptakan Masa Depan yang Berkelanjutan",
                description: "YATU Indonesia berkomitmen untuk merintis solusi inovatif yang berdampak positif terhadap lingkungan. Kami secara aktif mengurangi limbah dengan menerapkan proses produksi tanpa limbah dan menginvestasikan sumber daya dalam teknologi mesin powder coating hemat energi. Selain itu, kami menawarkan program daur ulang, memungkinkan powder coating yang telah digunakan dapat didaur ulang atau digunakan kembali. Kami juga berusaha mendidik pelanggan tentang praktik pengelolaan limbah yang bertanggung jawab, yang berkontribusi dalam mengurangi jejak karbon secara keseluruhan. Melalui inisiatif ini, kami berkomitmen untuk menciptakan masa depan yang lebih berkelanjutan."
            },
            chooce5: {
                title: "Investasi dalam Kualitas",
                description: "Dengan dukungan dari pemasok inti seperti KINTE, SJ, dan Huahui, YATU Indonesia terus menjaga standar kualitas tertinggi untuk semua produk kami. Komitmen kami terhadap keunggulan tercermin dalam sertifikasi ISO 9001, penerapan protokol manajemen yang ketat, serta penggunaan peralatan pengujian yang komprehensif. Kami bangga menawarkan Garansi Global Emas 15 Tahun dan Garansi Global Perak 10 Tahun, menjamin keandalan produk dalam jangka panjang dan kepuasan pelanggan. Di YATU Indonesia, kami yakin bahwa investasi dalam kualitas adalah fondasi utama untuk kesuksesan jangka panjang."
            },
            button1: "Pelajari Lebih Lanjut",
            button2: "Tutup"
        },
        feature: {
            heading: "Fitur Utama",
            subheading: "Fitur Utama Kami",
            a1: {
                title: "Powder Premium Kami",
                description: "Produk unggulan kami mampu memberikan hasil pelapisan yang sempurna bahkan pada permukaan logam yang memiliki bentuk kompleks. Celah-celah sempit, sudut tajam, dan area yang sulit dijangkau dapat kami lapisi dengan hasil yang seragam, meningkatkan tampilan dan ketahanan produk."
            },
            a2: {
                title: "Solusi Tanpa Repot untuk Pelapisan Ulang",
                description: "Proses pelapisan ulang sering kali memakan waktu dan biaya tinggi. Namun, dengan powder coating dari YATU Indonesia, Anda dapat menghemat waktu dan biaya. Produk kami dirancang untuk memudahkan pelapisan ulang dan memberikan hasil optimal, bahkan jika diperlukan pelapisan ulang di kemudian hari."
            },
            a3: {
                title: "Teknologi Mesin Pengikat yang Canggih Mesin Pengikat Metalik",
                description: "Yatu Indonesia menghadirkan inovasi dalam dunia powder coating, menciptakan ikatan yang kuat dan permanen antara pigmen metalik dan partikel powder. Teknologi ini memungkinkan hasil pelapisan yang lebih tahan lama dan stabil."
            }
        },
        product: {
            heading: "Produk",
            subheading: "Produk Kami",
            p: "Powder Coating Yatu menawarkan berbagai solusi berkinerja tinggi untuk berbagai aplikasi. Apakah Anda berada di industri perangkat keras, industri, arsitektur, atau pencahayaan, Yatu memberi Anda kekuatan untuk mencapai hasil yang unggul."
        },
        client: {
            heading: "Klien",
            subheading: "Klien Kami"
        }
    },
    about: {
        history: {
            heading: "Sejarah",
            subheading: "Sejarah Yatu Indonesia",
            p: "Pabrik Zhejiang YATU telah memperluas bisnisnya ke Tangerang, Indonesia di bawah PT.",
            a1: {
                year: "2024",
                title: "Pabrik Baru",
                description: "Pabrik Zhejiang YATU memiliki memperluas ke tangerang, Indonesia di bawah PT. Xing Yatu Indonesia"
            },
            a2: {
                year: "2023",
                title: "Pabrik Baru",
                description: "Pabrik Zhejiang YATU telah dimulai operasi, menandai tonggak penting dalam upaya ekspansi kami, sekaligus mendirikan kantor penjualan Henan kami untuk lebih baik memenuhi kebutuhan kita yang berharga pelanggan di wilayah tersebut"
            },
            a3: {
                year: "2022",
                title: "Penghargaan teknologi tinggi",
                description: "Setelah menerima teknologi tinggi otorisasi, kami punya berhasil dipindahkan ke kami tempat baru, dilengkapi untuk lebih jauh memajukan kemampuan teknologi kami dan melayani dengan lebih baik pelanggan kami dengan ditingkatkan efisiensi dan inovasi."
            },
            a4: {
                year: "2021",
                title: "Peluncuran",
                description: "Setelah tergabung, KERTAS KAYU YATU MINGCAI MILL mulai beroperasi."
            }
        },
        point: {
            heading: "Keunggulan Utama",
            t1: "Perusahaan Teknologi Tinggi Nasional",
            t2: "Layanan Cepat",
            t3: "Daya Tahan",
            t4: "ISO 9001",
            t5: "Menyediakan Bubuk Berkualitas Tinggi",
            t6: "Produk Terdepan",
            t7: "Jaminan Kualitas",
            t8: "Pendekatan Berorientasi Pelanggan"
        }
    },
    career: {
        banner: {
            heading: "Jadilah Bagian dari Keluarga Inovatif Kami!",
            subheading: "Jelajahi peluang karir yang menarik dan lepaskan potensi Anda bersama kami. Bergabunglah dengan tim kami dan bantu membentuk masa depan.",
            button: "Bergabung dengan Tim Kami"
        },
        review: {
           a1: {
                employee: "Mariah Carry",
                review: "Bekerja di Yatu Indonesia terasa seperti menjadi bagian dari keluarga besar. Kami semua saling mendukung dan berusaha memberikan yang terbaik setiap hari. Dedikasi perusahaan terhadap kualitas dan inovasi dalam pelapisan bubuk sungguh menginspirasi. Saya memiliki kesempatan untuk berkembang secara profesional dan menghadapi tantangan baru yang tidak pernah saya bayangkan sebelumnya. Ini adalah perjalanan yang luar biasa sejauh ini.",
                position: "Desain Grafis",
                images: "/images/review/1.jpg"
            },
            a2: {
                employee: "Laudia Cyntia Bella",
                review: "Bekerja di Yatu Indonesia terasa seperti menjadi bagian dari keluarga besar. Kami semua saling mendukung dan berusaha memberikan yang terbaik setiap hari. Dedikasi perusahaan terhadap kualitas dan inovasi dalam pelapisan bubuk sungguh menginspirasi. Saya memiliki kesempatan untuk berkembang secara profesional dan menghadapi tantangan baru yang tidak pernah saya bayangkan sebelumnya. Ini adalah perjalanan yang luar biasa sejauh ini.",
                position: "Digital Marketing",
                images: "/images/review/2.jpg"
            },
            a3: {
                employee: "Feby Putry",
                review: "Bekerja di Yatu Indonesia terasa seperti menjadi bagian dari keluarga besar. Kami semua saling mendukung dan berusaha memberikan yang terbaik setiap hari. Dedikasi perusahaan terhadap kualitas dan inovasi dalam pelapisan bubuk sungguh menginspirasi. Saya memiliki kesempatan untuk berkembang secara profesional dan menghadapi tantangan baru yang tidak pernah saya bayangkan sebelumnya. Ini adalah perjalanan yang luar biasa sejauh ini.",
                position: "UI/UX Designer",
                images: "/images/review/3.jpg"
            },
            heading: "Mari Tumbuh Bersama",
            subheading: "Di Yatu Indonesia, kami adalah pelopor di dunia pelapisan bubuk, berkomitmen untuk memberikan solusi berkualitas tinggi dan berkelanjutan kepada klien kami. Kami percaya bahwa kesuksesan kami didorong oleh dedikasi dan semangat tim kami. Jika Anda mencari perusahaan yang berpikiran maju di mana keterampilan dan ide-ide Anda akan dihargai, kami ingin mendengar dari Anda!"
        },
        gallery: {
            heading: "Lingkungan Kerja Kami",
            subheading: "Di Yatu Indonesia, kami percaya bahwa lingkungan kerja yang positif sangat penting untuk mendorong kreativitas dan inovasi. Kantor modern kami dirancang untuk menginspirasi kolaborasi dan kerja tim, dengan ruang terbuka dan area istirahat di mana karyawan dapat bersantai dan mengisi ulang tenaga. Kami juga menawarkan jam kerja yang fleksibel dan opsi kerja jarak jauh untuk membantu tim kami mencapai keseimbangan kerja-hidup yang sehat.",
            a1: "Semua",
            a2: "Kantor",
            a3: "Produk"
        }
    },    
    contact: {
        heading: "Hubungi Kami",
        office: "Kantor",
        email: "Alamat Email",
        phone: "Nomor Telepon",
        form: {
            name: "Nama",
            phone: "Nomor Telepon",
            email: "Email",
            subject: "Subjek",
            message: "Pesan"
        },
        button: "Kirim Pesan",
        sending: "Mengirim...",
        success: "Pesan Anda telah terkirim.",
    },
    product: {
        titleA1: "Yatu Untuk Otomotif",
        subtitleA1: "Pengecatan Otomotif",
        descriptionA1: "Pilihlah lapisan bubuk Yatu berkualitas tinggi yang sesuai dengan kebutuhan Anda jika Anda ingin lapisan tersebut memiliki fitur-fitur penting berikut: fleksibilitas, warna dan hasil akhir yang luar biasa, ketahanan terhadap goresan dan lecet, tahan terhadap cuaca, dan daya tahan.",
        taglineA1: "Yatu menganggap pelapis bubuk yang digunakan untuk memoles sama pentingnya.",
    
        titleA2: "Yatu Untuk Arsitektur",
        subtitleA2: "Pengecatan Arsitektur",
        descriptionA2: "Kami menawarkan atribut ganda yaitu daya tahan yang luar biasa dan aplikasi yang mudah, memberikan perlindungan yang hemat biaya dan abadi terhadap korosi dan keausan, memastikan masa pakai yang lebih lama dan penampilan yang terjaga dalam kondisi yang menuntut.",
        taglineA2: "Tahan pakai dan anti korosi",
    
        titleA3: "Yatu Untuk Industri",
        subtitleA3: "Pengecatan Industri",
        descriptionA3: "Untuk berbagai aplikasi industri, rangkaian pelapis bubuk Yatu Indonesia memberikan perlindungan dan kinerja yang luar biasa. Pilihan produk kimiawi dan warna kami yang luas memastikan bahwa kami dapat memenuhi kebutuhan unik berbagai jenis logam yang digunakan dalam lingkungan industri. Selain itu, kami juga menyediakan solusi yang tepat untuk setiap aplikasi industri, memastikan hasil yang optimal dan tahan lama.",
        taglineA3: "Pelapis bubuk Yatu dirancang agar tahan pakai dan pekerja keras.",
    
        titleA4: "Yatu Untuk Peralatan",
        subtitleA4: "Pengecatan Peralatan",
        descriptionA4: "Baik untuk keperluan rumah tangga, komersial, atau industri, Yatu Indonesia menawarkan beragam formulasi bubuk yang dirancang khusus untuk berbagai aplikasi.",
        taglineA4: "Yatu Indonesia memberikan nilai kepada orang-orang di seluruh dunia setiap hari.",
    
        titleA5: "Yatu Untuk Perangkat Keras",
        subtitleA5: "Pengecatan Perangkat Keras",
        descriptionA5: "Pilihlah lapisan bubuk Yatu berkualitas tinggi yang sesuai dengan kebutuhan Anda jika Anda ingin lapisan tersebut memiliki fitur-fitur penting berikut: fleksibilitas, warna dan hasil akhir yang luar biasa, ketahanan terhadap goresan dan lecet, tahan terhadap cuaca, dan daya tahan.",
        taglineA5: "Baik untuk keperluan rumah tangga, komersial, atau industri, Yatu Indonesia menawarkan beragam formulasi bubuk yang dirancang khusus untuk berbagai aplikasi.",
    
        titleA6: "Yatu Untuk ACE",
        subtitleA6: "Pengecatan ACE",
        descriptionA6: "Memimpin secara global dalam solusi pelapisan bubuk yang unggul dan inovatif. Lapisan kami hadir dalam berbagai warna dan diuji secara ketat untuk memenuhi standar kinerja tertinggi.",
        taglineA6: "Memanfaatkan pengalaman kami di sektor arsitektur, kami menerapkan pengetahuan kami untuk menciptakan produk khusus perangkat keras yang terdepan di industri.",
    
        titleA7: "Yatu Untuk Ritel",
        subtitleA7: "Pengecatan Ritel",
        descriptionA7: "Memimpin secara global dalam solusi pelapisan bubuk yang unggul dan inovatif. Pelapis ini cocok untuk berbagai permukaan, termasuk rak, aksesori, furnitur, dan bubuk eksternal untuk jendela, pintu, dinding tirai, dan etalase, memastikan perlindungan dan menyempurnakan penampilan.",
        taglineA7: "Yatu Indonesia menawarkan pelapis bubuk yang kuat, tahan lama, dan tahan aus untuk berbagai jenis Pertanian, Konstruksi dan Peralatan (ACE), memastikan perlindungan abadi dan daya tarik estetika.",
    
        titleA8: "Yatu Untuk Tulangan",
        subtitleA8: "Pengecatan Tulangan",
        descriptionA8: "Mereka menanamkan kepercayaan terhadap ketahanan trotoar, struktur laut, dan jembatan. Tulangan berlapis epoksi berikat fusi digunakan dalam berbagai aplikasi konstruksi.",
        taglineA8: "Lapisan serbuk Yatu menawarkan lapisan pelindung yang kuat, tangguh, dan abadi karena sifat termosetnya.",
    
        titleA9: "Yatu Untuk Di Luar Logam",
        subtitleA9: "Pengecatan Di Luar Logam",
        descriptionA9: "Memimpin secara global dalam solusi pelapisan bubuk yang unggul dan inovatif. Lapisan kami hadir dalam berbagai warna dan diuji secara ketat untuk memenuhi standar kinerja tertinggi.",
        taglineA9: "Batang tulangan (rebar) baja berlapis epoksi memberikan keuntungan signifikan untuk beton yang terkena kondisi korosif seperti garam deicing atau lingkungan laut.",
    
        titleA10: "Yatu Untuk Penerangan",
        subtitleA10: "Pengecatan Penerangan",
        descriptionA10: "Tersedia dalam berbagai warna dan efek penuh gaya dari Yatu untuk lampu, menyempurnakan tampilan dan fungsinya dalam berbagai gaya desain, dari klasik hingga modern. Pilihan warna dan efek bubuk trendi Yatu untuk perlengkapan lampu tidak hanya memastikan fungsionalitas optimal tetapi juga meningkatkan daya tarik visualnya, meningkatkan estetika dan suasana dalam beragam gaya seperti tradisional, modern, industri, kontemporer, dan pedesaan, memberikan keserbagunaan dan kecanggihan.",
        taglineA10: "Yatu Indonesia menawarkan pelapis bubuk yang kuat, tahan lama, dan tahan aus untuk berbagai jenis Pertanian, Konstruksi dan Peralatan (ACE), memastikan perlindungan abadi dan daya tarik estetika.",
    
        titleA11: "Yatu Untuk Aplikator",
        subtitleA11: "Pengecatan Aplikator",
        descriptionA11: "Berbagai pilihan kami, ditambah dengan layanan pencocokan warna khusus untuk aplikator. Yatu Powder Coatings menawarkan beragam pilihan, termasuk pencocokan warna khusus untuk aplikator.",
        taglineA11: "Yatu menawarkan beragam warna bubuk dan efek modis untuk perlengkapan lampu, memastikannya berfungsi secara efektif dan terlihat fantastis, meningkatkan penampilan dan nuansa dalam berbagai gaya seperti lampu tradisional, modern, industri, kontemporer, dan lampu dinding pedesaan.",
    
        titleA12: "Yatu Untuk Furniture",
        subtitleA12: "Pengecatan Furniture",
        descriptionA12: "Yatu Indonesia memberikan nilai kepada orang-orang di seluruh dunia setiap hari. Dengan menciptakan nada dan suasana yang hidup untuk semua jenis furnitur. Produk dan material inovatif kami dirancang untuk melindungi dan mempercantik dunia Anda hari ini, esok, dan seterusnya.",
        taglineA12: "Yatu Powder Coatings menawarkan beragam pilihan powder coating, termasuk ribuan warna, efek, dan pencocokan warna khusus terutama untuk aplikator.",

        keuntungan: "Keuntungan",
        button: "Pelajari Lebih Lanjut",
        variant: "Pelapis Warna Variasi"
    }
}

export default content;