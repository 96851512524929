import { Fragment } from 'react'
import Breadcrumb from '../../components/Breadcrumb'
import ListProduct from '../../components/ListProduct'

const Product = () => {
    return (
        <Fragment>
            <Breadcrumb />
            <ListProduct />
        </Fragment>
    )
}

export default Product;