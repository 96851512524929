// ./src/components/Language.tsx
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

const locales = ['en', 'id', 'zh', 'ru'] as const;
type Locale = typeof locales[number];

// Utility function to get the locale from pathname
const getLocaleFromPathname = (pathname: string): Locale => {
    const path = pathname.split('/')[1];
    return locales.includes(path as Locale) ? (path as Locale) : 'id'; // Default to 'id' if not found
};

const Language: React.FC = () => {
    const { i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const locale = getLocaleFromPathname(location.pathname); // Extract locale from the path

    const handleToggle = () => setIsOpen(!isOpen);

    const changeLanguage = (lang: Locale) => {
        let newPath = `/${lang}${location.pathname.replace(`/${locale}`, '')}`;

        i18n.changeLanguage(lang);
        navigate(newPath);
        handleToggle();
    };

    return (
        <div className='relative'>
            <button
                className="flex items-center rounded bg-transparent text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                type="button"
                id="dropdownMenuButton1"
                data-twe-dropdown-toggle-ref
                aria-expanded="false"
                data-twe-ripple-init
                data-twe-ripple-color="light"
                onClick={handleToggle}
            >
                <img
                    src={`/images/flags/${localStorage.i18nextLng}.svg`}
                    alt={`${locale}`}
                    width={24}
                    height={24}
                    className="rounded-full"
                />
            </button>
            <ul
                className={`${isOpen ? 'block' : 'hidden'} absolute z-[1000] right-0 m-0 min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-base shadow-lg data-[twe-dropdown-show]:block dark:bg-surface-dark`}
                aria-labelledby="dropdownMenuButton1"
                data-twe-dropdown-menu-ref>
                    {
                        locales.map((lang) => (
                            <li key={lang} className="hover:bg-black/10">
                                <button
                                    className="w-full h-full p-4 whitespace-nowrap flex items-center gap-4 text-sm font-normal text-black focus:outline-none"
                                    onClick={() => changeLanguage(lang)}
                                >
                                    <img src={`/images/flags/${lang}.svg`} fetchPriority="high" alt={`${lang}`} width={30} height={30} className="" />
                                    <span className="text-sm font-semibold">
                                        {lang === 'id' ? 'Indonesia' : lang === 'en' ? 'English' : lang === 'zh' ? 'China' : lang === 'ru' ? 'Rusia' : ''}
                                    </span>
                                </button>
                            </li>
                        ))
                    }
            </ul>
        </div>
    );
};

export default Language;
