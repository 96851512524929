import { FC, useState } from "react";
import { useTranslation } from 'react-i18next';

interface ItemsProps {
    imageSrc: string;
    altText: string;
    title: string;
    description: string;
    category: string;
}

interface ImageCardProps {
    imageSrc: string;
    altText: string;
    title: string;
    description: string;
  }
  

const items: ItemsProps [] = [
    {
        imageSrc: '/images/automotive01.webp',
        altText: 'Automotive',
        title: 'Yatu For Auto Motive',
        description: 'Automotive Painting',
        category: 'automotive',
    },
    {
        imageSrc: '/images/handgun.png',
        altText: 'Industrial',
        title: 'Yatu For Industrial',
        description: 'Architectural Painting',
        category: 'industrial',
    },
    {
        imageSrc: '/images/handgun.png',
        altText: 'Furniture',
        title: 'Yatu For Furniture',
        description: 'Architectural Painting',
        category: 'furniture',
    },
    {
        imageSrc: '/images/automotive01.webp',
        altText: 'Architectural',
        title: 'Yatu For Architectural',
        description: 'Architectural Painting',
        category: 'architectural',
    },
    {
        imageSrc: '/images/automotive01.webp',
        altText: 'Automotive',
        title: 'Yatu For Auto Motive',
        description: 'Automotive Painting',
        category: 'automotive',
    },
    {
        imageSrc: '/images/handgun.png',
        altText: 'Industrial',
        title: 'Yatu For Industrial',
        description: 'Architectural Painting',
        category: 'industrial',
    },
    {
        imageSrc: '/images/handgun.png',
        altText: 'Furniture',
        title: 'Yatu For Furniture',
        description: 'Architectural Painting',
        category: 'furniture',
    },
    {
        imageSrc: '/images/automotive01.webp',
        altText: 'Architectural',
        title: 'Yatu For Architectural',
        description: 'Architectural Painting',
        category: 'architectural',
    },
    {
        imageSrc: '/images/automotive01.webp',
        altText: 'Automotive',
        title: 'Yatu For Auto Motive',
        description: 'Automotive Painting',
        category: 'automotive',
    },
    {
        imageSrc: '/images/handgun.png',
        altText: 'Industrial',
        title: 'Yatu For Industrial',
        description: 'Architectural Painting',
        category: 'industrial',
    },
    {
        imageSrc: '/images/handgun.png',
        altText: 'Furniture',
        title: 'Yatu For Furniture',
        description: 'Architectural Painting',
        category: 'furniture',
    },
    {
        imageSrc: '/images/automotive01.webp',
        altText: 'Architectural',
        title: 'Yatu For Architectural',
        description: 'Architectural Painting',
        category: 'architectural',
    },
    {
        imageSrc: '/images/automotive01.webp',
        altText: 'Automotive',
        title: 'Yatu For Auto Motive',
        description: 'Automotive Painting',
        category: 'automotive',
    },
    {
        imageSrc: '/images/handgun.png',
        altText: 'Industrial',
        title: 'Yatu For Industrial',
        description: 'Architectural Painting',
        category: 'industrial',
    },
    {
        imageSrc: '/images/handgun.png',
        altText: 'Furniture',
        title: 'Yatu For Furniture',
        description: 'Architectural Painting',
        category: 'furniture',
    },
    {
        imageSrc: '/images/automotive01.webp',
        altText: 'Architectural',
        title: 'Yatu For Architectural',
        description: 'Architectural Painting',
        category: 'architectural',
    }
];

const ImageCard: FC<ImageCardProps> = ({ imageSrc, altText, title, description }) =>{
    return (
        <div className="relative w-full h-[167px] lg:h-[412px] bg-white rounded-[10px] lg:rounded-3xl col-span-1 lg:col-span-1 lg:first:col-span-2 lg:last:col-span-2">
            <div className="absolute w-full h-[167px] lg:h-[291px] bottom-0 bg-gradient-to-b from-[#0E102D]/0 to-[#0D1730] rounded-[10px] lg:rounded-b-3xl"></div>
            <img src={imageSrc} width={796} height={412} className="w-full h-[167px] lg:h-full object-cover object-center rounded-[10px] lg:rounded-b-3xl" alt={altText} />
            <div className="absolute bottom-4 left-4 lg:bottom-10 lg:left-10 w-full h-auto flex flex-col">
                <p className="text-lg lg:text-2xl text-white font-poppins font-bold">{title}</p>
                <span className="text-base lg:text-lg text-white font-sarala font-normal">{description}</span>
            </div>
        </div>
    );
}

const Product = () => {
    const { i18n, t } = useTranslation('');
    const [selectedCategory, setSelectedCategory] = useState('all');
    const filteredItems = selectedCategory === 'all' ? items : items.filter(item => item.category === selectedCategory)
    const categories = ['all', 'automotive', 'industrial', 'furniture', 'architectural'];
    const currentLang = i18n.language || 'id';
    return (
        <section className="relative w-full h-full py-20 bg-white dark:bg-black overflow-hidden">
            <div className="container">
                <div className="w-full flex flex-col xl:flex-row items-center justify-center" data-aos="fade-up">
                    <div className="w-full xl:w-6/12 flex flex-col items-start justify-between text-left">
                        <p className="relative text-lg text-blue font-poppins font-semibold mb-4 before:absolute before:content-[''] before:bg-blue before:w-[24px] before:h-0.5 pl-[30px] before:translate-x-0 before:top-[50%] before:left-0  before:rounded-l-lg before:rounded-r-lg">{t('home.product.heading')}</p>
                        <h2 className="text-xl text-[#12174D] dark:text-white font-poppins font-bold">{t('home.product.subheading')}</h2>
                    </div>
                    <div className="w-full xl:w-6/12 mt-6 xl:mt-0">
                        <p className="text-base text-gray dark:text-white font-sarala font-normal text-left xl:text-right">{t('home.product.p')}</p>
                    </div>
                </div>
                <div className="flex flex-1 justify-between items-center mt-16 mb-8" data-aos="fade-up">
                    <div className="overflow-x-auto md:overflow-x-visible">
                        <div className="flex md:flex-wrap">
                            {categories.map((category) => (
                                <button
                                    key={category}
                                    onClick={() => setSelectedCategory(category)}
                                    className={`category-button px-4 py-2 rounded-[50px] mr-2 ${selectedCategory === category ? 'bg-white border-[.5px] border-gray text-black' : 'text-black dark:text-white'}`}
                                >
                                    {category.charAt(0).toUpperCase() + category.slice(1)}
                                </button>
                            ))}
                        </div>
                    </div>
                    <a href={`/${currentLang}/product`} className="hidden xl:inline-block bg-green-500 text-white px-6 py-3 rounded-md hover:bg-green-600">See All</a>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6" data-aos="fade-down">
                    {filteredItems.slice(0, 4).map((item, index) => (
                        <ImageCard
                            key={index}
                            imageSrc={item.imageSrc}
                            altText={item.altText}
                            title={item.title}
                            description={item.description}
                        />
                    ))}
                </div>
                <a href={`/${currentLang}/product`} className="w-full mt-4 block xl:hidden bg-green-500 text-white px-6 py-3 rounded-md hover:bg-green-600">See All</a>
            </div>
        </section>
    )
}

export default Product;