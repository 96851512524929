const BannerCareer = () => {
    return (
        <section className="relative w-full h-auto py-10 xl:py-20 overflow-hidden">
            <div className="relative w-full h-full container">
                <div className="relative w-full md:py-10 xl:pt-64 xl:pb-20 flex flex-col items-center justify-center bg-[#069EDB] gap-y-10 rounded-[25px] z-10">
                    <div className="relative w-full md:w-10/12 lg:w-8/12 flex flex-col items-center justify-center text-center gap-y-2">
                        <h2 className="md:text-2xl lg:text-3xl xl:text-5xl text-white font-poppins font-bold">Be a Part of Our Innovative Family!</h2>
                        <p className="md:text-base text-white font-poppins font-normal"> Explore exciting career opportunities and unleash your potential with us. Join our team and help shape the future.</p>
                    </div>
                    <a href="#" className="md:text-lg text-white font-sarala font-bold px-9 py-5 bg-[#39B54A] rounded-[15px]">Join Our Team</a>
                    <div className="absolute hidden xl:block inset-0 w-full h-full overflow-hidden z-20">
                        <div className="absolute top-0 left-[210px] w-[127px] h-[134px] bg-black rounded-[15px] bg-[url('/images/banner/career/1.jpg')] bg-center bg-cover bg-no-repeat"></div>
                        <div className="absolute top-[121px] left-0 w-[179px] h-[181px] bg-black rounded-r-[15px] bg-[url('/images/banner/career/2.jpg')] bg-center bg-cover bg-no-repeat"></div>
                        <div className="absolute bottom-[80px] left-[110px] w-[100px] h-[102px] bg-black rounded-[15px] bg-[url('/images/banner/career/3.jpg')] bg-center bg-cover bg-no-repeat z-50"></div>
                        <div className="absolute bottom-0 left-[156px] w-[170px] h-[110px] bg-black rounded-[15px] bg-[url('/images/banner/career/4.jpg')] bg-center bg-cover bg-no-repeat z-40"></div>
                        <div className="absolute top-0 right-[210px] w-[127px] h-[134px] bg-black rounded-[15px] bg-[url('/images/banner/career/1.jpg')] bg-center bg-cover bg-no-repeat"></div>
                        <div className="absolute top-[121px] right-0 w-[179px] h-[181px] bg-black rounded-l-[15px] bg-[url('/images/banner/career/2.jpg')] bg-center bg-cover bg-no-repeat"></div>
                        <div className="absolute bottom-[80px] right-[110px] w-[100px] h-[102px] bg-black rounded-[15px] bg-[url('/images/banner/career/3.jpg')] bg-center bg-cover bg-no-repeat z-50"></div>
                        <div className="absolute bottom-0 right-[156px] w-[170px] h-[110px] bg-black rounded-[15px] bg-[url('/images/banner/career/4.jpg')] bg-center bg-cover bg-no-repeat z-40"></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BannerCareer;