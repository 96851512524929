/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useRef, useState } from "react";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from 'react-i18next';

// interface ReviewEmployeeProps {
//   employee: string;
//   review: string;
//   position: string;
//   images: string;
// }

const ReviewEmployee = () => {
  const { t } = useTranslation();
  // const [selectedButtonIndex, setSelectedButtonIndex] = useState<number | null>(
  //   null
  // ); // Store the manually selected button index
  // const carouselRef = useRef<any>(null);

  // const responsive = {
  //   superLargeDesktop: {
  //     breakpoint: { max: 4000, min: 1024 },
  //     items: 1,
  //   },
  //   desktop: {
  //     breakpoint: { max: 1024, min: 820 },
  //     items: 1,
  //   },
  //   tablet: {
  //     breakpoint: { max: 820, min: 464 },
  //     items: 1,
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //   },
  // };

  // const items: ReviewEmployeeProps[] = [
  //   {
  //     employee: t('career.review.a1.employee'),
  //     review: t('career.review.a1.review'),
  //     position: t('career.review.a1.position'),
  //     images: t('career.review.a1.images'),
  //   },
  //   {
  //     employee: t('career.review.a2.employee'),
  //     review: t('career.review.a2.review'),
  //     position: t('career.review.a2.position'),
  //     images: t('career.review.a2.images'),
  //   },
  //   {
  //     employee: t('career.review.a3.employee'),
  //     review: t('career.review.a3.review'),
  //     position: t('career.review.a3.position'),
  //     images: t('career.review.a3.images'),
  //   },
  // ];

  // const handleSlideChange = (index: number) => {
  //   setSelectedButtonIndex(index); // Update manually selected button index
  //   if (carouselRef.current) {
  //     carouselRef.current.goToSlide(index); // Go to the clicked slide
  //   }
  // };

  // const CardReview = ({
  //   employee,
  //   review,
  //   position,
  //   images,
  // }: ReviewEmployeeProps) => {
  //   return (
  //     <div className="relative w-full flex items-center justify-center">
  //       <div className="grid md:grid-cols-2 gap-x-24 py-10 md:py-0 gap-y-10 md:gap-y-0">
  //         <div
  //           className="relative w-full flex flex-col md:flex-row items-center justify-center"
  //           data-aos="fade-right"
  //         >
  //           <div className="flex items-center">
  //             <img
  //               src={images}
  //               className="w-full h-full xl:h-[486px] md:m-10 object-cover object-center rounded-[25px] shadow-[-10px_-10px_15px_rgba(255,255,255,0.5),10px_10px_15px_rgba(0,0,0,0.20)]"
  //               alt="Employee Review"
  //             />
  //           </div>
  //         </div>
  //         <div
  //           className="relative w-full flex flex-col items-center md:items-start justify-center"
  //           data-aos="fade-left"
  //         >
  //           <img
  //             src="/images/icon/qoute.svg"
  //             className="w-4 h-4 md:w-10 md:h-10 mb-4 md:mb-4 lg:mb-8"
  //             alt=""
  //           />
  //           <p className="text-center md:text-left text-base md:text-base lg:text-lg text-gray dark:text-white font-poppins font-normal break-all">
  //             {review}
  //           </p>
  //           <p className="md:text-sm lg:text-base text-black dark:text-white font-poppins font-semibold mt-6">
  //             {employee}
  //           </p>
  //           <span className="md:text-xs lg:text-sm text-gray dark:text-white font-poppins font-normal mt-2">
  //             {position}
  //           </span>

  //           <div className="absolute -bottom-6 md:bottom-10 lg:-bottom-4 flex items-center justify-start mt-20 md:mt-10 gap-x-3">
  //             {items.map((_, index) => (
  //               <button
  //                 key={index}
  //                 onClick={() => handleSlideChange(index)}
  //                 className={`relative w-[32px] h-[4px] rounded-sm cursor-pointer transition-all ${
  //                   selectedButtonIndex === index
  //                     ? "bg-[#079EDB]"
  //                     : "bg-[#C0C0C0]"
  //                 }`}
  //               />
  //             ))}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <>
      {/* <section className="relative w-full h-full py-10 md:py-10 xl:py-20 bg-white dark:bg-black overflow-hidden">
        <div className="container">
          <Carousel
            ref={carouselRef}
            responsive={responsive}
            ssr={true}
            arrows={false}
            infinite={false}
            autoPlay={false}
            containerClass="carousel-container"
            autoPlaySpeed={6000}
            focusOnSelect={true}
            removeArrowOnDeviceType={["tablet", "mobile"]}
          >
            {items.map((item, index) => (
              <CardReview key={index} {...item} />
            ))}
          </Carousel>
        </div>
      </section> */}
      <section className="relative w-full h-full py-10 md:py-10 xl:py-20 bg-white dark:bg-black overflow-hidden">
        <div className="container">
          <div className="relative w-full flex items-center justify-center">
            <div className="grid md:grid-cols-2 gap-x-16 gap-y-10 md:gap-y-0">
              <div
                className="relative w-full flex items-center justify-center md:order-2"
                data-aos="fade-right"
              >
                <div className="flex items-center">
                  <img
                    src="/images/review/4.jpg"
                    className="w-full xl:h-[486px] object-cover object-center rounded-[25px] shadow-[-10px_-10px_15px_rgba(255,255,255,0.5),10px_10px_15px_rgba(0,0,0,0.20)]"
                    alt="Employee Review"
                  />
                </div>
              </div>
              <div
                className="relative w-full flex flex-col items-center md:items-end justify-center md:order-1 gap-y-7"
                data-aos="fade-left"
              >
                <h2 className="text-[#0E1318] text-center md:text-right text-xl md:text-4xl font-poppins font-semibold">{t('career.review.heading')}</h2>
                <p className="text-gray text-center md:text-right text-lg font-sarala font-normal">{t('career.review.subheading')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReviewEmployee;
