import { FC, useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from 'react-i18next'

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024)
        };

        handleResize();

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return isMobile

}

const History: FC = () => {
    const { t } = useTranslation();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const isMobile = useIsMobile()
    const years = [
        { 
            "year": t('about.history.a1.year'),
            "image1": "/images/about/2024-1.jpg",
            "image2": "/images/about/2024-2.jpg"
        },
        { 
            "year": t('about.history.a2.year'),
            "image1": "/images/about/2023-1.jpg",
            "image2": "/images/about/2023-2.jpg"
        },
        { 
            "year": t('about.history.a3.year'),
            "image1": "/images/about/2022-1.jpg",
            "image2": "/images/about/2022-2.jpg"
        },
        { 
            "year": t('about.history.a4.year'),
            "image1": "/images/about/2021-1.jpg",
            "image2": "/images/about/2021-2.jpg"
        }
    ]

    useEffect(() => {
        setSelectedIndex(0)
    }, [isMobile])

    const handleSildeChange = (index: number) => {
        setSelectedIndex(index)
    }

    return (
        <section className="relative w-full py-20 bg-white dark:bg-black overflow-hidden">
            <div className="container">
                <div className="w-full flex flex-col xl:flex-row items-center justify-between pb-10" data-aos="fade-down">
                    <div className="w-full xl:w-6/12 flex flex-col items-start justify-between text-left">
                        <p className="relative text-lg text-blue font-poppins font-semibold mb-4 before:absolute before:content-[''] before:bg-blue before:w-[24px] before:h-0.5 pl-[30px] before:translate-x-0 before:top-[50%] before:left-0  before:rounded-l-lg before:rounded-r-lg">{t('about.history.heading')}</p>
                        <h2 className="text-xl text-[#12174D] dark:text-white font-poppins font-bold">{t('about.history.subheading')}</h2>
                    </div>
                    <div className="w-full xl:w-4/12 mt-6 xl:mt-0">
                        <p className="text-base text-gray dark:text-white font-sarala font-normal text-left xl:text-right leading-8">{t('about.history.p')}</p>
                    </div>
                </div>
            </div>
            <div className="hidden xl:block relative z-50">
                <div className="absolute w-full h-fit left-0 top-0 flex flex-col items-start pt-20">
                    {years.map((item, index) => (
                        <button onClick={() => handleSildeChange(index)} key={index} className="mb-[120px] last:mb-0">
                            <p className={`relative font-poppins ${index === selectedIndex ? 'text-[28px] text-blue font-bold before:absolute before:content-[""] before:bg-[#1782BD] before:w-[22px] before:h-[5px] pl-[30px] before:translate-x-0 before:top-[50%] before:left-0 before:rounded-r-lg' : 'text-[22px] text-[#D9D9D9] dark:text-white font-semibold'} `}>{item.year}</p>
                        </button>
                    ))}
                </div>
            </div>
            <div className="relative w-full pt-20 pb-20 xl:pt-20 xl:pb-20 bg-[#FAFAFF] dark:bg-[#212121]">
                <div className="relative container">
                    <Carousel
                        autoPlay={true}
                        showArrows={false}
                        showIndicators={false}
                        showStatus={false}
                        showThumbs={false}
                        infiniteLoop={true}
                        stopOnHover={false}
                        axis={isMobile ? 'horizontal' : 'vertical'}
                        selectedItem={selectedIndex}
                        onChange={handleSildeChange}
                    >
                    {years.map((item, index) => (
                        <div key={index} className="grid grid-cols-1 md:grid-cols-12 gap-10">
                            <div className="md:col-span-6 text-left mt-0 md:mt-20">
                                <h3 className="text-[#12174D] dark:text-white text-3xl font-poppins font-bold mb-10">{t(`about.history.a${index+1}.title`)}</h3>
                                <p className="text-gray dark:text-white text-base font-sarala font-normal leading-8">{t(`about.history.a${index+1}.description`)}</p>
                            </div>
                            <div className="md:col-span-6">
                                <div className="relative">
                                    <figure className="relative pb-[115px] overflow-hidden mb-0">
                                        <img src={item.image2} className="!w-[228px] h-[290px] xl:!w-[334px] xl:h-[425px] !flex !ml-auto mr-10 xl:mr-0 rounded-[25px]" />
                                    </figure>
                                    <figure className="absolute md:absolute xl:absolute left-0 bottom-10 md:-left-10 lg:left-20 xl:left-40 xl:bottom-0 overflow-hidden mb-0 rounded-[25px] !border-4 !border-white">
                                        <img src={item.image1} className="w-[193px] h-[172px] xl:w-![283px] xl:h-[252px] ml-auto rounded-[25px] !border  !border-white" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    ))}
                    </Carousel>
                    {/* <div className="block xl:hidden w-full h-full relative z-50"> */}
                        <div className="xl:hidden absolute w-full h-fit px-4 left-0 -bottom-16 flex flex-row items-start justify-between transition-all">
                            {years.map((item, index) => (
                                <button onClick={() => handleSildeChange(index)} key={index} className="">
                                    <p className={`relative font-poppins ${index === selectedIndex ? 'text-base text-blue font-bold before:absolute before:content-[""] before:bg-[#1782BD] before:w-[5px] before:h-[22px] before:pb-8 before:translate-x-0 before:-bottom-8 before:left-[50%] before:right-0 before:rounded-t-lg' : 'mt-4 text-base text-[#D9D9D9] dark:text-white font-semibold'} `}>{item.year}</p>
                                </button>
                            ))}
                        </div>
                    {/* </div> */}
                </div>
            </div>
        </section>
    );
};

export default History;