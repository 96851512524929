import { Fragment } from "react"
import { useParams } from "react-router-dom"
import Breadcrumb from "../../components/Breadcrumb"
import { product } from "../../json/product"
import { useTranslation } from 'react-i18next'

const DetailProduct = () => {
    const { t } = useTranslation();
    const { slug } = useParams<{ slug: string }>();
    // Find the product that matches the slug
    const productDetail = product.find(
        (item) => item.slug === slug
    );

    if (!productDetail) {
        return <div>Product not found</div>; // Handle case where product is not found
    }
    return (
        <Fragment>
            <Breadcrumb />
            <section className="relative w-full h-auto py-20 overflow-hidden">
                <div className="container">
                    <div className='relative w-full flex flex-col gap-y-12'>
                        <div className="relative w-full flex flex-col md:flex-row items-center gap-10">
                            <div className="relative w-full h-[250px] md:h-[561px] shadow-[-10px_-10px_15px_rgba(0,0,0,0.20),0px_0px_35px_rgba(0,0,0,0.20)] rounded-3xl">
                                <img src={productDetail.images[0].src} className="w-full h-[250px] md:h-[561px] object-cover object-center rounded-3xl" />
                                <div className="absolute p-4 md:p-10 top-0 w-full h-full flex flex-col items-start justify-end gap-y-1 bg-gradient-to-b from-[#0E102D]/0 from-25% to-[#0D1730] to-100# rounded-3xl">
                                    <p className="text-white text-base md:text-4xl font-poppins font-bold">{t(`product.titleA${productDetail.id}`)}</p>
                                    <span className="text-white text-sm md:text-base font-sarala font-normal">{t(`product.subtitleA${productDetail.id}`)}</span>
                                </div>
                            </div>
                            <div className="hidden md:flex flex-row md:flex-col gap-6">
                                <div className="relative md:w-[259px] h-[200px] md:h-[268px] shadow-[-10px_-10px_15px_rgba(0,0,0,0.20),0px_0px_35px_rgba(0,0,0,0.20)] rounded-3xl">
                                    <img src={productDetail.images[1].src} className="md:w-[259px] h-[200px] md:h-[268px] object-cover object-center rounded-3xl" />
                                </div>
                                <div className="relative md:w-[259px] h-[200px] md:h-[268px] shadow-[-10px_-10px_15px_rgba(0,0,0,0.20),0px_0px_35px_rgba(0,0,0,0.20)] rounded-3xl">
                                    <img src={productDetail.images[2].src} className="md:w-[259px] h-[200px] md:h-[268px] object-cover object-center rounded-3xl" />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-y-5'>
                            <p className="text-[#12174D] dark:text-white text-3xl font-sarala font-bold">{t(`product.keuntungan`)}</p>
                            <p className="text-lg text-gray dark:text-white font-normal font-sarala leading-10">{t(`product.descriptionA${productDetail.id}`)}</p>
                        </div>
                    </div>
                    {productDetail.variants && productDetail.variants.length > 0 ? (
                        <div className="relative w-full pt-10">
                            <div className="flex items-start flex-col md:flex-col lg:flex-row justify-between gap-2">
                                <p className="text-[#12174D] dark:text-white text-3xl font-sarala font-bold">{t(`product.variant`)}</p>
                                <span className="w-full md:w-full xl:w-4/12 text-left md:text-left xl:text-right text-base text-gray dark:text-white/90 font-sarala font-normal">{t(`product.taglineA${productDetail.id}`)}</span>
                            </div>
                            <div className="w-full mt-10 grid grid-cols-2 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 gap-10">
                                {productDetail.variants?.map((item, index) => {
                                    return(
                                        <div key={index} className="relative w-full h-auto p-4 rounded-[15px] shadow-[0px_0px_35px_rgba(108,146,181,10%)]">
                                            <div className="w-full h-auto flex items-center justify-center">
                                                <img src={item.img} width={260} height={160} className="w-full h-[100px] md:h-[160px] rounded-[10px]" alt={item.name} title={item.name} />
                                            </div>
                                            <div className="flex items-center justify-center pt-4">
                                                <span className="text-black text-base font-poppins font-medium capitalize">{item.name}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    ) : (
                        <div className="mt-10">
                            <p className="text-center text-lg text-gray dark:text-white font-normal font-sarala">No variants available for this product.</p>
                        </div>
                    )}
                </div>
            </section>
        </Fragment>
    )
}

export default DetailProduct