interface VariantProps {
    name: string;
    img: string;
}

interface ImageProps {
    src: string;
}

interface ProductProps {
    id: number;
    title: string;
    slug: string;
    subtitle: string;
    description: string;
    images: ImageProps[];
    variants: VariantProps[];
}

export const product: ProductProps[] = [
    {
        id: 1,
        title: "Yatu For Automotive",
        slug: "yatu-for-automotive",
        subtitle: "Automotive Painting",
        description: "Pilihlah lapisan bubuk Yatu berkualitas tinggi yang sesuai dengan kebutuhan Anda jika Anda ingin lapisan tersebut memiliki fitur-fitur penting berikut: fleksibilitas, warna dan hasil akhir yang luar biasa, ketahanan terhadap goresan dan lecet, tahan terhadap cuaca, dan daya tahan.",
        images: [
            {
                src: "/images/product/Automotive/automative.jpg",
            },
            {
                src: "/images/product/Automotive/Image by Patrick Langwallner.jpg",
            },
            {
                src: "/images/product/Automotive/301195772_438497204968835_9054809161967963999_n.jpg",
            },
        ],
        variants: [
            {
                name : 'BLM  Carob Brown',
                img:'/images/categories/automotive/BLM  Carob Brown.jpg'
            },
            {
                name : 'Canyon Brown',
                img:'/images/categories/automotive/Canyon Brown.jpg'
            },
            {
                name : 'Glossy  Azzuro',
                img:'/images/categories/automotive/Glossy  Azzuro.jpg'
            },
            {
                name : 'Heartford Green',
                img:'/images/categories/automotive/Heartford Green.jpg'
            },
            {
                name : 'Matte  Aged Coper',
                img:'/images/categories/automotive/Matte  Aged Coper.jpg'
            },
            {
                name : 'Matte  Brick Red',
                img:'/images/categories/automotive/Matte  Brick Red.jpg'
            },
            {
                name : 'Matte Boysenberry',
                img:'/images/categories/automotive/Matte Boysenberry.jpg'
            },
            {
                name : 'Railing Black',
                img:'/images/categories/automotive/Railing Black.jpg'
            },
            {
                name : 'Satin  Classic Green',
                img:'/images/categories/automotive/Satin  Classic Green.jpg'
            },
            {
                name : 'Satin  Ivy Green',
                img:'/images/categories/automotive/Satin  Ivy Green.jpg'
            },
            {
                name : 'Satin  Jet Black',
                img:'/images/categories/automotive/Satin  Jet Black.jpg'
            },
            {
                name : 'Satin  Redwood',
                img:'/images/categories/automotive/Satin  Redwood.jpg'
            },
            {
                name : 'Shadow Grey',
                img:'/images/categories/automotive/Shadow Grey.jpg'
            },
            {
                name : 'Sudan Browen',
                img:'/images/categories/automotive/Sudan Browen.jpg'
            },
            {
                name : 'Yuma green',
                img:'/images/categories/automotive/Yuma green.jpg'
            }
        ]
    },
    {
        id: 2,
        title: "Yatu For Architecture",
        slug: "yatu-for-architecture",
        subtitle: "Arsitektur Painting",
        description: "Kami menawarkan atribut ganda yaitu daya tahan yang luar biasa dan aplikasi yang mudah, memberikan perlindungan yang hemat biaya dan abadi terhadap korosi dan keausan, memastikan masa pakai yang lebih lama dan penampilan yang terjaga dalam kondisi yang menuntut.",
        images: [
            {
                src: "/images/product/Arsitektur/image 24_2_11zon.jpg",
            },
            {
                src: "/images/product/Arsitektur/image 24 (1)_1_11zon.jpg",
            },
            {
                src: "/images/product/Arsitektur/jason-briscoe-UV81E0oXXWQ-unsplash_3_11zon.jpg",
            },
        ],
        variants: [
            {
                name: 'Alamo',
                img:'/images/categories/architectural/Alamo.jpg'
            },
            {
                name: 'Alby',
                img:'/images/categories/architectural/Alby.jpg'
            },
            {
                name: 'Ambre',
                img:'/images/categories/architectural/Ambre.jpg'
            },
            {
                name: 'Arches',
                img:'/images/categories/architectural/Arches.jpg'
            },
            {
                name: 'Babua',
                img:'/images/categories/architectural/Babua.jpg'
            },
            {
                name: 'Baku',
                img:'/images/categories/architectural/Baku.jpg'
            },
            {
                name: 'Baku Sable',
                img:'/images/categories/architectural/Baku_sable.jpg'
            },
            {
                name: 'Bleu Sable',
                img:'/images/categories/architectural/Bleu_Sable.jpg'
            },
            {
                name: 'Aquamarine',
                img:'/images/categories/architectural/Aquamarine.jpg'
            },
            {
                name: 'Bodrum',
                img:'/images/categories/architectural/Bodrum.jpg'
            },
            {
                name: 'Brilliant Silver',
                img:'/images/categories/architectural/Brilliant_Silver.jpg'
            },
            {
                name: 'Brun Sable',
                img:'/images/categories/architectural/Brun_Sable.jpg'
            },
            {
                name: 'Canon',
                img:'/images/categories/architectural/Canon.jpg'
            },
            {
                name: 'Changi',
                img:'/images/categories/architectural/Changi.jpg'
            },
            {
                name: 'Chicago',
                img:'/images/categories/architectural/Chicago.jpg'
            },
            {
                name: 'Cusco',
                img:'/images/categories/architectural/Cusco.jpg'
            },
            {
                name: 'Detendre',
                img:'/images/categories/architectural/Detendre.jpg'
            },
            {
                name: 'Electrum',
                img:'/images/categories/architectural/Electrum.jpg'
            },
            {
                name: 'Grazalema',
                img:'/images/categories/architectural/Grazalema.jpg'
            },
            {
                name: 'Gris',
                img:'/images/categories/architectural/Gris.jpg'
            },
            {
                name: 'Gris 2805',
                img:'/images/categories/architectural/Gris_2805.jpg'
            },
            {
                name: 'Gris Sable',
                img:'/images/categories/architectural/Gris_Sable.jpg'
            },
            {
                name: 'Griss 2400',
                img:'/images/categories/architectural/Griss_2400.jpg'
            },
            {
                name: 'Innoko',
                img:'/images/categories/architectural/Innoko.jpg'
            },
            {
                name: 'Lichen',
                img:'/images/categories/architectural/Lichen.jpg'
            },
            {
                name: 'Luxor',
                img:'/images/categories/architectural/Luxor.jpg'
            },
            {
                name: 'Manganese',
                img:'/images/categories/architectural/Manganese.jpg'
            },
            {
                name: 'Noir',
                img:'/images/categories/architectural/Noir.jpg'
            },
            {
                name: 'Noir 2330 Sable',
                img:'/images/categories/architectural/Noir_2330 _Sable.jpg'
            },
            {
                name: 'Noir Sable',
                img:'/images/categories/architectural/Noir_Sable.jpg'
            },
            {
                name: 'Patah',
                img:'/images/categories/architectural/Patah.jpg'
            },
            {
                name: 'Pearl Night Blue',
                img:'/images/categories/architectural/Pearl_Night_Blue.jpg'
            },
            {
                name: 'Pyrite',
                img:'/images/categories/architectural/Pyrite.jpg'
            },
            {
                name: 'Rushmore',
                img:'/images/categories/architectural/Rushmore.jpg'
            },
            {
                name: 'Sage',
                img:'/images/categories/architectural/Sage.jpg'
            },
            {
                name: 'Saguaro',
                img:'/images/categories/architectural/Saguaro.jpg'
            },
            {
                name: 'Scarlett',
                img:'/images/categories/architectural/Scarlett.jpg'
            },
            {
                name: 'Silver',
                img:'/images/categories/architectural/Silver.jpg'
            },
            {
                name: 'Starlight Sable',
                img:'/images/categories/architectural/Starlight_Sable.jpg'
            },
            {
                name: 'Sumela',
                img:'/images/categories/architectural/Sumela.jpg'
            },
            {
                name: 'Tanamiu',
                img:'/images/categories/architectural/Tanamiu.jpg'
            },
            {
                name: 'Tasilaq',
                img:'/images/categories/architectural/Tasilaq.jpg'
            },
            {
                name: 'Tourmaline',
                img:'/images/categories/architectural/Tourmaline.jpg'
            },
            {
                name: 'Yazd Sable',
                img:'/images/categories/architectural/Yazd_Sable.jpg'
            }
        ]
    },
    {
        id: 3,
        title: "Yatu For Industry",
        slug: "yatu-for-industry",
        subtitle: "Industry  Painting",
        description: "For a wide range of industrial applications, Yatu Indonesia range of powder coatings provides outstanding protection and performance. Our wide selection of product chemistries & colors ensures that we can meet the unique needs of various types of metals used in industrial environments. Besides, we also provide the right solution for every industrial application, ensuring optimal and long lasting results.",
        images: [
            {
                src: "/images/product/Industri/industry.jpg",
            },
            {
                src: "/images/product/Industri/lucas-santos-EdeOrwhmBVk-unsplash_11zon.jpg",
            },
            {
                src: "/images/product/Industri/tania-melnyczuk-LhuupC_9EqE-unsplash_11zon.jpg",
            },
        ],
        variants: [
            {
                name: 'atmosphere',
                img:'/images/categories/industrial/atmosphere.jpg'
            },
            {
                name: 'Babua',
                img:'/images/categories/industrial/Babua.jpg'
            },
            {
                name: 'baku',
                img:'/images/categories/industrial/baku.jpg'
            },
            {
                name: 'Brun',
                img:'/images/categories/industrial/Brun.jpg'
            },
            {
                name: 'brushed_linen',
                img:'/images/categories/industrial/brushed_linen.jpg'
            },
            {
                name: 'Changi',
                img:'/images/categories/industrial/Changi.jpg'
            },
            {
                name: 'clarity',
                img:'/images/categories/industrial/clarity.jpg'
            },
            {
                name: 'cream',
                img:'/images/categories/industrial/cream.jpg'
            },
            {
                name: 'cream_filtra',
                img:'/images/categories/industrial/cream_filtra.jpg'
            },
            {
                name: 'echo',
                img:'/images/categories/industrial/echo.jpg'
            },
            {
                name: 'Electrum',
                img:'/images/categories/industrial/Electrum.jpg'
            },
            {
                name: 'gloss',
                img:'/images/categories/industrial/gloss.jpg'
            },
            {
                name: 'gravel',
                img:'/images/categories/industrial/gravel.jpg'
            },
            {
                name: 'Gris',
                img:'/images/categories/industrial/Gris.jpg'
            },
            {
                name: 'ivory',
                img:'/images/categories/industrial/ivory.jpg'
            },
            {
                name: 'Lichen',
                img:'/images/categories/industrial/Lichen.jpg'
            },
            {
                name: 'lita_frawn',
                img:'/images/categories/industrial/lita_frawn.jpg'
            },
            {
                name: 'lusso',
                img:'/images/categories/industrial/lusso.jpg'
            },
            {
                name: 'lyra',
                img:'/images/categories/industrial/lyra.jpg'
            },
            {
                name: 'melodi',
                img:'/images/categories/industrial/melodi.jpg'
            },
            {
                name: 'mono_biscuit',
                img:'/images/categories/industrial/mono_biscuit.jpg'
            },
            {
                name: 'mono_naco',
                img:'/images/categories/industrial/mono_naco.jpg'
            },
            {
                name: 'mono_truffle',
                img:'/images/categories/industrial/mono_truffle.jpg'
            },
            {
                name: 'Noir_Sable',
                img:'/images/categories/industrial/Noir_Sable.jpg'
            },
            {
                name: 'opal',
                img:'/images/categories/industrial/opal.jpg'
            },
            {
                name: 'Pachua',
                img:'/images/categories/industrial/Pachua.jpg'
            },
            {
                name: 'pasadena',
                img:'/images/categories/industrial/pasadena.jpg'
            },
            {
                name: 'pearl',
                img:'/images/categories/industrial/pearl.jpg'
            },
            {
                name: 'pistachio',
                img:'/images/categories/industrial/pistachio.jpg'
            },
            {
                name: 'Sage',
                img:'/images/categories/industrial/Sage.jpg'
            },
            {
                name: 'Saguaro',
                img:'/images/categories/industrial/Saguaro.jpg'
            },
            {
                name: 'spirit',
                img:'/images/categories/industrial/spirit.jpg'
            },
            {
                name: 'Starlight',
                img:'/images/categories/industrial/Starlight.jpg'
            },
            {
                name: 'Sumela',
                img:'/images/categories/industrial/Sumela.jpg'
            },
            {
                name: 'Tourmaline',
                img:'/images/categories/industrial/Tourmaline.jpg'
            },
            {
                name: 'truth',
                img:'/images/categories/industrial/truth.jpg'
            },
            {
                name: 'vanilla',
                img:'/images/categories/industrial/vanilla.jpg'
            },
            {
                name: 'vela',
                img:'/images/categories/industrial/vela.jpg'
            },
            {
                name: 'vibe_grey',
                img:'/images/categories/industrial/vibe_grey.jpg'
            },
            {
                name: 'white_filtra',
                img:'/images/categories/industrial/white_filtra.jpg'
            },
            {
                name: 'white_polka',
                img:'/images/categories/industrial/white_polka.jpg'
            },
            {
                name: 'woodline',
                img:'/images/categories/industrial/woodline.jpg'
            },
            {
                name: 'zephy',
                img:'/images/categories/industrial/zephy.jpg'
            },
            {
                name: 'zora_barley',
                img:'/images/categories/industrial/zora_barley.jpg'
            }
        ]
    },
    {
        id: 4,
        title: "Yatu For Equipment",
        slug: "yatu-for-equipment",
        subtitle: "Equipment Painting",
        description: "Baik untuk keperluan rumah tangga, komersial, atau industri, Yatu Indonesia menawarkan beragam formulasi bubuk yang dirancang khusus untuk berbagai aplikasi.",
        images: [
            {
                src: "/images/product/Peralatan/FORMANI_RIVIO_FACTORY_533_L-4ed36c2d.jpg",
            },
            {
                src: "/images/product/Peralatan/Maniglie-di-design-rifinite-con-lavorazione-in-PVD.jpg",
            },
            {
                src: "/images/product/Peralatan/5-Essential-Things-To-Know-About-PVD-Coating-In-Dubai.jpg",
            },
        ],
        variants: [
            {
                name:'45novella',
                img:'/images/categories/applience/45novella.jpg'
            },
            {
                name:'anthracite',
                img:'/images/categories/applience/anthracite.jpg'
            },
            {
                name:'apache',
                img:'/images/categories/applience/apache.jpg'
            },
            {
                name:'armada',
                img:'/images/categories/applience/armada.jpg'
            },
            {
                name:'atomic',
                img:'/images/categories/applience/atomic.jpg'
            },
            {
                name:'beryl',
                img:'/images/categories/applience/beryl.jpg'
            },
            {
                name:'black',
                img:'/images/categories/applience/black.jpg'
            },
            {
                name:'black filtra',
                img:'/images/categories/applience/black_filtra.jpg'
            },
            {
                name:'bondi',
                img:'/images/categories/applience/bondi.jpg'
            },
            {
                name:'cady floss',
                img:'/images/categories/applience/cady_floss.jpg'
            },
            {
                name:'cerise',
                img:'/images/categories/applience/cerise.jpg'
            },
            {
                name:'coral',
                img:'/images/categories/applience/coral.jpg'
            },
            {
                name:'ebon',
                img:'/images/categories/applience/ebon.jpg'
            },
            {
                name:'energy',
                img:'/images/categories/applience/energy.jpg'
            },
            {
                name:'flame',
                img:'/images/categories/applience/flame.jpg'
            },
            {
                name:'graphite',
                img:'/images/categories/applience/graphite.jpg'
            },
            {
                name:'jazz',
                img:'/images/categories/applience/jazz.jpg'
            },
            {
                name:'latino',
                img:'/images/categories/applience/latino.jpg'
            },
            {
                name:'light persia',
                img:'/images/categories/applience/light_persia.jpg'
            },
            {
                name:'lilac',
                img:'/images/categories/applience/lilac.jpg'
            },
            {
                name:'majestic',
                img:'/images/categories/applience/majestic.jpg'
            },
            {
                name:'merle',
                img:'/images/categories/applience/merle.jpg'
            },
            {
                name:'merrie',
                img:'/images/categories/applience/merrie.jpg'
            },
            {
                name:'mirano',
                img:'/images/categories/applience/mirano.jpg'
            },
            {
                name:'mocca',
                img:'/images/categories/applience/mocca.jpg'
            },
            {
                name:'navy',
                img:'/images/categories/applience/navy.jpg'
            },
            {
                name:'onyx',
                img:'/images/categories/applience/onyx.jpg'
            },
            {
                name:'orion',
                img:'/images/categories/applience/orion.jpg'
            },
            {
                name:'persia',
                img:'/images/categories/applience/persia.jpg'
            },
            {
                name:'pulse',
                img:'/images/categories/applience/pulse.jpg'
            },
            {
                name:'quartz red',
                img:'/images/categories/applience/quartz_red.jpg'
            },
            {
                name:'rapture rebel',
                img:'/images/categories/applience/rapture_rebel.jpg'
            },
            {
                name:'rapture sonic',
                img:'/images/categories/applience/rapture_sonic.jpg'
            },
            {
                name:'reef',
                img:'/images/categories/applience/reef.jpg'
            },
            {
                name:'shelby',
                img:'/images/categories/applience/shelby.jpg'
            },
            {
                name:'sky',
                img:'/images/categories/applience/sky.jpg'
            },
            {
                name:'soul',
                img:'/images/categories/applience/soul.jpg'
            },
            {
                name:'tropic',
                img:'/images/categories/applience/tropic.jpg'
            },
            {
                name:'umbra',
                img:'/images/categories/applience/umbra.jpg'
            },
            {
                name:'vibe trance',
                img:'/images/categories/applience/vibe_trance.jpg'
            },
            {
                name:'woodline 05',
                img:'/images/categories/applience/woodline 05.jpg'
            },
            {
                name:'woodline 08',
                img:'/images/categories/applience/woodline 08.jpg'
            },
            {
                name:'woodline 04',
                img:'/images/categories/applience/woodline_04.jpg'
            },
            {
                name:'woodline 07',
                img:'/images/categories/applience/woodline_07.jpg'
            },
            {
                name:'woodline 11',
                img:'/images/categories/applience/woodline_11.jpg'
            },
            {
                name:'zircon',
                img:'/images/categories/applience/zircon.jpg'
            }
        ]
    },
    {
        id: 5,
        title: "Yatu For Hardware",
        slug: "yatu-for-hardware",
        subtitle: "Hardware Painting",
        description: "Pilihlah lapisan bubuk Yatu berkualitas tinggi yang sesuai dengan kebutuhan Anda jika Anda ingin lapisan tersebut memiliki fitur-fitur penting berikut: fleksibilitas, warna dan hasil akhir yang luar biasa, ketahanan terhadap goresan dan lecet, tahan terhadap cuaca, dan daya tahan.",
        images: [
            {
                src: "/images/product/PerangkatKeras/steel-wrenches-tools_3_11zon.jpg",
            },
            {
                src: "/images/product/PerangkatKeras/composition-different-car-accessories_2_11zon.jpg",
            },
            {
                src: "/images/product/PerangkatKeras/tools-background-equipment-garage-shop_4_11zon.jpg",
            },
        ],
        variants: [
            {
                name: 'aged chopper',
                img:'/images/categories/hardware/aged_chopper.jpg'
            },
            {
                name: 'boysenberry',
                img:'/images/categories/hardware/boysenberry.jpg'
            },
            {
                name: 'brick storefront',
                img:'/images/categories/hardware/brick_storefront.jpg'
            },
            {
                name: 'copper kivu',
                img:'/images/categories/hardware/copper_kivu.jpg'
            },
            {
                name: 'covert green',
                img:'/images/categories/hardware/covert_green.jpg'
            },
            {
                name: 'gold moero',
                img:'/images/categories/hardware/gold_moero.jpg'
            },
            {
                name: 'green pagan',
                img:'/images/categories/hardware/green_pagan.jpg'
            },
            {
                name: 'interstate blue',
                img:'/images/categories/hardware/interstate_blue.jpg'
            },
            {
                name: 'ivy green',
                img:'/images/categories/hardware/ivy_green.jpg'
            },
            {
                name: 'juniper green',
                img:'/images/categories/hardware/juniper_green.jpg'
            },
            {
                name: 'mountain moss',
                img:'/images/categories/hardware/mountain_moss.jpg'
            },
            {
                name: 'pearl chopper',
                img:'/images/categories/hardware/pearl_chopper.jpg'
            },
            {
                name: 'pearl night blue',
                img:'/images/categories/hardware/pearl_night_blue.jpg'
            },
            {
                name: 'shale green',
                img:'/images/categories/hardware/shale_green.jpg'
            },
            {
                name: 'zinc rich',
                img:'/images/categories/hardware/zinc_rich.jpg'
            }
        ]
    },
    {
        id: 6,
        title: "Yatu For ACE",
        slug: "yatu-for-ace",
        subtitle: "Ace Painting",
        description: "Memimpin secara global dalam solusi pelapisan bubuk yang unggul dan inovatif Lapisan kami hadir dalam berbagai warna dan diuji secara ketat untuk memenuhi standar kinerja tertinggi.",
        images: [
            {
                src: "/images/product/Ace/3d-rendering-hydraulic-elements_2_11zon.jpg",
            },
            {
                src: "/images/product/Ace/scoop-and-hydraulic-arm-of-an-excavator-2024-06-13-05-36-04-utc_7_11zon.jpg",
            },
            {
                src: "/images/product/Ace/hydrostatic-crane-engine-the-control-system-of-the-2023-11-27-04-54-24-utc_4_11zon.jpg",
            },
        ],
        variants: [
            {
                name: 'black lava texture',
                img:'/images/categories/ace/black_lava_texture.jpg'
            },
            {
                name: 'brick',
                img:'/images/categories/ace/brick.jpg'
            },
            {
                name: 'brick storefront',
                img:'/images/categories/ace/brick_storefront.jpg'
            },
            {
                name: 'bronze bagana',
                img:'/images/categories/ace/bronze_bagana.jpg'
            },
            {
                name: 'bronze lario',
                img:'/images/categories/ace/bronze_lario.jpg'
            },
            {
                name: 'chopper kivu',
                img:'/images/categories/ace/chopper_kivu.jpg'
            },
            {
                name: 'crocodile green',
                img:'/images/categories/ace/crocodile_green.jpg'
            },
            {
                name: 'gold karkar',
                img:'/images/categories/ace/gold_karkar.jpg'
            },
            {
                name: 'grey khorgo',
                img:'/images/categories/ace/grey_khorgo.jpg'
            },
            {
                name: 'grey yojoa',
                img:'/images/categories/ace/grey_yojoa.jpg'
            },
            {
                name: 'luminous orange',
                img:'/images/categories/ace/luminous_orange.jpg'
            },
            {
                name: 'marble effect',
                img:'/images/categories/ace/marble_effect.jpg'
            },
            {
                name: 'mettalic deore',
                img:'/images/categories/ace/mettalic_deore.jpg'
            },
            {
                name: 'minty',
                img:'/images/categories/ace/minty.jpg'
            },
            {
                name: 'white onega',
                img:'/images/categories/ace/white_onega.jpg'
            },
            {
                name: 'yellow flat sand',
                img:'/images/categories/ace/yellow_flat_sand.jpg'
            }
        ]
    },
    {
        id: 7,
        title: "Yatu For Retail",
        slug: "yatu-for-retail",
        subtitle: "Retail Painting",
        description: "Memimpin secara global dalam solusi pelapisan bubuk yang unggul dan inovatif Pelapis ini cocok untuk berbagai permukaan, termasuk rak, rak, aksesori, furnitur, dan bubuk eksternal untuk jendela, pintu, dinding tirai, dan etalase, memastikan perlindungan dan menyempurnakan penampilan.",
        images: [
            {
                src: "/images/product/Ritel/scratched-metal-background_4_11zon.jpg",
            },
            {
                src: "/images/product/Ritel/horizontal-lines-strips-abstract-background_2_11zon.jpg",
            },
            {
                src: "/images/product/Ritel/closeup-modern-white-table-with-drawer-lights_1_11zon.jpg",
            },
        ],
        variants: [
            {
                name: 'antique red silver',
                img : '/images/categories/retail/antique_red_silver.jpg'
            },
            {
                name: 'beige wrinkle',
                img : '/images/categories/retail/beige_wrinkle.jpg'
            },
            {
                name: 'black wrinkle',
                img : '/images/categories/retail/black_wrinkle.jpg'
            },
            {
                name: 'blue wrinkle',
                img : '/images/categories/retail/blue_wrinkle.jpg'
            },
            {
                name: 'dark sparkle silver',
                img : '/images/categories/retail/dark_sparkle_silver.jpg'
            },
            {
                name: 'grey wrinkle',
                img : '/images/categories/retail/grey_wrinkle.jpg'
            },
            {
                name: 'metallic black',
                img : '/images/categories/retail/metallic_black.jpg'
            },
            {
                name: 'metallic chart brown',
                img : '/images/categories/retail/metallic_chart_brown.jpg'
            },
            {
                name: 'metallic copper',
                img : '/images/categories/retail/metallic_copper.jpg'
            },
            {
                name: 'metallic gold',
                img : '/images/categories/retail/metallic_gold.jpg'
            },
            {
                name: 'nickle finish',
                img : '/images/categories/retail/nickle_finish.jpg'
            },
            {
                name: 'phirozi',
                img : '/images/categories/retail/phirozi.jpg'
            },
            {
                name: 'Red wrinkle',
                img : '/images/categories/retail/Red_wrinkle.jpg'
            },
            {
                name: 'silver ash',
                img : '/images/categories/retail/silver_ash.jpg'
            },
            {
                name: 'silver grey',
                img : '/images/categories/retail/silver_grey.jpg'
            }
        ]
    },
    {
        id: 8,
        title: "Yatu For Rebar",
        slug: "yatu-for-rebar",
        subtitle: "Rebar Painting",
        description: "Mereka menanamkan kepercayaan terhadap ketahanan trotoar, struktur laut, dan jembatan. Tulangan berlapis epoksi berikat fusi digunakan dalam berbagai aplikasi konstruksi.",
        images: [
            {
                src: "/images/product/Rebar/background-with-metallic-grid-line-texture_2_11zon.jpg",
            },
            {
                src: "/images/product/Rebar/silver-metal-chain_4_11zon.jpg",
            },
            {
                src: "/images/product/Rebar/welded-mesh-fence-night_6_11zon.jpg",
            },
        ],
        variants: [
            {
                name: 'alumunium silver',
                img : '/images/categories/rebar/alumunium silver.jpg'
            },
            {
                name: 'alu silver',
                img : '/images/categories/rebar/alu_silver.jpg'
            },
            {
                name: 'antiq silver',
                img : '/images/categories/rebar/antiq_silver.jpg'
            },
            {
                name: 'antiq silver black',
                img : '/images/categories/rebar/antiq_silver_black.jpg'
            },
            {
                name: 'antiq silver black mid',
                img : '/images/categories/rebar/antiq_silver_black_mid.jpg'
            },
            {
                name: 'antiq silver red',
                img : '/images/categories/rebar/antiq_silver_red.jpg'
            },
            {
                name: 'chrome silver',
                img : '/images/categories/rebar/chrome_silver.jpg'
            },
            {
                name: 'crack silver',
                img : '/images/categories/rebar/crack_silver.jpg'
            },
            {
                name: 'crocodile pink',
                img : '/images/categories/rebar/crocodile_pink.jpg'
            },
            {
                name: 'crocodile red',
                img : '/images/categories/rebar/crocodile_red.jpg'
            },
            {
                name: 'light green flat sand',
                img : '/images/categories/rebar/light_green_flat_sand.jpg'
            },
            {
                name: 'luster silver',
                img : '/images/categories/rebar/luster_silver.jpg'
            },
            {
                name: 'metallic silver',
                img : '/images/categories/rebar/metallic_silver.jpg'
            },
            {
                name: 'orange sand',
                img : '/images/categories/rebar/orange_sand.jpg'
            },
            {
                name: 'yellow flat sand',
                img : '/images/categories/rebar/yellow_flat_sand.jpg'
            }
        ]
    },
    {
        id: 9,
        title: "Yatu For Beyond Metal",
        slug: "yatu-for-beyond_metal",
        subtitle: "Beyond Metal Painting",
        description: "Memimpin secara global dalam solusi pelapisan bubuk yang unggul dan inovatif Lapisan kami hadir dalam berbagai warna dan diuji secara ketat untuk memenuhi standar kinerja tertinggi.",
        images: [
            {
                src: "/images/product/BeyondMetal/close-up-bicycle-outdoors_1_11zon.jpg",
            },
            {
                src: "/images/product/BeyondMetal/piece-modern-futuristic-building_3_11zon.jpg",
            },
            {
                src: "/images/product/BeyondMetal/top-view-different-paper-sheets_4_11zon.jpg",
            },
        ],
        variants: [
            {
                name: 'antiq silver blue',
                img : '/images/categories/beyondmetal/antiq_silver_blue.jpg'
            },
            {
                name: 'black wrinkle',
                img : '/images/categories/beyondmetal/black_wrinkle.jpg'
            },
            {
                name: 'blue sand',
                img : '/images/categories/beyondmetal/blue_sand.jpg'
            },
            {
                name: 'brown wrinkle',
                img : '/images/categories/beyondmetal/brown_wrinkle.jpg'
            },
            {
                name: 'candy pearl silver',
                img : '/images/categories/beyondmetal/candy_pearl_silver.jpg'
            },
            {
                name: 'clear sans topcoat',
                img : '/images/categories/beyondmetal/clear_sans_topcoat.jpg'
            },
            {
                name: 'crocodile blue',
                img : '/images/categories/beyondmetal/crocodile_blue.jpg'
            },
            {
                name: 'crocodile tosca',
                img : '/images/categories/beyondmetal/crocodile_tosca.jpg'
            },
            {
                name: 'dark grey wrinkle',
                img : '/images/categories/beyondmetal/dark_grey_wrinkle.jpg'
            },
            {
                name: 'fine wrinkle',
                img : '/images/categories/beyondmetal/fine_wrinkle.jpg'
            },
            {
                name: 'grey blue sand',
                img : '/images/categories/beyondmetal/grey_blue_sand.jpg'
            },
            {
                name: 'grey wrinkle',
                img : '/images/categories/beyondmetal/grey_wrinkle.jpg'
            },
            {
                name: 'light grey wrinkle',
                img : '/images/categories/beyondmetal/light_grey_wrinkle.jpg'
            },
            {
                name: 'pebble grey wrinkle',
                img : '/images/categories/beyondmetal/pebble_grey_wrinkle.jpg'
            },
            {
                name: 'red wrinkle',
                img : '/images/categories/beyondmetal/red_wrinkle.jpg'
            }
        ]
    },
    {
        id: 10,
        title: "Yatu For Lighting",
        slug: "yatu-for-lighting",
        subtitle: "Lighting Painting",
        description: "Tersedia dalam berbagai warna dan efek penuh gaya dari Yatu untuk lampu, menyempurnakan tampilan dan fungsinya dalam berbagai gaya desain, dari klasik hingga modern. Pilihan warna dan efek bubuk trendi Yatu untuk perlengkapan lampu tidak hanya memastikan fungsionalitas optimal tetapi juga meningkatkan daya tarik visualnya, meningkatkan estetika dan suasana dalam beragam gaya seperti tradisional, modern, industri, kontemporer, dan pedesaan, memberikan keserbagunaan dan kecanggihan.",
        images: [
            {
                src: "/images/product/Penerangan/vintage-ceiling-light-lamp_4_11zon.jpg",
            },
            {
                src: "/images/product/Penerangan/light-lamp-decoration-interior-room_3_11zon.jpg",
            },
            {
                src: "/images/product/Penerangan/lamp_2_11zon.jpg",
            },
        ],
        variants: [
            {
                name: 'ancient brass',
                img : '/images/categories/lighting/ancient_brass.jpg'
            },
            {
                name: 'avant garden',
                img : '/images/categories/lighting/avant_garden.jpg'
            },
            {
                name: 'beige futuna',
                img : '/images/categories/lighting/beige_futuna.jpg'
            },
            {
                name: 'blue aegean',
                img : '/images/categories/lighting/blue_aegean.jpg'
            },
            {
                name: 'dark bronze',
                img : '/images/categories/lighting/dark_bronze.jpg'
            },
            {
                name: 'gold-moero',
                img : '/images/categories/lighting/gold-moero.jpg'
            },
            {
                name: 'gold guri',
                img : '/images/categories/lighting/gold_guri.jpg'
            },
            {
                name: 'Gold watch',
                img : '/images/categories/lighting/Gold_watch.jpg'
            },
            {
                name: 'green pagan',
                img : '/images/categories/lighting/green_pagan.jpg'
            },
            {
                name: 'grey tulare',
                img : '/images/categories/lighting/grey_tulare.jpg'
            },
            {
                name: 'kilauea cloud',
                img : '/images/categories/lighting/kilauea cloud.jpg'
            },
            {
                name: 'light bronze',
                img : '/images/categories/lighting/light_bronze.jpg'
            },
            {
                name: 'mountain moss',
                img : '/images/categories/lighting/mountain_moss.jpg'
            },
            {
                name: 'regenerate',
                img : '/images/categories/lighting/regenerate.jpg'
            },
            {
                name: 'sea sailing',
                img : '/images/categories/lighting/sea_sailing.jpg'
            }
        ]
    },
    {
        id: 11,
        title: "Yatu For Aplikator",
        slug: "yatu-for-aplikator",
        subtitle: "Aplikator  Painting",
        description: "Berbagai pilihan kami, ditambah dengan layanan pencocokan warna khusus untuk aplikator. Yatu Powder Coatings menawarkan beragam pilihan, termasuk pencocokan warna khusus untuk aplikator.",
        images: [
            {
                src: "/images/product/Aplikator/worker-spraying-powder-paint-from-gun-side-view_11zon.jpg",
            },
            {
                src: "/images/product/Aplikator/service-worker-painting-car-auto-service_11zon.jpg",
            },
            {
                src: "/images/product/Aplikator/hand-spraying-powder-paint-from-gun-side-view_11zon.jpg",
            },
        ],
        variants: [
            {
                name: 'black lava',
                img : '/images/categories/applicator/black_lava.jpg'
            },
            {
                name: 'black notis',
                img : '/images/categories/applicator/black_notis.jpg'
            },
            {
                name: 'bronze glence',
                img : '/images/categories/applicator/bronze_glence.jpg'
            },
            {
                name: 'bronze suwa',
                img : '/images/categories/applicator/bronze_suwa.jpg'
            },
            {
                name: 'bronze vanda',
                img : '/images/categories/applicator/bronze_vanda.jpg'
            },
            {
                name: 'brown apana',
                img : '/images/categories/applicator/brown_apana.jpg'
            },
            {
                name: 'brown nebo',
                img : '/images/categories/applicator/brown_nebo.jpg'
            },
            {
                name: 'camino patchway',
                img : '/images/categories/applicator/camino_patchway.jpg'
            },
            {
                name: 'cold style',
                img : '/images/categories/applicator/cold_style.jpg'
            },
            {
                name: 'copper clocktower',
                img : '/images/categories/applicator/copper_clocktower.jpg'
            },
            {
                name: 'gold karkar',
                img : '/images/categories/applicator/gold_karkar.jpg'
            },
            {
                name: 'red leatherbound',
                img : '/images/categories/applicator/red_leatherbound.jpg'
            },
            {
                name: 'rust rame',
                img : '/images/categories/applicator/rust_rame.jpg'
            },
            {
                name: 'white irazu',
                img : '/images/categories/applicator/white_irazu.jpg'
            }
        ]
    },
    {
        id: 12,
        title: "Yatu For Furniture",
        slug: "yatu-for-furniture",
        subtitle: "Equipment Furniture",
        description: "Yatu Indonesia provides value to people worldwide daily. By creating vibrant tones and atmospheres for all types of furniture. Our innovative products and materials are designed to protect and beautify your world today, tomorrow, and beyond.",
        images: [
            {
                src: "/images/product/Furniture/furniture.jpg",
            },
            {
                src: "/images/product/Furniture/photo-1678080244743-8f662f0a56c3_1_11zon.jpg",
            },
            {
                src: "/images/product/Furniture/photo-1701948866896-6bef1cdc958e_2_11zon.jpg",
            },
        ],
        variants: [
            {
                name: 'aluminium filtra',
                img:'/images/categories/furniture/aluminium_filtra.jpg'
            },
            {
                name: 'alumunium',
                img:'/images/categories/furniture/alumunium.jpg'
            },
            {
                name: 'aqua',
                img:'/images/categories/furniture/aqua.jpg'
            },
            {
                name: 'beige',
                img:'/images/categories/furniture/beige.jpg'
            },
            {
                name: 'boutique',
                img:'/images/categories/furniture/boutique.jpg'
            },
            {
                name: 'brushed aluminium',
                img:'/images/categories/furniture/brushed_aluminium.jpg'
            },
            {
                name: 'brushed sand',
                img:'/images/categories/furniture/brushed_sand.jpg'
            },
            {
                name: 'citrine',
                img:'/images/categories/furniture/citrine.jpg'
            },
            {
                name: 'conton',
                img:'/images/categories/furniture/conton.jpg'
            },
            {
                name: 'dream',
                img:'/images/categories/furniture/dream.jpg'
            },
            {
                name: 'electra blue',
                img:'/images/categories/furniture/electra_blue.jpg'
            },
            {
                name: 'emerald',
                img:'/images/categories/furniture/emerald.jpg'
            },
            {
                name: 'estella abyss',
                img:'/images/categories/furniture/estella_abyss.jpg'
            },
            {
                name: 'estella axis',
                img:'/images/categories/furniture/estella_axis.jpg'
            },
            {
                name: 'estella lyra',
                img:'/images/categories/furniture/estella_lyra.jpg'
            },
            {
                name: 'Flint',
                img:'/images/categories/furniture/Flint.jpg'
            },
            {
                name: 'glam',
                img:'/images/categories/furniture/glam.jpg'
            },
            {
                name: 'glow',
                img:'/images/categories/furniture/glow.jpg'
            },
            {
                name: 'grey filtra',
                img:'/images/categories/furniture/grey_filtra.jpg'
            },
            {
                name: 'hunter',
                img:'/images/categories/furniture/hunter.jpg'
            },
            {
                name: 'litra coca',
                img:'/images/categories/furniture/litra_coca.jpg'
            },
            {
                name: 'litra putty',
                img:'/images/categories/furniture/litra_putty.jpg'
            },
            {
                name: 'luster',
                img:'/images/categories/furniture/luster.jpg'
            },
            {
                name: 'mink',
                img:'/images/categories/furniture/mink.jpg'
            },
            {
                name: 'mitnz',
                img:'/images/categories/furniture/mitnz.jpg'
            },
            {
                name: 'mono mud',
                img:'/images/categories/furniture/mono_mud.jpg'
            },
            {
                name: 'Nomad',
                img:'/images/categories/furniture/Nomad.jpg'
            },
            {
                name: 'ocean',
                img:'/images/categories/furniture/ocean.jpg'
            },
            {
                name: 'pastel blue',
                img:'/images/categories/furniture/pastel_blue.jpg'
            },
            {
                name: 'powder green',
                img:'/images/categories/furniture/powder_green.jpg'
            },
            {
                name: 'quartz blue',
                img:'/images/categories/furniture/quartz_blue.jpg'
            },
            {
                name: 'rapture glisten',
                img:'/images/categories/furniture/rapture_glisten.jpg'
            },
            {
                name: 'shadow grey',
                img:'/images/categories/furniture/shadow_grey.jpg'
            },
            {
                name: 'steel filtra',
                img:'/images/categories/furniture/steel_filtra.jpg'
            },
            {
                name: 'tahiti',
                img:'/images/categories/furniture/tahiti.jpg'
            },
            {
                name: 'titanium',
                img:'/images/categories/furniture/titanium.jpg'
            },
            {
                name: 'vibe',
                img:'/images/categories/furniture/vibe.jpg'
            },
            {
                name: 'vibe cool blue',
                img:'/images/categories/furniture/vibe_cool_blue.jpg'
            },
            {
                name: 'vibe silver',
                img:'/images/categories/furniture/vibe_silver.jpg'
            },
            {
                name: 'vibe yellow',
                img:'/images/categories/furniture/vibe_yellow.jpg'
            },
            {
                name: 'Vitale',
                img:'/images/categories/furniture/Vitale.jpg'
            },
            {
                name: 'woodline',
                img:'/images/categories/furniture/woodline.jpg'
            },
            {
                name: 'woodline 9401',
                img:'/images/categories/furniture/woodline_9401.jpg'
            },
            {
                name: 'woodline 9412',
                img:'/images/categories/furniture/woodline_9412.jpg'
            },
            {
                name: 'yellow',
                img:'/images/categories/furniture/yellow.jpg'
            },
            {
                name: 'zora coco',
                img:'/images/categories/furniture/zora_coco.jpg'
            },
            {
                name: 'zora haze',
                img:'/images/categories/furniture/zora_haze.jpg'
            }
        ]
    },
]