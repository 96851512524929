import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { useTranslation } from 'react-i18next'
import SocialMedia from "./SocialMedia";
import { menu } from "../json/menu";
const Footer = () => {
    const currentYear = new Date().getFullYear();
    const { i18n, t } = useTranslation();
    const currentLang = i18n.language || 'id';
    return (
        <footer className="relative w-full h-auto text-black bg-white dark:bg-black overflow-hidden">
            <div className="relative w-full h-auto py-6 bg-blue text-center text-[#12174D] border-black border-y">
                <p className="text-white">{t('footer.p1')}</p>
            </div>
            <div className="container py-16">
                <div className="relative grid grid-cols-1 md:grid-cols-1 lg:grid-cols-12 xl:grid-cols-12 gap-10">
                    <div className="col-span-1 md:col-span-1 lg:col-span-3 xl:col-span-4 flex flex-col items-center md:items-center lg:items-start text-center lg:text-left">
                        <img src="/images/yatulogo.svg" alt="logo yatu" width={200} height={93} />
                        <span className="pt-4 text-gray dark:text-white w-full font-medium text-sm font-montserrat">{t('footer.tagline')}</span>
                    </div>
                    <div className="col-span-1 md:col-span-1 lg:col-span-2 xl:col-span-2 flex flex-col justify-start gap-y-4">
                        <span className="text-[#12174D] dark:text-blue text-xl font-bold font-sarala text-center lg:text-left">{t('footer.company')}</span>
                        <ul className="flex flex-col gap-5 text-gray dark:text-white text-base font-normal font-sarala text-center lg:text-left">
                            {menu.filter((_, index) => index !== 0).map((item, index) => (
                                <li key={index}>
                                    <a href={`/${currentLang}${item.link}`}>{t(`menu.${item.slug}`)}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-span-1 md:col-span-1 lg:col-span-3 xl:col-span-3 flex flex-col md:mx-auto justify-start gap-y-4">
                        <span className="text-[#12174D] dark:text-blue text-xl font-bold font-sarala text-center lg:text-left">{t('footer.contact')}</span>
                        <div className="flex flex-col items-center lg:items-start xl:items-start gap-y-4">
                            <div className="flex items-center xl:items-start">
                                <div className="w-12 h-7 pr-4">
                                    <FaEnvelope className="w-6 h-6 text-blue" />
                                </div>
                                <div className="flex flex-col gap-y-2">
                                    <ul className="">
                                        <li>
                                            <a href="mailto:cs@primateknologicoating.com" className="text-gray dark:text-white text-base font-sarala">cs@yatu.co.id</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex items-center xl:items-start">
                                <div className="w-12 h-7 pr-4">
                                    <FaPhoneAlt className="w-6 h-6 text-blue" />
                                </div>
                                <div className="flex flex-col gap-y-2">
                                    <ul className="">
                                        <li>
                                            <a href="tel:+6285179995559" className="text-gray dark:text-white text-base font-sarala">+62851 7999 5559</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex items-center xl:items-start">
                                <div className="w-12 h-7 pr-4">
                                    <FaMapMarkerAlt className="w-6 h-6 text-blue" />
                                </div>
                                <div className="flex flex-col gap-y-2">
                                    <p className="text-gray dark:text-white text-base font-sarala">Jl. Industri Raya 3 Blok G No. 08<br />RT. 002 / RW. 004 Pasir Jaya<br />Kec. Jatiuwung, Kota Tangerang, <br /> Banten, 15135</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 md:col-span-1 lg:col-span-3 xl:col-span-3 flex flex-col items-center md:items-center lg:items-start justify-start gap-y-4">
                        <span className="text-[#12174D] dark:text-blue text-xl font-bold font-sarala text-center lg:text-left">{t('footer.follow')}</span>
                        <SocialMedia colorText="text-blue dark:text-white" sizeIcon="w-9 h-9" />
                    </div>
                </div>
            </div>
            <hr className="border-gray/50 border-[0.5px]" />
            <p className="py-4 text-sm text-gray dark:text-white font-poppins text-center">&copy; {currentYear} <a href="/">PT. Xing Yatu Indonesia</a> | {t('footer.p2')} </p>
        </footer>
    );
}

export default Footer;