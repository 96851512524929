interface ClientProps {
    logo: string;
}

export const client: ClientProps[] = [
    { logo: '/images/client/1.png' },
    { logo: '/images/client/2.png' },
    { logo: '/images/client/3.png' },
    { logo: '/images/client/4.png' },
    { logo: '/images/client/5.png' },
    { logo: '/images/client/6.png' },
    { logo: '/images/client/7.png' },
    { logo: '/images/client/8.png' },
    { logo: '/images/client/9.png' },
    { logo: '/images/client/10.png' },
    { logo: '/images/client/11.png' },
    { logo: '/images/client/12.png' },
    { logo: '/images/client/13.png' },
    { logo: '/images/client/14.png' },
    { logo: '/images/client/15.png' },
    { logo: '/images/client/16.png' },
    { logo: '/images/client/17.png' },
    { logo: '/images/client/18.png' },
    { logo: '/images/client/19.png' },
    { logo: '/images/client/20.png' },
    { logo: '/images/client/21.png' },
]