import { useState } from "react";
import { useTranslation } from 'react-i18next';

interface GalleryProps {
    imageSrc: string;
    category: string;
}

const items: GalleryProps[] = [
    {
      imageSrc: "/images/career/1.jpg",
      category: "Office"
    },
    {
      imageSrc: "/images/career/2.jpg",
      category: "Office"
    },
    {
      imageSrc: "/images/career/3.jpg",
      category: "Office"
    },
    {
      imageSrc: "/images/career/4.jpg",
      category: "Office"
    },
    {
      imageSrc: "/images/career/5.jpg",
      category: "Office"
    },
    {
      imageSrc: "/images/career/6.jpg",
      category: "Office"
    },
    {
      imageSrc: "/images/career/7.jpg",
      category: "Product"
    },
]
  

const Gallery = () => {
    const { t } = useTranslation();
    const [selectedCategory, setSelectedCategory] = useState('All');

    const filteredItems = selectedCategory === 'All' ? items : items.filter(item => item.category === selectedCategory);
    return (
        <section className="relative w-full h-auto px-0 m-0 py-20 bg-white dark:bg-black overflow-hidden">
            <div className="container">
                <div className="flex flex-col text-center gap-y-8">
                    <h2 className="text-black dark:text-white text-4xl font-poppins font-semibold">{t('career.gallery.heading')}</h2>
                    <p className="text-black/80 text-lg font-sarala font-normal leading-6">{t('career.gallery.subheading')}</p>
                </div>
                <div className="py-10 flex items-center justify-center gap-x-0 xl:gap-x-14 overflow-x-auto">
                    <button onClick={() => setSelectedCategory('All')} className={`text-lg text-black font-poppins font-medium py-4 px-8 ${selectedCategory === 'All' ? 'bg-[#F8F7F4] rounded-[50px]' : ''}`}>{t('career.gallery.a1')}</button>
                    <button onClick={() => setSelectedCategory('Office')} className={`text-lg text-black font-poppins font-medium py-4 px-8 ${selectedCategory === 'Office' ? 'bg-[#F8F7F4] rounded-[50px]' : ''}`}>{t('career.gallery.a2')}</button>
                    <button onClick={() => setSelectedCategory('Product')} className={`text-lg text-black font-poppins font-medium py-4 px-8 ${selectedCategory === 'Product' ? 'bg-[#F8F7F4] rounded-[50px]' : ''}`}>{t('career.gallery.a3')}</button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-[30px]">
                    {filteredItems.slice(0, 7).map((item, index) => (
                        <div key={index} className={`relative w-full ${(index === 4 || index === 5) ? 'xl:h-[516px]' : index == 6 ? 'xl:h-[330px]' : 'xl:h-[330px]'} bg-white rounded-[25px] ${(index === 4 || index === 5) ? 'xl:col-span-2' : index == 6 ? 'xl:col-span-4' : ''}`}>
                            <div className="absolute w-full h-full flex items-center justify-center top-0 left-0 right-0 bg-gradient-to-b from-transparent to-[#0D1730]/50 from-25 rounded-[25px] lg:rounded-b-3xl">
                                {/* <span className="text-white">{item.category}</span> */}
                            </div>
                            <img src={item.imageSrc || '/images/no_image.png'}  width={300} height={300} className={`w-full ${(index === 4 || index === 5) ? 'xl:h-[516px]' : index == 6 ? 'xl:h-[330px]' : 'xl:h-[330px]'} object-cover object-center rounded-[25px] lg:rounded-b-3xl`} alt="" />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Gallery;