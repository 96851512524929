import { Fragment } from 'react';

import Hero from '../components/Hero'
import AboutUsSection from '../components/AboutUsSection'
import Feature from '../components/Feature'
import ChoiceUs from '../components/ChoiceUs'
import Product from '../components/Product'
import Client from '../components/Client'

const Home = () => {
    return (
        <Fragment>
            <Hero />
            <AboutUsSection />
            <Feature />
            <ChoiceUs />
            <Product />
            <Client />
        </Fragment>
    )
}

export default Home;